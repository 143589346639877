<template>
  <div class="comic-info">
    <el-form
      ref="comicForm"
      :model="comicData"
      :rules="editType === 'view' ? {} : comicDataRules"
      label-width="200px"
      element-loading-background="rgba(0, 0, 0, 0.75)"
    >
      <el-form-item label="动态漫名称" prop="animation_name">
        <el-col :span="12">
          <span v-if="editType === 'view'" class="comic-info-name">{{
            comicData.animation_name
          }}</span>
          <template v-else>
            <el-input
              v-model="comicData.animation_name"
              placeholder="请输入动态漫名称，无需填写《》或其他符号"
            />
            <div class="search-box-message">
              <i class="el-icon-info"></i
              >请认真填写动态漫名称，一经保存将不可修改
            </div>
          </template>
        </el-col>
      </el-form-item>
      <el-form-item label="动态漫封面" prop="animation_cover_3_4">
        <div
          v-if="editType === 'view'"
          class="comic-info-cover-box"
          :style="{ width: '120px', height: '150px' }"
        >
          <img
            :key="
              comicData.animation_cover_domain +
              $utils.filterImagePath(comicData.animation_cover_3_4)
            "
            v-lazy="
              comicData.animation_cover_domain +
              $utils.filterImagePath(comicData.animation_cover_3_4)
            "
            class="comic-info-cover"
          />
        </div>
        <template v-else>
          <!-- <el-input v-model="comicData.animation_cover_3_4" :value="comicData.animation_cover_3_4" v-show="false"></el-input> -->
          <anComicCoverUpload
            id="cover34"
            :drag="true"
            width="120px"
            height="160px"
            :accept="$config.acceptImg"
            :onChange="uploadChangeHandle"
          >
            <img
              v-if="comicData.animation_cover_3_4"
              :src="
                (tokenData.domain || comicData.animation_cover_domain) +
                $utils.filterImagePath(comicData.animation_cover_3_4)
              "
              class="comic-info-cover"
            />
            <!-- <img v-if="comicData.animation_cover_3_4" :key="tokenData.domain + $utils.filterImagePath(comicData.animation_cover_3_4)" v-lazy="tokenData.domain + $utils.filterImagePath(comicData.animation_cover_3_4)" class="comic-info-cover" /> -->
          </anComicCoverUpload>
          <p class="comic-info-tips comic-info-cover-tips">
            建议封面尺寸为750*1000px,72dpi; 支持jpg，png; 大小2M以内
          </p>
        </template>
      </el-form-item>
      <el-form-item label="动态漫封面" prop="animation_cover_2_1">
        <div
          v-if="editType === 'view'"
          class="comic-info-cover-box"
          :style="{ width: '160px', height: '90px' }"
        >
          <img
            :key="
              comicData.animation_cover_domain +
              $utils.filterImagePath(comicData.animation_cover_2_1)
            "
            v-lazy="
              comicData.animation_cover_domain +
              $utils.filterImagePath(comicData.animation_cover_2_1)
            "
            class="comic-info-cover"
          />
        </div>
        <template v-else>
          <!-- <el-input v-model="comicData.animation_cover_2_1" :value="comicData.animation_cover_2_1" v-show="false"></el-input> -->
          <!-- {{$config.cdn + comicData.animation_cover_3_4}} -->
          <anComicCoverUpload
            id="cover21"
            :drag="true"
            width="200px"
            height="100px"
            :accept="$config.acceptImg"
            :onChange="uploadChangeHandle"
          >
            <img
              v-if="comicData.animation_cover_2_1"
              :src="
                (tokenData.domain || comicData.animation_cover_domain) +
                $utils.filterImagePath(comicData.animation_cover_2_1)
              "
              class="comic-info-cover"
            />
            <!-- <img v-if="comicData.animation_cover_2_1" :key="tokenData.domain + $utils.filterImagePath(comicData.animation_cover_2_1)" v-lazy="tokenData.domain + $utils.filterImagePath(comicData.animation_cover_2_1)" class="comic-info-cover" /> -->
          </anComicCoverUpload>
          <p class="comic-info-tips comic-info-cover-tips">
            建议封面尺寸为800*400px,72dpi; 支持jpg，png; 大小2M以内
          </p>
        </template>
      </el-form-item>
      <el-form-item label="动态漫封面" prop="animation_cover_4_3">
        <div
          v-if="editType === 'view'"
          class="comic-info-cover-box"
          :style="{ width: '160px', height: '120px' }"
        >
          <img
            :key="
              tokenData.domain +
              $utils.filterImagePath(comicData.animation_cover_4_3)
            "
            v-lazy="
              comicData.animation_cover_domain +
              $utils.filterImagePath(comicData.animation_cover_4_3)
            "
            class="comic-info-cover"
          />
        </div>
        <template v-else>
          <anComicCoverUpload
            id="cover43"
            :drag="true"
            width="160px"
            height="120px"
            :accept="$config.acceptImg"
            :onChange="uploadChangeHandle"
          >
            <img
              v-if="comicData.animation_cover_4_3"
              :src="
                (tokenData.domain || comicData.animation_cover_domain) +
                $utils.filterImagePath(comicData.animation_cover_4_3)
              "
              class="comic-info-cover"
            />
          </anComicCoverUpload>
          <p class="comic-info-tips comic-info-cover-tips">
            建议封面尺寸为1000*750,比例为4：3; 支持jpg，png; 大小2M以内
          </p>
        </template>
      </el-form-item>
      <el-form-item label="作者名" prop="author_name">
        <el-col :span="12">
          <template v-if="editType === 'view'">{{
            comicData.author_name
          }}</template>
          <el-input
            v-else
            v-model="comicData.author_name"
            placeholder="请输入作者名"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="作品介绍" prop="animation_brief">
        <el-col :span="12" class="comic-info-textarea">
          <template v-if="editType === 'view'">
            <el-tooltip
              class="item"
              effect="light"
              :content="comicData.animation_brief"
              placement="top-start"
            >
              <div class="width100 single-ellipsis">
                {{ comicData.animation_brief }}
              </div>
            </el-tooltip>
          </template>
          <template v-else>
            <el-input
              ref="textarea"
              type="textarea"
              v-model="comicData.animation_brief"
              :autosize="{ minRows: 4, maxRows: 6 }"
              placeholder="请输入关于作品的描述"
              :maxlength="maxlength"
            ></el-input>
            <div class="comic-info-textarea-length">
              {{ inputTxtLength }}/{{ maxlength }}
            </div>
          </template>
        </el-col>
      </el-form-item>
      <el-form-item label="价格" prop="chapter_price">
        <el-col :span="12">
          <span v-if="editType === 'view'" class="comic-info-name">{{
            comicData.chapter_price
          }}</span>
          <template v-else>
            <el-input
              v-model="comicData.chapter_price"
              placeholder="请输入动态漫价格"
            />
            <div class="search-box-message">
              <i class="el-icon-info"></i>请填写动态漫价格，若免费填0
            </div>
          </template>
        </el-col>
      </el-form-item>
      <template v-if="publicClass.length">
        <el-form-item label="连载状态" prop="serial_status">
          <template v-if="editType === 'view'">
            <!-- TODO -->
            <template
              v-for="item in serialStatusList"
              v-if="item.id === comicData.serial_status"
              >{{ item.name }}</template
            >
          </template>
          <el-radio-group v-else v-model="comicData.serial_status">
            <el-radio
              :label="item.id"
              :key="item.id"
              v-for="item in serialStatusList"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="受众" prop="audience_status">
          <template v-if="editType === 'view'">
            <template
              v-for="item in audienceStatusList"
              v-if="item.id === comicData.audience_status"
              >{{ item.name }}</template
            >
          </template>
          <el-radio-group v-else v-model="comicData.audience_status">
            <el-radio
              :label="item.id"
              :key="item.id"
              v-for="item in audienceStatusList"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="题材" prop="theme_ids">
          <el-col :span="16">
            <template v-if="editType === 'view'">
              <template
                v-for="item in publicClass"
                v-if="comicData.theme_ids.includes(item.class_id)"
                >{{ item.class_name }}
              </template>
            </template>
            <template v-else>
              <el-checkbox-group v-model="comicData.theme_ids">
                <el-checkbox
                  class="comic-info-theme"
                  :key="item.id"
                  v-for="item in publicClass"
                  :label="item.class_id"
                  >{{ item.class_name }}</el-checkbox
                >
              </el-checkbox-group>
              <p class="comic-info-tips">必选，限选1--3项</p>
            </template>
          </el-col>
        </el-form-item>
      </template>
      <el-form-item label="动态漫看点" prop="animation_feature">
        <template v-if="editType === 'view'">
          {{ comicData.animation_feature }}
        </template>
        <el-input
          v-else
          v-model="comicData.animation_feature"
          placeholder="请输入12-18字的动态漫看点"
        />
      </el-form-item>
      <el-form-item label="更新规律" prop="update_cycle">
        <template v-if="editType === 'view'">
          {{ comicData.update_cycle }}
        </template>
        <el-input
          v-else
          v-model="comicData.update_cycle"
          placeholder="请输入1-10字的更新规律"
        />
      </el-form-item>
      <el-form-item label="年份" prop="since_year">
        <el-col :span="4">
          <template v-if="editType === 'view'">
            {{ comicData.since_year }}
          </template>
          <el-input
            v-else
            v-model="comicData.since_year"
            placeholder="请输入年份"
          />
        </el-col>
      </el-form-item>
      <el-form-item label="原作漫画" prop="origin_comic_name">
        <template v-if="editType === 'view'">
          <span v-if="comicData.origin_comic_id !== 0"
            >{{ comicData.origin_comic_name }} 漫画ID:
            {{ comicData.origin_comic_id }}</span
          >
        </template>
        <template v-else>
          <el-col :span="4">
            <el-select
              v-model="selectedOriginComic"
              value-key="id"
              filterable
              clearable
              remote
              reserve-keyword
              @change="handleChangeOrginComic"
              placeholder="请输入漫画名称"
              :remote-method="searchOriginComic"
              :loading="loading"
            >
              <el-option
                v-for="item in matchedOriginComicList"
                :key="item.id"
                :label="item.label"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-input
              v-if="selectedOriginComic"
              readonly
              v-model="selectedOriginComic.id"
            >
              <template slot="prepend"> 漫画ID: </template>
            </el-input>
          </el-col>
        </template>
      </el-form-item>
      <el-form-item label="批量下载：" prop="allow_down">
        <template v-if="editType === 'view'">{{
          comicData.allow_down === 1 ? '支持下载' : '不支持下载'
        }}</template>
        <template v-else>
          <el-radio-group v-model="comicData.allow_down">
            <el-radio :label="1">支持下载</el-radio>
            <el-radio :label="0">不支持下载</el-radio>
          </el-radio-group>
        </template>
      </el-form-item>
      <el-form-item
        label="发布平台："
        prop="auth_platforms"
        class="comic-info-platform"
      >
        <template v-if="editType === 'view'">
          <div
            v-for="item in comicData.auth_platforms"
            :key="item.platform_id"
            class="mr10"
          >
            <span>{{ item.platform_name }}</span>
            <span v-if="item.is_timing === 1"
              >发布时间: {{ item.publish_time | timeFilter }}</span
            >
          </div>
        </template>
        <template v-else>
          <el-row v-for="item in mergedAuthPlatforms" :key="item.platform_id">
            <el-col :span="2">
              <el-checkbox v-model="item.check">{{
                item.platform_name
              }}</el-checkbox>
            </el-col>
            <el-col :span="16">
              <el-radio-group v-model="item.is_timing">
                <el-radio :label="0">立即发布</el-radio>
                <el-radio :label="1">定时发布</el-radio>
              </el-radio-group>
              <el-date-picker
                class="ml10"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-if="item.is_timing"
                v-model="item.publish_time"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </template>
      </el-form-item>
      <el-form-item v-if="!(editType === 'view')">
        <el-checkbox v-model="agreement">我同意</el-checkbox>
        <el-button
          type="text"
          @click.stop="changeDialog(true, '用户协议', 1, { name: 'agreement' })"
          size="medium"
          >中国漫画分发中心服务协议</el-button
        >
      </el-form-item>
      <!-- buttons -->
      <el-form-item v-if="!(editType === 'view')">
        <el-button type="primary" @click="submitFormHandle('comicForm')"
          >保 存</el-button
        >
        <el-button @click="cancelHandle('comicForm')">取消</el-button>
      </el-form-item>
      <el-form-item v-else>
        <el-button type="primary" @click="editFormHandle">编辑信息</el-button>
      </el-form-item>
      <!-- buttons-end -->
    </el-form>
    <cropper
      title="封面图剪切"
      :visible.sync="cropperData.visible"
      :image="cropperData.image"
      :options="cropperData.options"
      @close="closeCropper"
      @getCropperImage="getCropperImage"
    ></cropper>
    <dialogPlus
      :visible.sync="dialogData.visible"
      :title.sync="dialogData.title"
      :width="dialogData.data.name === 'editTag' ? '90%' : '530px'"
      :model="dialogData.data"
      :heightFull="true"
    >
      <agreement />
      <div slot="footer">
        <el-button @click="dialogCloseHandle">取消</el-button>
        <el-button type="primary" @click="agreementHandle">同意</el-button>
      </div>
    </dialogPlus>
  </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-17 14:58:30
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-17 14:58:30
 * @Description: 增加、修改漫画
 * @Company: 成都二次元动漫
 */
import { Grades } from '@/constants'
import agreement from '@/views/main/other/agreement'
import {
  formMixin,
  cropperMixin,
  anUploadMixin,
  anComicMixin,
  dialogMixin,
  dataMixin,
} from '@/mixins'
const COVER_TYPE = {
  cover34: 34,
  cover21: 21,
  cover43: 43,
}

export default {
  mixins: [
    formMixin,
    cropperMixin,
    anUploadMixin,
    dialogMixin,
    anComicMixin,
    dataMixin,
  ],
  props: {
    comicInfo: Object,
    publicClassList: Array,
  },
  data() {
    return {
      grades: Grades,
      editType: 'add',
      maxlength: 200,
      currentUploadType: '',
      auth_platforms: [],
      mergedAuthPlatforms: [],
      comicSelectList: [],
      matchedOriginComicList: [],
      selectedOriginComic: undefined,
      loading: false,
      agreement: true, // 同意协议
      // 连载状态
      serialStatusList: [
        {
          name: '连载',
          id: 1,
        },
        {
          name: '完结',
          id: 2,
        },
      ],
      audienceStatusList: [
        {
          name: '男生',
          id: 1,
        },
        {
          name: '女生',
          id: 2,
        },
        {
          name: '男生和女生',
          id: 3,
        },
      ],
    }
  },
  computed: {
    inputTxtLength() {
      if (this.comicData.animation_brief) {
        return this.comicData.animation_brief.length
      } else {
        return 0
      }
    },
  },
  beforeCreate() {
    if (!this.$route.tabtype) this.$emit('update:active', 0)
  },
  async created() {
    this.setBreadcrumb()
    await this.getComicPlatformList()
    await this.getComicSelectList()
    this.initData()
  },
  methods: {
    // 设置面包屑
    setBreadcrumb() {
      if (!this.comicInfo) {
        const { commit, state } = this.$store
        if (state.app.breadcrumb[2].name === 'addComic')
          state.app.breadcrumb.splice(2, 1)
        commit('app/setBreadcrumb', [...state.app.breadcrumb])
      }
    },
    // 初始化
    async initData() {
      console.log('publicClass=================', this.publicClass)
      const { animation_id = undefined, editType = 'add' } = this.$route.query
      this.editType = editType
      this.comicData.animation_id = animation_id
      // 如果当作组件使用，有数据跳过请求
      if (this.comicInfo) {
        this.publicClass = this.publicClassList
        this.comicData = this.comicInfo
        this.comicDataClone = this.$utils.cloneDeep(this.comicInfo)
        this.matchedOriginComicList.push({
          id: this.comicData.origin_comic_id,
          label: this.comicData.origin_comic_name,
        })
        if (this.comicData.origin_comic_id !== 0) {
          this.selectedOriginComic = {
            id: this.comicData.origin_comic_id,
            label: this.comicData.origin_comic_name,
          }
        } else {
          this.comicData.origin_comic_name = undefined
          this.comicData.origin_comic_id = undefined
        }
        this.auth_platforms.map((item) => {
          let resItem = this.comicData.auth_platforms.find((subItem) => {
            return subItem.platform_id === item.platform_id
          })
          if (resItem) {
            if (resItem.is_timing === 0) {
              delete resItem.publish_time
            }
            return this.mergedAuthPlatforms.push(
              Object.assign({}, resItem, { check: true })
            )
          }
          this.mergedAuthPlatforms.push(item)
        })
      } else if (['edit', 'view'].includes(this.editType)) {
        await this.getAllData([
          this.getPublicClass(),
          this.getComicData(this.comicData.animation_id),
        ])
      } else {
        await this.getAllData([this.getPublicClass()])
        this.auth_platforms.map((item) => {
          let tmpItem = Object.assign({}, item, { check: true, is_timing: 0 })
          console.log(this.mergedAuthPlatforms)
          this.mergedAuthPlatforms.push(tmpItem)
          console.log(this.comicData)
          // this.comicData.auth_platforms.push(tmpItem);
        })
      }
    },
    // 获取漫画平台列表
    async getComicPlatformList() {
      return this.$api('getPublicConfig').then(({ data }) => {
        data.platform_list.map((platform) => {
          if ([5].includes(platform.id)) {
            this.auth_platforms.push({
              platform_id: platform.id,
              platform_name: platform.name,
            })
          }
        })
      })
    },
    // 获取原作漫画列表
    async getComicSelectList() {
      return this.$api('getComicSelectList').then(({ data }) => {
        data.map((item) => {
          this.comicSelectList.push({
            id: item.comic_id,
            label: item.comic_name,
          })
        })
      })
    },
    searchOriginComic(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.matchedOriginComicList = this.comicSelectList.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
          })
        }, 200)
      } else {
        this.matchedOriginComicList = []
      }
    },
    handleChangeOrginComic(item) {
      if (item) {
        this.comicData.origin_comic_name = item.label
        this.comicData.origin_comic_id = item.id
      } else {
        this.comicData.origin_comic_name = undefined
        this.comicData.origin_comic_id = undefined
      }
    },
    // 图片选择
    uploadChangeHandle(file, fileList, id) {
      // 将选择图片放入剪切组件
      let options = {
        aspectRatio: 3 / 4,
        aspectWidth: 750,
        aspectHeight: 1000,
        minCropBoxHeight: 320,
        minPreviewWidth: 180,
        minPreviewHeight: 240,
      }

      // 第二个裁切组件配置
      if (id === 'cover21') {
        options = {
          ...options,
          aspectWidth: 800,
          aspectHeight: 400,
          aspectRatio: 2 / 1,
          minCropBoxWidth: 320,
          minPreviewWidth: 180,
          minPreviewHeight: 102,
        }
      } else if (id === 'cover43') {
        options = {
          ...options,
          aspectWidth: 1000,
          aspectHeight: 750,
          aspectRatio: 4 / 3,
          minCropBoxWidth: 320,
          minPreviewWidth: 240,
          minPreviewHeight: 180,
        }
      }
      this.currentUploadType = id

      this.validateImage(file, options)
        .then(async () => {
          const res = await this.getAnComicCoverToken(
            file.name,
            COVER_TYPE[this.currentUploadType]
          )
          console.log(res, '.../')
          this.comicData.animation_cover_domain = res.domain
          // 获取本地图片地址
          const reader = new FileReader()
          reader.readAsDataURL(file.raw)

          reader.onloadend = (e) => {
            this.showCropper(true, e.target.result, options)
          }
        })
        .catch(() => {})
    },
    validateImage(file, options) {
      return this.$utils.getImagePromise(file.raw).then((image) => {
        const { width, height } = image
        const { aspectWidth, aspectHeight } = options
        const isRt2M = file.size > 2 * 1024 * 1024
        if (isRt2M) {
          this.$notify.error({
            title: '图片错误',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<strong>名称：</strong>${file.name}<br /><strong>错误信息：</strong>请上传小于2M的图片`,
          })
          throw new Error('验证图片错误')
        }
        if (width !== aspectWidth || height !== aspectHeight) {
            this.$notify.error({
                title: '图片错误',
                duration: 0,
                dangerouslyUseHTMLString: true,
                message: `<strong>名称：</strong>${file.name}<br /><strong>错误信息：</strong>请上传指定尺寸的图片`
            });
            throw new Error('验证图片错误');
        }
      })
    },
    // 获取裁剪图片内容
    getCropperImage(blob) {
      const that = this
      this.uploadHandle({ file: blob }).then(({ name }) => {
        console.log(name)
        if (that.currentUploadType === 'cover34')
          that.comicData.animation_cover_3_4 = `/${name}`
        else if (that.currentUploadType === 'cover21')
          that.comicData.animation_cover_2_1 = `/${name}`
        else if (that.currentUploadType === 'cover43')
          that.comicData.animation_cover_4_3 = `/${name}`
        that.closeCropper()
      })
    },
    // 同意协议
    agreementHandle() {
      this.agreement = true
      this.dialogCloseHandle()
    },
    // 编辑漫画信息
    editFormHandle() {
      this.editType = 'edit'
      this.$nextTick(() => this.clearFormValidate('comicForm')) // 清空校验结果
    },
    // 取消编辑
    cancelHandle(formName) {
      this.clearFormValidate(formName)
      const { editType } = this.$route.query
      if (this.editType === 'add') this.resetDataField('comicData')
      else {
        this.comicData = this.$utils.cloneDeep(this.comicDataClone)
        if (editType === 'edit') this.$router.back()
        if (editType === 'view') this.editType = 'view'
      }
    },
    // 提交审核
    async submitFormHandle() {
      let auth_platforms = this.mergedAuthPlatforms.map((item) => {
        if (item.check) {
          if (item.is_timing === 0) {
            item.publish_time = undefined
          }
          const { platform_id, is_timing, publish_time } = item
          return {
            platform_id,
            is_timing,
            publish_time,
          }
        }
      })
      if (auth_platforms) {
        this.comicData.auth_platforms = auth_platforms
      }
      const valid = await this.validateForm('comicForm')
      if (!valid) {
        this.message && this.message.close()
        this.message = this.$message.error('动态漫信息填写有误!')
        return false
      }
      const {
        animation_id,
        animation_name,
        author_name,
        author_uid,
        animation_brief,
        animation_cover_3_4,
        animation_cover_4_3,
        animation_cover_2_1,
        serial_status,
        audience_status,
        theme_ids,
        animation_feature,
        update_cycle,
        since_year,
        animation_cover_domain,
        origin_comic_name,
        origin_comic_id,
        allow_down,
        chapter_price,
      } = this.comicData
      let url
      if (this.editType === 'add') url = 'addAnComic'
      else if (this.editType === 'edit') url = 'editAnComic'
      let data = {
        animation_id,
        animation_name,
        author_name,
        author_uid,
        animation_brief,
        animation_cover_3_4,
        animation_cover_4_3,
        animation_cover_2_1,
        theme_ids,
        serial_status,
        audience_status,
        auth_platforms,
        animation_feature,
        update_cycle,
        since_year,
        animation_cover_domain,
        origin_comic_name,
        origin_comic_id,
        allow_down,
        chapter_price,
      }
      this.$utils.confirm(
        '',
        '确认提交待审核吗？',
        '提交中...',
        (action, instance, done) => {
          return this.$api(url, data).then((res) => {
            this.$message.success({
              message: '提交成功！',
              duration: '1000',
              onClose: () => {
                // 如果为已有的漫画编辑页面，就不用跳转，否则刷新数据
                if (!this.$route.query.tabtype) {
                  this.$emit('update:active', 1)
                  if (this.editType === 'add') {
                    return this.$router.replace({
                      name: 'editAnChapter',
                      query: {
                        animation_id: res.data.animation_id,
                        animation_name,
                        editType: 'add',
                        chapter_price,
                      },
                    })
                  }
                  this.$router.replace({
                    name: 'addChapter',
                    query: {
                      type: 'add',
                      animation_name,
                      animation_id,
                      author_name,
                      tabtype: 1,
                      chapter_price,
                    },
                  })
                } else this.$router.back()
              },
            })
          })
        }
      )
    },
  },
  components: {
    agreement,
  },
}
</script>
<style lang="scss">
@import '../../../../styles/var';
.comic-info {
  &-textarea {
    position: relative;
    textarea {
      padding-bottom: 20px;
    }
    &-length {
      position: absolute;
      bottom: 5px;
      left: calc(100% + 10px);
      line-height: 1;
      color: #999;
    }
  }
  &-tips {
    font-size: 12px;
    color: #909399;
  }
  &-theme.el-checkbox {
    margin-left: 0;
    margin-right: 30px;
  }
  &-cover {
    width: 100%;
    height: 100%;
  }
  &-platform {
    .el-checkbox-group {
      flex-flow: row wrap;
      .el-checkbox {
        margin-left: 0;
        margin-right: 30px;
      }
    }
  }

  &-wrap {
    padding: 10px 0;
    .comic-info-item {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      .comic-info-categroy {
        box-sizing: border-box;
        width: 200px;
        font-size: 14px;
        flex-basis: 200px;
        padding: 5px 12px 0 0;
        line-height: 30px;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .comic-info-tags {
        flex: 1 0;
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        &-item {
          &-inner {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            vertical-align: middle;
          }
          margin: 5px;
          &.disabled {
            background: #ccc;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
