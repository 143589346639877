<template>
    <div class="an-audit-record-col">
        <!-- 图片展示 -->
        <div v-if="['animation_cover_3_4', 'animation_cover_2_1', 'animation_cover_4_3', 'chapter_cover_16_8'].includes(edit_column)" class="an-audit-record-colimg" v-scrollbar>
            <el-popover
              ref="popover"
              placement="top"
              title=""
              trigger="hover">
              <div class="audit-enlarged" v-lazy:background-image="coverDomain + $utils.filterImagePath(colDataJson)" :key="coverDomain + $utils.filterImagePath(colDataJson)"></div>
              <div slot="reference" class="thumbnail">
                  <img :key="$utils.filterImagePath(colDataJson)" v-lazy="coverDomain + $utils.filterImagePath(colDataJson)" alt="">
              </div>
          </el-popover>
        </div>
        <!-- 章节视频 -->
        <div class="chapter-video-preview" v-else-if="edit_column === 'chapter_video_id'">
          <span @click="previewHandle(colDataJson)">
              <SvgIcon icon="play"></SvgIcon>
          </span>
        </div>
        <!-- 题材 -->
         <div v-else-if="edit_column === 'theme_ids'">
           <template v-for="item in publicClass" v-if="colDataJson.includes(item.class_id)">{{item.class_name}} </template>
         </div>
         <!-- 连载 -->
         <div v-else-if="edit_column === 'serial_status'">
           <template v-for="item in serialStatusList" v-if="item.id === colDataJson">{{item.name}}</template>
         </div>
         <!-- 受众 -->
         <div v-else-if="edit_column === 'audience_status'">
           <template v-for="item in audienceStatusList" v-if="item.id === colDataJson">{{item.name}}</template>
         </div>
         <!-- 章节价格 -->
         <div v-else-if="edit_column === 'charge_type'">
           {{colDataJson === 0 ? '免费' : '付费' }}
         </div>
         <!-- 视频时长相关 -->
         <div v-else-if="['duration', 'free_sec', 'head_sec', 'tail_sec'].includes(edit_column)">
           {{colDataJson | reCovertTimeFilter }}
         </div>
         <!-- 下载状态 -->
         <div v-else-if="edit_column === 'allow_down'">
           {{colDataJson === 0 ? '不支持下载' : '支持下载' }}
         </div>
         <!-- 上下架 -->
         <div v-else-if="edit_column === 'show_status'">
           {{colDataJson === 1 ? '上架' : '下架' }}
         </div>
        <!-- 发布平台展示 -->
        <div v-else-if="edit_column === 'auth_platforms'" v-for="platform in colDataJson" :key="platform.platformId" class="platform-col flex-row-between">
            <div class="platform-col-name">{{formatPlatform(platform.platform_id)}}</div>
            <div v-if="platform.is_timing" class="platform-col-time">{{platform.publish_time | timeFilter('yyyy-MM-dd hh:mm:ss')}}</div>
            <div v-else class="platform-col-time">立即发布</div>
        </div>
        <template v-else-if="edit_column.length">{{colDataJson}}</template>
        <!-- 新增 -->
        <template v-else-if="edit_column === ''">--</template>
        <!-- 空 -->
        <template v-else-if="!colData">--</template>
        <!-- 特殊处理 -->
        <template v-else>{{colData == '[]' ? '--' : colData}}</template>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-29 10:46:49
 * @LastEditTime: 2018-09-29 10:46:49
 * @Description: 审核记录-修改前后-分类展示组件
 * @Company: 成都二次元动漫
 */
import { mapState } from 'vuex';
export default {
    props: {
        colData: {
            type: String,
            default: ''
        },
        edit_column: String,
        platformList: Array
    },
    data() {
        return {
            // 连载状态
            serialStatusList: [{
                name: '连载',
                id: 1
            },
            {
                name: '完结',
                id: 2
            }],
            // 受众
            audienceStatusList: [{
                name: '男生',
                id: 1
            },
            {
                name: '女生',
                id: 2
            },
            {
                name: '男生和女生',
                id: 3
            }]
        };
    },
    computed: {
        ...mapState('app', {
            // 题材
            publicClass: state => state.publicConfig.animation.class_configs,
            coverDomain: state => state.publicConfig.animation.cover_https_domain
        }),
        colDataJson() {
            let data = '--';
            try {
                data = JSON.parse(this.colData);
            } catch (error) {
                return this.colData;
            }
            return data;
        },
        // 图片
        imgData() {
            if (/\.(png|jpe?g|gif|svg)+/ig.test(this.colData)) {
                return /\[/g.test(this.colData) ? JSON.parse(this.colData) : [this.colData];
            } else return [];
        },
        // 发布平台
        publishData() {
            if (/(platform_id)+/g.test(this.colData)) return JSON.parse(this.colData);
            else return [];
        },
        // 连载类型、漫画类型、受众类型、题材类型
        types() {
            if (['serial', 'comicType', 'audience'].includes(this.editKey) && this.publicClass.length) {
                return this.$utils.formatPublicClass(this.publicClass, [this.colData])[`${this.editKey}Names`].join('、');
            }
            if (['theme'].includes(this.editKey) && this.publicClass.length) {
                return this.$utils.formatPublicClass(this.publicClass, JSON.parse(this.colData))[`${this.editKey}Names`].join('、');
            }
            return '';
        }
    },
    methods: {
        // 预览章节
        async previewHandle(chapter_video_id) {
            let animation_id = this.$route.query.animation_id;
            if (!this.link) {
                this.link = document.createElement('a');
            }
            await this.$api('getAnChapterVideoPlayAuth', {
                animation_id,
                chapter_video_id
            }).then(res => {
                this.link.setAttribute('href', `/static/video_preview.html?playauth=${res.data.PlayAuth}&vid=${chapter_video_id}`);
                this.link.setAttribute('target', '_blank');
                this.link.click();
            });
        },
        // 格式化平台名称
        formatPlatform(platformId) {
            const platform = this.platformList.find(platform => (platform.id === platformId));
            if (platform) {
                return platform.name;
            }
            return '';
        }
    }
};
</script>

<style lang="scss">
    .an-audit-record-col{
        width: 100%;
        &Slide{
            width: 100%;
            overflow: hidden;
            position: relative;
        }
        &img{
            height: 40px;
            position: relative;
            overflow: hidden;
            text-align: center;
            .thumbnail{
                padding-right: 10px;
                box-sizing: border-box;
                display: inline-block;
                height: 20px;
                cursor: pointer;
                img{
                    height: 100%;
                }
            }
        }
        .chapter-video-preview .icon{
          font-size: 28px;
          color: #999;
          cursor: pointer;
        }
    }
    .audit-enlarged{
        width: 200px;
        height: 200px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>
