<template>
    <div class="audit-table">
        <div v-show="!isTable" class="audit-table-wrap" ref="table" v-loading="isloading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading" v-empty="noData">
            <template v-if="tableList.length">
                <auditRelatedVideoCard
                    v-for="card in tableList"
                    ref="card"
                    :key="card.correlation_id"
                    :comicData="card"
                    @preview="previewHandle"
                    @search="getTableList"
                >
                </auditRelatedVideoCard>
            </template>
        </div>
        <paginationPlus :currentPage.sync="pagination.page_index" :page_size.sync="pagination.page_size" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
    </div>
</template>
<script>
import { paginationMixin, anComicVideoMixin } from '@/mixins';
import anRoleMixin from '@/mixins/anRole';
import { mapState } from 'vuex';

export default {
    mixins: [paginationMixin, anComicVideoMixin, anRoleMixin],
    props: {
        isTable: Boolean,
        index: String
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level, // 1：用户 2：管理 3：超管 4:一审人员
            platform_list: state => state.publicConfig.platform_list,
            class_configs: state => state.publicConfig.animation.class_configs
        })
    },
    data() {
        return {
            isloading: false,
            noData: true,
            tableList: []
        };
    },
    methods: {
        // 搜索接收时间
        searchEvent(searchData) {
            this.searchData = searchData;
            this.getTableList();
        },
        // 预览相关视频
        previewHandle({correlation_video_id}) {
            if (!this.link) {
                this.link = document.createElement('a');
            }
            this.$api('getRelatedVideoPlayAuth', {
                correlation_video_id
            }).then(res => {
                this.link.setAttribute('href', `/static/video_preview.html?playauth=${res.data.PlayAuth}&vid=${correlation_video_id}`);
                this.link.setAttribute('target', '_blank');
                this.link.click();
            });
        },
        // 去审核
        gotoAuditHandle({ correlation_id, animation_name, audit_status }) {
            this.$router.push({ name: 'auditAnComicInfo', query: { correlation_id, animation_name, audit_status } });
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            const that = this;
            let pagination = {};
            for (let [key, value] of Object.entries(this.pagination)) {
                if (key === 'total') {
                    continue;
                }
                pagination[this.$utils.snakeCase(key)] = value;
            }
            return this.$api('getAuditRelatedVideoList_Admin', { ...this.searchData, ...pagination }).then(res => {
                that.$refs.table && (that.$refs.table.scrollTop = 0);
                const { rows, page_index, page_size, total, status_data } = res.data;
                this.noData = (rows.length !== 0);
                this.tableList = rows;
                this.pagination = {
                    ...this.pagination,
                    page_index: page_index,
                    page_size: page_size,
                    total
                };
                // console.log(status_data);
                this.$emit('changeTypeNumber', this.index, this.index === 'all' ? total : status_data[this.index]);
                this.isloading = false;
            });
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    }
};
</script>

<style lang="scss">
    @import '../../../../styles/var';
    .audit-table{
        height: 100%;
        &-wrap{
            height: calc(100% - 48px);
            overflow-y: auto;
            position: relative;
        }
        &-list{
            height: calc(100% - 48px);
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
        }
    }
</style>
