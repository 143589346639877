<!--
 * @Author: daipeng
 * @Date: 2019-09-06 11:50:03
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-18 12:10:18
 * @Description: 审核漫画卡片
 -->
<template>
    <div class="audit-related-video-card flex-row" :class="{'has-deffer' : comicData.defer_status, 'unshelve': comicData.online_show_status === 0}">
        <div class="audit-related-video-card-img">
            <img :key="comicData.correlation_cover_domain + comicData.correlation_cover_16_8" v-lazy="comicData.correlation_cover_domain + $utils.filterImagePath(comicData.correlation_cover_16_8)" alt="">
            <div v-if="comicData.online_show_status === 0">已下架</div>
        </div>
        <dl class="audit-related-video-card-info" style="overflow: hidden;">
            <dt class="flex-row-between">
                <div class="audit-related-video-card-title flex-row">
                    <span class="comic-name">{{comicData.correlation_name}}</span>
                    <!-- <span class="comic-btn-edit comic-dispatch-primary cursor-pointer" @click="editComicHandle(comicData)"><i class="el-icon-edit"></i>编辑漫画</span> -->
                </div>
                <div :class="comicData.audit_status | auditAnStatusStyleFilter">
                    {{comicData.audit_status | auditAnStatusFilter(level)}}
                    <el-tooltip placement="top" v-if="[3, 4, -1].includes(comicData.audit_status)">
                        <div slot="content">{{comicData.audit_remark || ([3, -1].includes(comicData.audit_status) ?'审核未通过': '审核通过')}}</div>
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </div>
            </dt>
            <dd class="comic-dispatch-time">
                <div>
                    <span class="comic-dispatch-label">创建时间：</span>
                    <span>{{comicData.create_time | timeFilter('yyyy年MM月dd日 hh:mm')}}</span>
                </div>
            </dd>
            <dd class="related-comic">
                <template v-if="comicData.animations.length">
                  <span class="comic-dispatch-label">关联动态漫：</span>
                  <span class="comic-dispatch-content">
                      <template v-for="(item, index) in comicData.animations">
                        <span :key="item.animation_id" :title="item.animation_name">
                          {{item.animation_name}}
                        </span>
                        <template v-if="index + 1 !== comicData.animations.length"> 、</template>
                      </template>
                  </span>
                </template>
            </dd>
            <dd v-if="comicData.defer_desc">
                <div class="comic-dispatch-deffer">
                    <span class="comic-dispatch-label">延迟备注：</span>
                    <el-tooltip placement="top">
                        <div slot="content">{{comicData.defer_desc}}</div>
                        <span>{{comicData.defer_desc}}</span>
                    </el-tooltip>
                </div>
            </dd>
            <dd class="audit-related-video-card-btns">
                <template v-if="couldOperate(comicData, level)">
                    <el-button size="mini" @click="$emit('preview', comicData)">预览</el-button>
                    <el-button size="mini" :disabled="couldAudit(comicData, level)" v-if="hasAuditBtn(comicData, level)" type="primary" @click="gotoAuditHandle(comicData)">审核</el-button>
                </template>
                <template v-if="[2].includes(level) && checkAuth('userAnimationCorrelationController.updateShowStatus')">
                    <el-button v-if="comicData.online_show_status === 0" type="primary" @click="handleDisplayComic(1)" size="mini">上架</el-button>
                    <el-button type="primary" v-else @click="handleDisplayComic(0)" size="mini">下架</el-button>
                </template>
            </dd>
        </dl>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-14 09:55:44
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-14 09:56:05
 * @Description: 漫画审核卡片
 * @Company: 成都二次元动漫
 */

import { Grades } from '@/constants';
import { mapState } from 'vuex';
import { dialogPlus } from '@/components';
import anRoleMixin from '@/mixins/anRole';

export default {
    mixins: [anRoleMixin],
    components: {
        dialogPlus
    },
    props: {
        comicData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            grades: Grades
        };
    },
    methods: {
        // 二审上下架漫画
        handleDisplayComic(show_status) {
            const msg = show_status === 0 ? '下架' : '上架';
            this.$utils.confirm('', `确定${msg}该相关视频吗？`, `${msg}中...`, (action, instance, done) => {
                return this.$api('displayRelatedVideoForAuditor', { show_status, correlation_id: this.comicData.correlation_id }).then(res => {
                    this.comicData.online_show_status = show_status;
                });
            });
        },
        couldAudit(comicData, level) {
            // 审核中/拒绝就放开点击
            const maps = {
                // 二审人员
                '2': [4, 5],
                // 一审人员
                '4': [2, 3]
            };
            if (maps[level].includes(comicData.audit_status)) {
                return false;
            }
        },
        couldOperate(comicData, level) {
            // case 2: return level === 4 ? '待审核' : '一审审核中';
            // case 1: return '待提交';
            // case 3: return '一审未通过';
            // case 4: return '二审审核中';
            // case 5: return '二审未通过';
            // case 6: return '二审已通过';
            const maps = {
                // 二审人员
                '2': [4, 5, 6],
                // 一审人员
                '4': [1, 2, 3]
            };
            return maps[level].includes(comicData.audit_status);
        },
        // 去审核
        gotoAuditHandle({ correlation_id, correlation_name }) {
            this.$router.push({ name: 'auditRelatedVideoInfo', query: { correlation_id, correlation_name } });
        }
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        })
    }
};
</script>
<style lang="scss">
    .audit-related-video-card {
        float: left;
        position: relative;
        width: calc(50% - 10px);
        min-width: 560px;
        height: 190px;
        background-color: #fff;
        border: 1px solid #e4e7ea;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 2px;
        margin-bottom: 20px;
        &.unshelve {
           .audit-related-video-card-img {
             filter: grayscale(1);
             > div {
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               height: 20px;
               line-height: 20px;
               background-color: rgba(255,255,255, .7);
               text-align: center;
               color: #666;
             }
           }
        }

         &.has-deffer{
            background: #f5ecec;
        }

        .el-dropdown .el-dropdown__caret-button .el-dropdown__icon{
            vertical-align: top;
        }
        &.isMouseOver{
            box-shadow: 0px 0px 20px rgba(102, 102, 102, 0.35);
        }
        &:nth-child(odd){
            margin-right: 20px;
        }
        &-img{
            position: relative;
            width: 316px;
            height: 100%;
            background-color: #e4e7ea;
            border-right: 1px solid #e4e7ea;
            cursor: pointer;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
        &-info{
            padding: 10px;
            flex-grow: 1;
            font-size: 14px;
            dt, dd {
                height: 30px;
                line-height: 30px;
                color: #606266;
            }
            .comic-name{
                max-width: 140px;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .related-comic {
              display: flex;
              flex-wrap: nowrap;
              flex: 1;
              height: auto;
              white-space: nowrap;
            }
            .comic-dispatch-label {
              width: 6em;
              flex: 0 0 6em;
              white-space: nowrap;
              overflow: hidden;
            }
            .comic-dispatch-content {
              display: flex;
              flex-wrap: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              span {
                flex: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
        }
        &-title{
            align-items: center;
        }
        &-btns{
            font-size: 0;
            margin: 10px 0 0 0;
            .el-button{
                padding: 7px 10px;
            }
            .el-dropdown{
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .comic-btn-edit{
            font-size: 12px;
            margin-left: 10px;
        }
        .comic-dispatch-time{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
         .comic-dispatch-deffer{
            display: inline-block;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            width: 90%;
        }
    }
</style>
