<template>
    <dialogPlus
        center
        :visible.sync="dialogData.visible"
        :title.sync="dialogData.title"
        width="800px"
        :heightFull="false"
        :close-on-click-modal="false"
        @closed="close"
    >
        <div class="pop-comic-publish">
            <template v-for="platform in editPlatformList">
                <div class="pop-comic-publish__wrap" :key="platform.platformId">
                    <span class="pop-comic-publish__title">{{ platform.name }}</span>
                    <div class="pop-comic-publish__content">
                        <el-radio-group v-model="platform.selectTypeId" :disabled="!platform.edit">
                            <el-radio :label="1">立即发布</el-radio>
                            <el-radio :label="2">定时在该平台发布</el-radio>
                            <el-radio :label="3" :disabled="checkOriginStatus(platform)">下架</el-radio>
                        </el-radio-group>
                        <el-date-picker
                            type="datetime"
                            default-time="08:00:00"
                            :editable="false"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerDisabled"
                            :disabled="platform.selectTypeId !== 2 || !platform.edit"
                            v-model="platform.publishTime"
                            placeholder="选择日期时间"
                            @change="datePickerChange(platform)"
                        ></el-date-picker>
                    </div>
                    <div class="pop-comic-publish__operation">
                        <el-button size="mini" @click="publishPlatform(platform)" :disabled="!platform.edit">发布</el-button>
                        <el-button size="mini" @click="switchPlatform(platform)">{{ platform.edit? '取消' : '编辑' }}</el-button>
                    </div>
                </div>
            </template>
        </div>
    </dialogPlus>
</template>

<script>

/*
 * @Author: lufeng
 * @Date: 2019-06-06 10:35:00
 * @LastEditors: vscode
 * @LastEditTime: 2019-06-06 10:35:00
 * @Description: 漫画发布管理
 * @Company: 成都二次元动漫
 */

import { dialogMixin } from '@/mixins';

export default {
    mixins: [dialogMixin],
    props: {
        comicData: { type: Object, default: () => {} }, // 文章数据
        platformList: { type: Array, default: () => [] } // 平台列表
    },
    data() {
        return {
            editPlatformList: [], // 用于编辑的平台列表
            originData: {}, // 原始漫画数据
            pickerDisabled: { // 时间框时间选项
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            }
        };
    },
    watch: {
        comicData: {
            immediate: true,
            handler: function(newVal) {
                if (newVal) {
                    this.originData = this.$utils.cloneDeep(newVal);
                    this.editPlatformList = this.$utils.cloneDeep(this.platformList);
                    this.$nextTick(() => {
                        this.initPlatform();
                    });
                }
            }
        }
    },
    methods: {
        close() {
            this.$emit('update:comicData', null);
        },
        // 初始化编辑平台
        initPlatform() {
            this.initOperationPlatformList();
            this.changeDialog(true, this.originData.comicName + '漫画渠道发布', 2, this.originData);
        },
        // 检查原始章节，设置下架状态
        checkOriginStatus(platform) {
            const origin = this.getOriginPlatform(platform);
            if (!origin) {
                return true;
            }
            return origin.status === 0;
        },
        // 编辑切换
        switchPlatform(platform) {
            platform.edit = !platform.edit;
            if (platform.edit === false) {
                this.resetPlatFormInfo(platform);
            }
        },
        // 初始化平台数据
        initOperationPlatformList() {
            this.editPlatformList.forEach(platform => {
                const origin = this.originData.platforms.find(item => {
                    return item.platformId === platform.platformId;
                });
                this.setEditPlatInfo(platform, origin);
            });
        },
        // 平台发布
        publishPlatform(platform) {
            if (platform.selectTypeId === null) {
                this.$message.error('请选择发布类型');
                return;
            }
            if (platform.selectTypeId === 2 && platform.publishTime === null) {
                this.$message.error('请选择发布时间');
                return;
            }
            let text = `${platform.name}</br>
            <span style="font-size:12px;">
            ${platform.selectTypeId === 2 && platform.publishTime ? `于 ${this.$utils.timeFormat(platform.publishTime, 'yyyy-MM-dd hh:mm:ss')} ` : '立即'}发布
            </span>`;
            if (platform.selectTypeId === 3) {
                text = `${platform.name}</br><span style="font-size:12px;">下架</span>`;
            }
            this.$confirm(`${text}`, '', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                center: true,
                showClose: false
            }).then(() => {
                this.publishHandle(platform);
            });
        },
        // 发布管理
        publishHandle(platform) {
            const { comicId, comicName, authorName } = this.originData;
            const status = platform.selectTypeId === 3 ? 0 : 1;
            const publishTime = platform.selectTypeId === 2 ? platform.publishTime : null;
            const platformId = platform.platformId;
            const isTiming = platform.selectTypeId === 1 ? 0 : 1;
            this.$api('publishComic', { isTiming, publishTime, status, comicId, comicName, authorName, platformId }).then(res => {
                this.$message.success('发布成功！');
                this.$emit('refreshData', this.originData);
            }).finally(() => {
                platform.edit = false;
            });
        },
        // 还原平台发布信息
        resetPlatFormInfo(platform) {
            const origin = this.getOriginPlatform(platform);
            this.setEditPlatInfo(platform, origin);
        },
        // 根据现有平台选项获取原始平台数据
        getOriginPlatform(platform) {
            return this.originData.platforms.find((item) => {
                return item.platformId === platform.platformId;
            });
        },
        // 设置发布平台编辑值
        setEditPlatInfo(platform, origin) {
            platform.selectTypeId = null;
            platform.publishTime = null;
            if (!origin) {
                return;
            }
            if (origin.status === 0) {
                platform.selectTypeId = 3;
                return;
            }
            platform.selectTypeId = 1;
            if (origin.publishTime !== null) {
                platform.selectTypeId = 2;
                platform.publishTime = origin.publishTime;
            }
        },
        // date-picker选择
        datePickerChange(platform) {
            if (new Date(platform.publishTime).getTime() < Date.now()) {
                this.$message.error('只能选择当前时间以后的时间');
                platform.publishTime = null;
            }
        }
    }
};
</script>
<style lang="scss">
.pop-comic-publish{
    text-align: center;
    max-height: 600px;
    overflow-y: auto;
    padding:0 20px 20px;
    &__wrap{
        margin: 10px 0;
    }
    &__title{
        text-align: left;
        display: inline-block;
        width: 80px;
        font-weight: bold;
        font-size: 14px;
        vertical-align: middle;
    }
    &__operation{
        display: inline-block;
        margin-left: 10px;
    }
    &__content{
        display: inline-block;
        min-width: 444px;
        text-align: left;
    }
    .el-radio__label{
        font-size: 12px;
    }
    .el-radio{
        margin-right: 10px;
    }
}
</style>
