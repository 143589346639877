<template>
  <div class="customer-login" :class="{ ding: dingLogin }">
    <el-container class="customer-login">
      <el-main>
        <div class="customer-login-con" @keydown.enter.stop="submitHandle">
          <div
            class="switch-login"
            v-show="!bindAccount && !hasTip"
            @click="switchLogin()"
          >
            {{ switchTxt }}
          </div>
          <figure class="customer-logo">
            <img src="/static/images/logo.png" width="350" height="234" />
            <p>客服查询系统</p>
          </figure>
          <el-alert
            v-if="hasTip"
            :title="switchNextTxt"
            type="error"
            :closable="false"
          >
          </el-alert>
          <el-form :model="form" ref="loginForm" v-show="!dingLogin">
            <el-form-item prop="userName" :rules="rules.userName">
              <el-input
                v-model.trim="form.userName"
                placeholder="输入用户名"
                prefix-icon="el-icon-phone"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="password" :rules="rules.password">
              <el-input
                v-model.trim="form.password"
                placeholder="输入密码"
                type="password"
                prefix-icon="el-icon-password"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :span="12">
                  <!-- <el-checkbox v-model="checked">自动登录</el-checkbox> -->
                </el-col>
                <el-col :span="12" class="customer-tar">
                  <!-- <router-link to="/forgert" class="customer-primary">忘记密码</router-link> -->
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item align="center">
              <el-button
                type="primary"
                @click="submitHandle"
                class="customer-percent"
                >{{ switchLoginTxt }}</el-button
              >
            </el-form-item>
          </el-form>
          <div v-show="dingLogin">
            <div id="login_container" class="code-box" v-show="!scanDingSucc" />
            <div class="code-box succ" v-show="scanDingSucc && !bindAccount">
              <span>扫码成功</span>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer class="login-footer">
        Copyright&copy;2017 中国漫画分发中心<br />
        <a
          style="color: #fff"
          ref="nofollow"
          target="_blank"
          href="http://beian.miit.gov.cn"
          >蜀ICP备17035423号-2</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;成都二次元动漫有限公司
      </el-footer>
    </el-container>
  </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-03 13:28:29
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-03 13:42:45
 * @Description: 客服查询系统-登录
 * @Company: 成都二次元动漫
 */
import { formMixin } from '@/mixins'
export default {
  mixins: [formMixin],
  data() {
    return {
      userSesionKey: '__loginUserInfo__',
      dingErrorText: '',
      dingCode: '', // 钉钉临时授权码
      dingLogin: true, // 是否钉钉登录界面
      scanDingSucc: false, // 是否扫描钉钉成功
      bindAccount: false, // 是否绑定账号界面
      hasTip: false, // 是否中间提示
      requestUrl: {
        // 1 普通登录，2扫码登录，3绑定登录
        1: 'login',
        2: 'scanLogin',
        3: 'bindLogin',
      },
      form: {
        userName: '',
        password: '',
      },
      rules: {
        userName: [{ equired: true, message: '账号不能为空', trigger: 'blur' }],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码至少为六位', trigger: 'blur' },
        ],
      },
      checked: true,
    }
  },
  mounted() {
    this.loginCheck()
    this.loadDing()
  },
  computed: {
    switchTxt() {
      return this.dingLogin ? '密码登录' : '扫码登录'
    },
    switchLoginTxt() {
      return this.scanDingSucc ? '绑定' : '登录'
    },
    switchNextTxt() {
      if (this.dingErrorText) {
        return this.dingErrorText
      }
      return this.bindAccount
        ? '首次扫码，请进行系统账号绑定'
        : '密码登录需要再次扫码核验'
    },
  },
  methods: {
    switchLogin() {
      this.dingLogin = !this.dingLogin
      this.dingCode = ''
      this.form.userName = ''
      this.form.password = ''
    },
    loginCheck() {
      const { code, path } = this.$route.query
      if (!code) {
        return
      }

      const userInfo = this.$session.get(this.userSesionKey)
      if (userInfo) {
        this.form = { ...this.form, ...userInfo }
        this.$session.remove(this.userSesionKey)
      }
      this.scanDingSucc = true
      this.dingCode = code
      this.$router.replace({ path })
      let loginType = userInfo && !userInfo.isBindDingTalk ? 3 : 2

      // 1 普通登录，2扫码登录，3绑定登录
      this.fireLogin(() => {
        // 扫码登录失败，如果没有绑定，则提示
        this.bindAccount = true
        this.dingLogin = false
        this.hasTip = true // 提示绑定
      }, loginType)
    },
    loadDing() {
      const url = `${window.location.protocol}//${window.location.host}/login`
      const norGo = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${this.$config.dingAppId}&response_type=code&scope=snsapi_login&redirect_uri=${url}`
      const goto = encodeURIComponent(norGo)

      /* eslint-disable */
      DDLogin({
        id: 'login_container',
        goto: goto,
        style: 'border:none;background-color:#FFFFFF;',
        width: '260',
        height: '300',
      })
      /* eslint-enable */

      const handleMessage = (event) => {
        const origin = event.origin
        if (origin !== 'https://login.dingtalk.com') {
          // 判断是否来自ddLogin扫码事件。
          return
        }
        const dingCode = event.data
        if (!dingCode) {
          return
        }
        const herf = `${norGo}&loginTmpCode=${dingCode}`
        window.location.href = herf
      }
      if (typeof window.addEventListener !== 'undefined') {
        window.addEventListener('message', handleMessage, false)
      } else if (typeof window.attachEvent !== 'undefined') {
        window.attachEvent('onmessage', handleMessage)
      }
    },
    loginSucc(data) {
      this.$store.commit('app/setUserInfo', data)
      this.$message({
        type: 'success',
        message: '登录成功~',
        duration: '500',
        onClose: () => {
          if ([2, 3, 4].includes(data.level))
            this.$router.replace({ name: 'personalCenter' })
          else this.$router.replace({ name: 'welcome' })
        },
      })
    },
    fireLogin(callback, loginType = 1) {
      // 1 普通登录，2扫码登录，3绑定登录
      const postData = { ...this.form, dingCode: this.dingCode }
      this.$api(this.requestUrl[loginType], postData)
        .then(({ data }) => {
          if (loginType !== 1) {
            // 非普通登录的正常状态，即登录成功
            this.loginSucc(data)
            return
          }
          const sessionInfo = {
            ...this.form,
            isBindDingTalk: data.isBindDingTalk,
          }
          this.$session.set(this.userSesionKey, sessionInfo) // 记录账户信息用于扫码绑定
          this.hasTip = true // 提示需要扫码
          callback()
        })
        .catch((ex) => {
          if ([1, 3].includes(loginType)) {
            // 普通登录|绑定报错，不进行回调
            return
          }

          let status = -1
          if (ex && ex.data) {
            status = ex.data.status
          }
          if (status === 11003) {
            // 扫码未绑定
            callback()
            return
          }
          this.dingErrorText = `钉钉错误：${status}, 请联系开发人员`
          this.hasTip = true // 提示绑定
        })
    },
    async submitHandle() {
      const valid = await this.validateForm('loginForm')
      if (!valid) return false
      // 1 普通登录，2扫码登录，3绑定登录
      const loginType = this.dingCode ? 3 : 1 // 有钉钉码点击登录，说明是绑定登录
      this.fireLogin(() => {
        this.dingLogin = true
      }, loginType)
    },
  },
}
</script>
<style lang="scss">
@import '../../styles/var';
.customer-login {
  width: 100%;
  height: 100%;
  background-color: #ccc;
  background-image: url('/static/images/globel_bg.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  .customer-login-con {
    text-align: center;
  }
  .el-alert {
    width: auto;
    display: inline-block;
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .switch-login {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
  }
  .code-box {
    height: 300px;
    width: 304px;

    &.succ {
      font-size: 18px;
      color: green;
      justify-content: center;
      align-items: center;
      display: flex;
      span {
        padding: 78px;
        background: #f0f9eb;
      }
    }
  }
  &.ding {
    .customer-logo {
      margin-bottom: -15px;
    }
    .login_qrcode_content {
      margin-top: 20px;
    }
    .el-alert {
      margin-top: 0;
      margin-bottom: -30px;
    }
  }
  &-con {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    padding: 20px;
    transform: translate(-50%, -60%);
    background-color: $color-white;
    background-clip: padding-box;
    border: 8px solid rgba($color-primary-light-9, 0.5); // border-radius: $border-radius-base;
    &-header {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      padding: 30px 0;
    }
    .form-con {
      padding: 10px 0 0;
    }
    .login-tip {
      font-size: 10px;
      text-align: center;
      color: #c3c3c3;
    }
  }
  .customer-logo {
    margin: 0 0 20px 0;
    text-align: center;
    img {
      width: 200px;
      height: 64px;
    }
    p {
      display: none;
    }
  }
  .customer-title {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }
}

.login {
  &-weixin,
  &-qq,
  &-sina {
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    color: $color-text-regular;
    [class*='el-icon-'] {
      display: block;
      width: 36px;
      height: 36px;
      margin: 10px auto 5px;
      border: 1px solid $color-primary;
      border-radius: 50%;
      line-height: 36px;
      font-size: 20px;
      text-align: center;
    }
  }
  &-qq {
    [class*='el-icon-'] {
      border-color: $color-primary;
      color: $color-primary;
    }
  }
  &-sina {
    [class*='el-icon-'] {
      border-color: #eb7350;
      color: #eb7350;
    }
  }
  &-weixin {
    [class*='el-icon-'] {
      border-color: #609700;
      color: #609700;
    }
  }
  @at-root .customer-title {
    font-size: 14px;
    color: $color-text-regular;
  }
  @at-root &-footer {
    height: 40px !important;
    text-align: center;
    font-size: 12px;
    opacity: 0.5;
    line-height: 1.25;
    color: $color-white;
  }
}

.three-party {
  padding-top: 10px;
  border-top: 1px solid $border-color-lighter;
}

.el-input-group__append,
.el-input-group__prepend {
  padding: 0 10px;
}
</style>
