import { render, staticRenderFns } from "./auditComicDialog.vue?vue&type=template&id=4b7facf1&scoped=true"
import script from "./auditComicDialog.vue?vue&type=script&lang=js"
export * from "./auditComicDialog.vue?vue&type=script&lang=js"
import style0 from "./auditComicDialog.vue?vue&type=style&index=0&id=4b7facf1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b7facf1",
  null
  
)

export default component.exports