<template>
    <div class="user-dialog">
        <el-form :model="user" label-width="80px" class="edit-form">
            <el-row v-if="type === 1">
                <el-col :span="16">
                    <el-form-item label="用户名称" :rules="[
                        { required: true, message: '用户名称不能为空'},
                    ]">
                        <el-input minlength="1" maxlength="20" v-model='userName' show-word-limit placeholder="请输入用户名称"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="type === 1">
                <el-col :span="10">
                    <el-form-item label="账号类型">
                        <el-select v-model="userType">
                            <el-option
                            v-for="item in userTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col v-if="[1,4].includes(userType)" :span="11">
                    <el-form-item label-width="120px" label="CP团队类型">
                         <el-select v-model="teamType">
                            <el-option
                            v-for="item in teamTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" v-if="[2].includes(type)">
                <el-col :span="16">
                    <el-form-item label="用户名称" :rules="[
                        { required: true, message: '用户名称不能为空'},
                    ]">
                        <el-input minlength="8" maxlength="20" :disabled="isEdit" v-model='user.userName' show-word-limit placeholder="请输入用户名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-button type="primary" @click="isEdit = false">编辑</el-button>
                </el-col>
            </el-row>
            <el-row v-if="[2].includes(type)">
                <el-col :span="10">
                    <el-form-item label="账号类型:">{{ userLevelName[user.userLevel] }}</el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="CP团队类型:" label-width="120px">{{teamTypeName[user.teamType]}}</el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="[0].includes(type)">
                <el-col :span="8">
                    <el-form-item label="账号:">{{ user.userId }}</el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="用户名称:">{{ user.userName }}</el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="用户来源:">{{user.userPlatform}}</el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="[0].includes(type)">
                <el-col :span="8">
                    <el-form-item label="注册时间:">{{ user.createTime | timeFilter }}</el-form-item>
                </el-col>
                <el-col :span="16">
                    <el-form-item label="账号状态:">
                        <span v-if="user.enable === 0">已禁用</span>
                        <span v-else-if="user.enable === 1" class="mr10">已启用</span>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="type === 0">
                <el-col :span="24">
                    <el-tooltip :disabled="user.permissions && !user.permissions.length" class="item" effect="light" :content="user.permissions | userPermissionFilter('、')" placement="top-start">
                        <el-form-item label="可用权限:">{{ user.permissions | userPermissionFilter('、')}}</el-form-item>
                    </el-tooltip>
                </el-col>
            </el-row>
            <!-- 新增/编辑内容-->
            <template v-if="[1, 2].includes(type)">
                <el-form-item label="可用权限:" class="width100">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllChangeHandle">全选</el-checkbox>
                    <el-checkbox-group v-model="list" @change="checkedChangeHandle">
                        <div class="permission-group" v-for="group in permissionList" :key="group.title">
                            <template v-if="group.get.length || group.post.length || group.put.length || group.delete.length">
                                <h2 class="permission-group-title">{{group.title}}</h2>
                                <div v-if="group.get.length" class="flex-row">
                                    <div class="permission-group-option mr10">查看权限</div>
                                    <div class="permission-group-check">
                                        <el-checkbox :disabled="permission.isDisable" v-for="permission in group.get" :label="permission" :key="permission.id">{{permission.name}}</el-checkbox>
                                    </div>
                                </div>
                                <div v-if="group.post.length" class="flex-row">
                                    <div class="permission-group-option mr10">新增权限</div>
                                    <div class="permission-group-check">
                                        <el-checkbox v-for="permission in group.post" :label="permission" :key="permission.id">{{permission.name}}</el-checkbox>
                                    </div>
                                </div>
                                <div v-if="group.put.length" class="flex-row">
                                    <div class="permission-group-option mr10">修改权限</div>
                                    <div class="permission-group-check">
                                        <el-checkbox v-for="permission in group.put" :label="permission" :key="permission.id">{{permission.name}}</el-checkbox>
                                    </div>
                                </div>
                                <div v-if="group.delete.length" class="flex-row">
                                    <div class="permission-group-option mr10">删除权限</div>
                                    <div class="permission-group-check">
                                        <el-checkbox v-for="permission in group.delete" :label="permission" :key="permission.id">{{permission.name}}</el-checkbox>
                                    </div>
                                </div>
                                <div v-if="group.funtions.length" class="flex-row">
                                    <div class="permission-group-option mr10">功能权限</div>
                                    <div class="permission-group-check">
                                        <el-checkbox v-for="permission in group.funtions" :label="permission" :key="permission.id">{{permission.name}}</el-checkbox>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </el-checkbox-group>
                </el-form-item>
            </template>
        </el-form>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-27 09:37:23
 * @LastEditTime: 2018-09-27 09:37:23
 * @Description: 用户设置设置 view/edit/add 弹出框
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        user: Object,
        type: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            list: [],
            userName: '',
            permissionList: [
                { title: '个人相关权限', get: [], delete: [], put: [], post: [], funtions: [] },
                { title: '普通用户漫画相关权限', get: [], delete: [], put: [], post: [], funtions: [] },
                { title: '管理员漫画相关权限', get: [], delete: [], put: [], post: [], funtions: [] },
                { title: '普通用户动态漫相关权限', get: [], delete: [], put: [], post: [], funtions: [] },
                { title: '管理员动态漫相关权限', get: [], delete: [], put: [], post: [], funtions: [] },
                { title: '普通用户相关视频相关权限', get: [], delete: [], put: [], post: [], funtions: [] },
                { title: '管理员相关视频相关权限', get: [], delete: [], put: [], post: [], funtions: [] }
            ],
            userTypes: [
                { value: 1, label: '普通cp' },
                { value: 4, label: '一审' },
                { value: 2, label: '二审' }
            ],
            userType: 1,
            teamTypes: [
                { value: 1, label: '独家' },
                { value: 2, label: '非独家' }
            ],
            teamType: 1,
            userLevelName: ['', '普通cp', '二审', '', '一审'],
            teamTypeName: ['', '独家', '非独家'],
            isEdit: true
        };
    },
    created() {
        this.setChecked();
        this.getAllPermission();
    },
    computed: {
        // 左右的权限列表，方便全选按钮
        allPermission() {
            let result = [];
            this.permissionList.forEach(per => {
                const { get, post, put, funtions } = per;
                result = result.concat(get, post, put, funtions, per.delete);
            });
            return result;
        },
        checkAll: {
            get() {
                return this.list.length === this.allPermission.length;
            },
            set: () => {}
        },
        isIndeterminate: {
            get() {
                return this.list.length > 0 && this.list.length < this.allPermission.length;
            },
            set: () => {}
        }
    },
    watch: {
        user() {
            this.isEdit = true;
            this.userName = '';
            this.setChecked();
        },
        allPermission() {
            this.setChecked();
        },
        userType(value) {
            if (value === 2) {
                this.teamType = 0;
            } else {
                this.teamType = 1;
            }
        }
    },
    methods: {
        setChecked() {
            let result = [];
            if (this.user.permissions) {
                this.user.permissions.forEach(item => {
                    this.allPermission.some(per => {
                        if (per.code === item.code) {
                            result.push(per);
                            return true;
                        }
                    });
                });
            }
            this.list = result;
        },
        // 全选
        checkAllChangeHandle(val) {
            this.list = val ? this.allPermission : [];
            this.isIndeterminate = false;
        },
        // 单选事件
        checkedChangeHandle(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.allPermission.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.allPermission.length;
        },
        /**
         * 获取所有的权限列表
         * relationType 权限类型     1个人相关/2普通用户漫画相关/3管理员漫画相关/4普通用户动态漫相关/5管理员动态漫相关/6普通用户相关视频相关/7管理员相关视频相关
         * method   行为类型    get查询/post新增/put修改/delete删除/funtions功能
         */
        getAllPermission() {
            return this.$api('getUserPermissionList', { pageIndex: 1, pageSize: 500 }).then(res => {
                const permissions = this.$utils.cloneDeep(this.permissionList);
                res.data.rows.forEach(row => {
                    const { relationType, method } = row;
                    // 工作效率、导出工作效率 不能编辑
                    if (relationType === 1 && ([
                        '/api/v2/admin/workefficiency/list',
                        '/api/v2/admin/workefficiency/exportlist'].includes(row.route)
                    )) {
                        row.isDisable = true;
                    }
                    permissions[relationType - 1][method || 'funtions'].push(row);
                });
                this.permissionList = permissions;
            });
        }
    }
};
</script>

<style lang="scss">
    .user-dialog{
        padding: 20px;
        .el-form-item__content{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span{
                font-size: 14px;
            }
        }
        .permission-group{
            &-title{
                font-size: 16px;
                padding-bottom: 8px;
                padding-top: 8px;
            }
            &-option{
                font-size: 14px;
                color: #888;
            }
            &-check{
                flex-grow: 1;
                display: flex;
                flex-flow: row wrap;
                .el-checkbox{
                    margin-left: 0;
                    margin-right: 30px;
                }
            }
        }
    }
</style>
