<template>
    <div class="audit-table">
        <div v-show="!isTable" class="audit-table-wrap" ref="table" v-loading="isloading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading" v-empty="noData">
            <template v-if="tableList.length">
                <auditAnComicCard
                    v-for="card in tableList"
                    ref="card"
                    @publish="showDialog"
                    :key="card.animation_id"
                    :comicData="card"
                    @preview="previewHandle"
                    @search="getTableList"
                >
                </auditAnComicCard>
            </template>
        </div>
        <paginationPlus :currentPage.sync="pagination.page_index" :page_size.sync="pagination.page_size" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
        <dialogPlus :visible.sync="dialogData.visible" :title.sync="dialogData.title" width="700px" :model="dialogData.data" :heightFull="true">
            <auditComicDialog :comicData="dialogData.data" />
            <div slot="footer" align="center">
                <el-button @click="dialogCloseHandle">关 闭</el-button>
                <el-button v-if="hasAuditBtn(dialogData.data, level)" :disabled="dialogData.data.onlineShowStatus === 0" type="primary" @click="gotoAuditHandle(dialogData.data)">去审核</el-button>
            </div>
        </dialogPlus>
         <an-comic-publish
            @refreshData="refreshData"
            v-if="currentPublishComicData"
            :comicData.sync="currentPublishComicData"
            :platformList="platformList"
        ></an-comic-publish>
    </div>
</template>
<script>
import { paginationMixin, anComicMixin, dialogMixin } from '@/mixins';
import anRoleMixin from '@/mixins/anRole';
import auditComicDialog from './auditComicDialog';
import anComicPublish from '@/components/comic/anComicPublish';
import { Grades } from '@/constants';
import { mapState } from 'vuex';

export default {
    mixins: [paginationMixin, anComicMixin, dialogMixin, anRoleMixin],
    props: {
        isTable: Boolean,
        index: String
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level, // 1：用户 2：管理 3：超管 4:一审人员
            platform_list: state => state.publicConfig.platform_list,
            class_configs: state => state.publicConfig.animation.class_configs
        })
    },
    data() {
        return {
            isloading: false,
            noData: true,
            tableList: [],
            pubClass: [],
            dialogData: {
                data: {
                    serialNames: [],
                    comicTypeNames: [],
                    audienceNames: [],
                    themeNames: []
                }
            },
            currentPublishComicData: null,
            platformList: [] // 平台列表
        };
    },
    created() {
        this.getComicPlatformList();
        this.getPublicClass().then(res => (this.pubClass = res));
    },
    methods: {
        // 获取动态漫平台列表
        getComicPlatformList() {
            this.platform_list.forEach(platform => {
                if (platform.id === 5) {
                    this.platformList.push({
                        platform_id: platform.id,
                        name: platform.name,
                        edit: false,
                        selectTypeId: null,
                        publish_time: undefined
                    });
                }
            });
        },
        // 刷新界面数据，用于改变发布信息后刷新
        refreshData(comicData) {
            this.getTableList().then(() => {
                const newComic = this.tableList.find(item => item.animation_id === comicData.animation_id);
                this.currentPublishComicData = newComic;
            });
        },
        // 展示发布dialog
        showDialog(comicData) {
            if (this.platformList === null || this.platformList.length === 0) {
                this.$message('平台列表尚未加载完成，请稍后点击');
                return;
            }
            this.currentPublishComicData = this.$utils.cloneDeep(comicData);
        },
        // 搜索接收时间
        searchEvent(searchData) {
            this.searchData = searchData;
            this.getTableList();
        },
        // 预览
        previewHandle({animation_id}) {
            this.$api('getAnComicInfo', { animation_id }).then(({data}) => {
                Object.assign(data, { publicClass: this.class_configs });
                this.changeDialog(true, '', 0, data);
            });
        },
        // 去审核
        gotoAuditHandle({ animation_id, animation_name, audit_status }) {
            this.$router.push({ name: 'auditAnComicInfo', query: { animation_id, animation_name, audit_status } });
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            const that = this;
            let pagination = {};
            for (let [key, value] of Object.entries(this.pagination)) {
                if (key === 'total') {
                    continue;
                }
                pagination[this.$utils.snakeCase(key)] = value;
            }
            return this.$api('getAuditAnComicList_Admin', { ...this.searchData, ...pagination }).then(res => {
                that.$refs.table && (that.$refs.table.scrollTop = 0);
                const { rows, page_index, page_size, total, status_data } = res.data;
                this.noData = (rows.length !== 0);
                this.tableList = rows;
                this.pagination = {
                    ...this.pagination,
                    page_index: page_index,
                    page_size: page_size,
                    total
                };
                // console.log(status_data);
                // console.log(this.index)
                this.$emit('changeTypeNumber', this.index, this.index === 'all' ? total : status_data[this.index]);
                this.isloading = false;
            });
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        },
        getGradeName(comicGrade) {
            const grade = Grades.find(item => item.value === comicGrade);
            return grade === undefined ? '未配置等级' : grade.label;
        }
    },
    components: {
        auditComicDialog,
        anComicPublish
    }
};
</script>

<style lang="scss">
    @import '../../../../styles/var';
    .audit-table{
        height: 100%;
        &-wrap{
            height: calc(100% - 48px);
            overflow-y: auto;
            position: relative;
        }
        &-list{
            height: calc(100% - 48px);
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
        }
    }
</style>
