/*
 * @Author: daipeng
 * @Date: 2018-09-03 11:29:10
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-19 14:10:23
 * @Description: 应用入口文件
 * @Company: 成都二次元动漫
 */
import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import storeCreator from '@/store';
import api from '@/api';
import SvgIcon from './components/SvgIcon.vue';
import global from './config/global';
import { utils, installFilters, installDirective, installPlugin } from '@/libs';
import App from '@/App';
import VueSessionStorage from 'vue-sessionstorage';

Vue.use(VueSessionStorage);
Vue.use(Vuex);
export const store = storeCreator(Vuex);

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$utils = utils;
Vue.prototype.$config = global;

Vue.component('SvgIcon', SvgIcon);

installPlugin(Vue);
installDirective(Vue);
installFilters(Vue);

// eslint-disable-next-line
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App />'
});
