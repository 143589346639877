<template>
    <div class="audit-record flex-column">
        <el-table ref="table" :data="tableList" border style="width: 100%">
            <el-table-column type="index" label="#" align="center" width="50"></el-table-column>
            <el-table-column prop="edit_field" label="编辑字段" align="center"></el-table-column>
            <el-table-column prop="edit_action" label="编辑行为" align="center" width="80"></el-table-column>
            <el-table-column prop="edit_detail" label="编辑细节" align="center" width="80"></el-table-column>
            <el-table-column prop="source" label="修改前" align="center" width="210">
                <template slot-scope="scope">
                   <auditComicRecordCol :edit_column="scope.row.edit_column" :colData="scope.row.origin_val" :platformList="platform_list"/>
                </template>
            </el-table-column>
            <el-table-column prop="target" label="修改结果" align="center" width="210">
                <template slot-scope="scope">
                   <auditComicRecordCol :edit_column="scope.row.edit_column" :colData="scope.row.target_val" :platformList="platform_list" />
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="提交审核时间" align="center" width="160">
                <template slot-scope="scope">
                     {{scope.row.create_time | timeFilter('yyyy年MM月dd hh:mm:ss')}}
                </template>
            </el-table-column>
            <el-table-column prop="audit_status" label="状态" align="center" width="90">
                <template slot-scope="scope">
                    {{scope.row.audit_status | auditAnStatusFilter}}
                </template>
            </el-table-column>
            <el-table-column prop="uname" label="审核人" align="center"></el-table-column>
            <el-table-column prop="audit_remark" label="原因">
                <template slot-scope="scope">
                    <el-tooltip :disabled="!scope.row.audit_remark" class="item" effect="light" :content="scope.row.audit_remark" placement="top-start">
                        <div class="width100 single-ellipsis">{{scope.row.audit_remark  || '--'}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-08-31 12:01:16
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-03 09:15:42
 * @Description: 审核漫画-审核记录
 * @Company: 成都二次元动漫
 */
import { paginationMixin } from '@/mixins';
import auditComicRecordCol from './auditComicRecordCol';
import { mapState } from 'vuex';

export default {
    mixins: [paginationMixin],
    data() {
        return {
            tableList: [],
            searchData: {}
        };
    },
    computed: {
        ...mapState('app', {
            platform_list: state => state.publicConfig.platform_list
        })
    },
    methods: {
        // 接收搜索事件
        searchEvent(searchData) {
            this.searchData = searchData;
            this.getTableList();
        },
        // 查询列表
        getTableList() {
            const { query_text, animation_id } = this.searchData;
            let pagination = {};
            for (let [key, value] of Object.entries(this.pagination)) {
                if (key === 'total') {
                    continue;
                }
                pagination[this.$utils.snakeCase(key)] = value;
            }
            return this.$api('getAnAuditLog_Admin', { animation_id, query_text, ...pagination }).then(res => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                const { rows = [], page_index, page_size, total } = res.data;
                this.tableList = rows;
                this.pagination.pageIndex = page_index;
                this.pagination.pageSize = page_size;
                this.pagination.total = total;
            });
        },
        // 重新组织列表数据
        formatTableList(list) {
            let result = {};
            list.forEach(item => {
                const hasTime = result.hasOwnProperty(item.create_time);
                if (!hasTime) result[item.create_time] = { ...item, list: [item] };
                else result[item.create_time].list.push(item);
            });
            return Object.values(result);
        },
        // 单行展开事件
        expandChangeHandle(currentExpandRow, allExpandRows) {
            if (this.batchExpandHandling) return false;
            const expandAllLen = allExpandRows.length;
            const tableListLen = allExpandRows.length;
            this.expandAll = expandAllLen === 0 ? false : (expandAllLen === tableListLen ? true : false);
        },
        // 批量展开操作
        batchExpandHandle() {
            this.batchExpandHandling = true;
            const tableComp = this.$refs.expandTable;
            const that = this;
            this.tableList.forEach(row => {
                tableComp.toggleRowExpansion(row, !that.expandAll);
            });
            this.expandAll = !this.expandAll;
            this.batchExpandHandling = false;
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    },
    components: {
        auditComicRecordCol
    }
};
</script>
<style lang="scss">
    .audit-record{
        height: 100%;
        >.el-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
        }
        .el-table__expanded-cell{
            padding: 0;
        }
        .el-table__row.expanded td{
            border-bottom: none;
        }
    }
</style>
