<!--
 * @Author: daipeng
 * @Date: 2019-09-06 11:50:03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-06 15:05:33
 * @Description:
 -->
<template>
    <el-dialog
        v-bind="$attrs"
        :title="title"
        :visible.sync="newVisible"
        :width="width"
        :custom-class="(heightFull ? 'dialog-plus height-full ' : 'dialog-plus ') + customClass" :top="heightFull ? '30px' : '20vh'"
        @close="close"
        append-to-body
        @closed="$emit('closed')"
        @open="open"
    >
        <div class="dialog-plus-content" ref="customContent">
            <slot></slot>
        </div>
        <template slot="footer">
            <slot name="footer"></slot>
        </template>
    </el-dialog>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-05 11:44:35
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-12 13:33:43
 * @Description: 封装dialog
 * @Company: 成都二次元动漫
 */
export default {
    props: {
        // 高度是否延伸
        heightFull: {
            type: Boolean,
            default: true
        },
        customClass: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '600px'
        }
    },
    data() {
        return {
            newVisible: false
        };
    },
    // 中间组件封装时需要重新处理双向绑定，可以以close来做，也可以用computed来做
    watch: {
        visible(value) {
            this.newVisible = value;
        },
        newVisible(value) {
            this.$emit('update:visible', value);
        }
    },
    methods: {
        close(obj) {
            this.$emit('update:visible', false);
            this.$emit('close');
        },
        open() {
            this.$nextTick(() => {
                this.$refs.customContent.scrollTop = 0;
            });
            this.$emit('open');
        }
    }
};
</script>
<style lang="scss">
.dialog-plus{
    border-radius: 4px;
    &.height-full{
        height: calc(100% - 80px);
        .el-dialog__body{
            height: calc(100% - 50px);
        }
    }
    display: flex;
    flex-flow: column nowrap;
    .el-dialog__header{
        box-sizing: border-box;
        height: 50px;
    }
    .el-dialog__body{
        box-sizing: border-box;
        padding: 0;
        // flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-flow: column nowrap;
        .dialog-plus-content{
            position: relative;
            flex-grow: 1;
            overflow-y: auto;
            border: none !important;
        }
    }
    .el-dialog__footer{
        height: 60px;
    }
}

</style>
