<template>
    <el-form :model="comicData" class="audit-dialog-content" label-width="90px">
        <el-form-item label="动态漫名称:">{{comicData.animation_name}}</el-form-item>
        <el-form-item label="状态:">{{comicData.audit_status | auditAnStatusFilter(level)}}</el-form-item>
        <el-form-item label="漫画封面:">
            <img :key="comicData.animation_cover_domain + $utils.filterImagePath(comicData.animation_cover_3_4)" v-lazy="comicData.animation_cover_domain + $utils.filterImagePath(comicData.animation_cover_3_4)" class="audit-cover audit-cover-1" />
        </el-form-item>
        <el-form-item label="漫画封面:">
            <img :key="comicData.animation_cover_domain + $utils.filterImagePath(comicData.animation_cover_2_1)" v-lazy="comicData.animation_cover_domain + $utils.filterImagePath(comicData.animation_cover_2_1)" class="audit-cover audit-cover-2" />
        </el-form-item>
        <el-form-item label="漫画封面:">
            <img :key="comicData.animation_cover_domain + $utils.filterImagePath(comicData.animation_cover_4_3)" v-lazy="comicData.animation_cover_domain + $utils.filterImagePath(comicData.animation_cover_4_3)" class="audit-cover audit-cover-3" />
        </el-form-item>
        <el-form-item label="作者名:">{{comicData.author_name}}</el-form-item>
        <el-form-item label="作品介绍:">
            <el-tooltip effect="light" :content="comicData.animation_brief" placement="top">
                <div class="width100 single-ellipsis">{{comicData.animation_brief}}</div>
            </el-tooltip>
        </el-form-item>
        <el-form-item label="动态漫价格:">{{comicData.chapter_price}}</el-form-item>
        <el-form-item label="连载状态:">
          <template v-for="item in serialStatusList" v-if="item.id === comicData.serial_status">{{item.name}}</template>
        </el-form-item>
        <el-form-item label="受众:">
          <template v-for="item in audienceStatusList" v-if="item.id === comicData.audience_status">{{item.name}}</template>
        </el-form-item>
        <el-form-item label="题材:">
          <template v-for="item in comicData.publicClass" v-if="comicData.theme_ids && comicData.theme_ids.includes(item.class_id)">{{item.class_name}} </template>
        </el-form-item>
        <el-form-item label="动态漫看点:">{{comicData.animation_feature}}</el-form-item>
        <el-form-item label="更新规律:">{{comicData.update_cycle}}</el-form-item>
        <el-form-item label="年份:">{{comicData.since_year}}</el-form-item>
        <el-form-item label="原作漫画:" v-if="comicData.origin_comic_id !== 0">
          <span>{{comicData.origin_comic_name}} 漫画ID: {{comicData.origin_comic_id}}</span>
        </el-form-item>
        <el-form-item label="发布平台:">
          <div v-for="item in comicData.auth_platforms" :key="item.platform_id" class="mr10">
            <span>{{item.platform_name}}</span>
            <span v-if="item.is_timing === 1">发布时间: {{item.publish_time | timeFilter }}</span>
          </div>
        </el-form-item>
    </el-form>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-29 17:52:38
 * @LastEditTime: 2018-09-29 17:52:38
 * @Description: 漫画详情-内容
 * @Company: 成都二次元动漫
 */

import { mapState } from 'vuex';
export default {
    props: {
        comicData: Object
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level, // 1：用户 2：管理 3：超管 4:一审人员
            class_configs: state => state.publicConfig.animation.class_configs
        })
    },
    data() {
        return {
            // 连载状态
            serialStatusList: [{
                name: '连载',
                id: 1
            },
            {
                name: '完结',
                id: 2
            }],
            audienceStatusList: [{
                name: '男生',
                id: 1
            },
            {
                name: '女生',
                id: 2
            },
            {
                name: '男生和女生',
                id: 3
            }]
        };
    },
    methods: {
        // code
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../styles/var';
    .audit-dialog-content{
        width: 450px;
        margin: 0 auto;
    }
    .audit-cover{
        border: 1px solid $border-color-base;
    }
    .audit-cover-1{
        width: 120px;
        height: 150px;
    }
    .audit-cover-2{
        width: 160px;
        height: 90px;
    }
    .audit-cover-3{
        width: 160px;
        height:120px;
    }
    .tag-wrap{
        padding: 10px 0;
        .tag-item{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            .tag-categroy{
                box-sizing: border-box;
                width: 90px;
                font-size: 14px;
                flex-basis: 90px;
                padding: 5px 12px 0 0;
                line-height: 30px;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .tag-tags{
                flex: 1 0;
                display: flex;
                flex-flow: row wrap;
                align-content: flex-start;
                &-item{
                    &-inner{
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    margin: 5px;
                    &.disabled{
                        background: #ccc;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>
