/*
 * @Author: daipeng
 * @Date: 2018-09-07 15:50:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-04 16:04:08
 * @Description: 过滤器，可以单独作为方法调用
 * @Company: 成都二次元动漫
 */

import { isNumber, isString, timeFormat, isArray, reCovertTime } from '@/libs/utils';

/**
 * 漫画产品线
 *
 * @param {*} value
 * @returns {string}
 */
export const productlineList = ['看漫画', '漫画台', '神漫画', '爱优漫', '爱飒漫画'];
export const productlineFilter = value => {
    value = Number(value);
    if (!isNumber(value) || !value) return '--';
    switch (value) {
    case 1: return '看漫画';
    case 2: return '漫画台';
    case 3: return '神漫画';
    case 4: return '爱优漫';
    case 5: return '斗罗大陆';
    case 6: return '爱飒漫画';
    case 1014: return '看漫画-小说';
    case 1015: return '漫画台-小说';
    default: return '--';
    }
};

/**
 * 我的漫画审核状态 style
 *
 * @param {*} type
 * @returns {string}
 */
export const auditStatusStyleFilter = type => {
    switch (type) {
    case 0: return 'comic-dispatch-auditing';
    case 1: return 'comic-dispatch-pending';
    case -1: return 'comic-dispatch-audit-failed';
    case 2: return 'comic-dispatch-auditing';
    case 3: return 'comic-dispatch-audit-failed';
    case 4: return 'comic-dispatch-audited';
    default: return 'comic-dispatch-auditing';
    }
};

/**
 * 我的漫画审核状态
 * @param {*} type
 * @param {number} level 1：普通CP 2：二审人员 3：超管 4:一审人员
 */
export const auditStatusFilter = (type, level) => {
    switch (type) {
    case 0: return level === 4 ? '待审核' : '一审审核中';
    case 1: return '待提交';
    case -1: return '一审未通过';
    case 2: return '二审审核中';
    case 3: return '二审未通过';
    case 4: return '二审已通过';
    default: return '待提交';
    }
};

/**
 * 我的动态漫审核状态 style
 *
 * @param {*} type
 * @returns {string}
 */
export const auditAnStatusStyleFilter = type => {
    switch (type) {
    case 2: return 'comic-dispatch-auditing';
    case 1: return 'comic-dispatch-pending';
    case 3: return 'comic-dispatch-audit-failed';
    case 4: return 'comic-dispatch-auditing';
    case 5: return 'comic-dispatch-audit-failed';
    case 6: return 'comic-dispatch-audited';
    default: return 'comic-dispatch-auditing';
    }
};

/**
* 我的动态漫审核状态
* @param {*} type
* @param {number} level 1：普通CP 2：二审人员 3：超管 4:一审人员
*/
export const auditAnStatusFilter = (type, level) => {
    switch (type) {
    case 2: return level === 4 ? '待审核' : '一审审核中';
    case 1: return '待提交';
    case 3: return '一审未通过';
    case 4: return '二审审核中';
    case 5: return '二审未通过';
    case 6: return '二审已通过';
    default: return '待提交';
    }
};

/**
 * 订单状态
 *
 * @param {*} value
 * @returns {string}
 */
export const orderStatusList = ['未支付', '支付完成', '已取消'];
export const orderStatusFilter = value => {
    value = Number(value);
    if (!isNumber(value)) return '--';
    return orderStatusList[value];
};

/**
 * 支付状态
 *
 * @param {*} value
 * @returns {string}
 */
export const payMethodFilter = value => {
    value = Number(value);
    if (!isNumber(value) || !value) return '--';
    switch (value) {
    case 1: return 'web 支付宝即时到账';
    case 2: return 'ios 支付宝app支付';
    case 3: return 'android 支付宝 app支付';
    case 4: return 'ios apple pay支付';
    case 5: return 'ios 银联支付';
    case 6: return 'android 银联支付';
    case 7: return 'web 银联支付';
    case 8: return 'ios 微信app支付';
    case 9: return 'android 微信app支付';
    case 10: return 'web 微信扫码支付';
    case 11: return 'android 支付宝wap支付';
    case 12: return 'ios 支付宝wap支付';
    case 13: return 'android 微信h5支付';
    case 14: return 'ios 微信h5支付';
    case 15: return '变蛙娱乐充值(未使用)';
    case 16: return 'QQ APP支付';
    case 17: return '华为 app支付';
    case 18: return 'paypal支付';
    case 19: return '微信 小程序支付';
    default: return '--';
    }
};

/**
 * 漫画付费状态
 *
 * @param {String} charge_status   漫画付费状态， 如：charge_status='10000000000000000' 16为
 *          第1位为0,是免费漫画，为1，是付费漫画
 *          为1情况下，付费类型如下：
            禁止赠币免费：第2位为1
            禁止分享免费：第3位为1
            禁止广告免费：第4位为1
            禁止等时免费：第5位为1
            禁止限时免费：第6位为1
            禁止限额免费：第7位为1
            禁止会员免费：第8位为1
            禁止推广免费：第9位为1
            禁止游戏免费：第10位为1
            禁止领券免费：第11位为1
            禁止抽奖免费：第12位为1
            禁止限时收费：第13位为1
            禁止限额收费：第14位为1
            禁止他人代付：第15位为1
            禁止信用付费：第16位为1
 * @returns {Array}
 */
export const comicPayTypeList = ['', '赠币免费', '分享免费', '广告免费', '等时免费', '限时免费', '限额免费', '会员免费', '推广免费', '游戏免费', '领券免费', '抽奖免费', '限时收费', '限额收费', '他人代付', '信用付费'];
export const comicPayTypeFilter = status => {
    status = status.substr(0, 16);
    if (!isString(status)) return [];
    else if (status[0] === '0') return ['免费'];
    else {
        let result = [];
        status.split('').forEach((item, index) => {
            if (item === '0') result.push(comicPayTypeList[index]);
        });
        return result;
    }
};

/**
 * 章节付费状态
 *
 * @param {*} value
 * @returns {string}
 */
export const chapterPayTypeList = ['不限制', '免费', '收费'];
export const chapterPayTypeFilter = value => {
    value = Number(value);
    if (!isNumber(value)) return '--';
    return orderStatusList[value];
};

/**
 * 章节名称格式化
 *
 * @param {*} value
 * @returns {string}
 */
export const chapterNameFilter = value => {
    if (!value) return null;
    return value.split('$$$')[1];
};

/**
 * 章节图片地址数组、页数
 *
 * @param {*} value
 * @returns {string}
 */
export const chapterStrFilter = (str, type) => {
    if (isArray(str)) return type === 'length' ? str.length : str;
    else if (isString(str)) return type === 'length' ? str.split('$$$').length : str.split('$$$');
    else return type === 'length' ? 0 : [];
};

/**
 * 获取图片名称
 *
 * @param {String}} img
 * @returns {String} imgName
 */
export const imgNameFilter = img => {
    return img.substring(img.lastIndexOf('/') + 1);
};

/**
 * 解锁方式
 *
 * @param {*} value
 * @returns {string}
 */
export const chapterUnlockList = ['金币/钻石购买', 'vip购买', '分享免费读', '一小时免费', '看广告免费'];
export const unlockFilter = value => {
    value = Number(value);
    if (!isNumber(value)) return '--';
    return chapterUnlockList[value];
};

/**
 * 时间格式化
 *
 * @param {*} time
 * @param {string} [fmt='yyyy.MM.dd hh:mm:ss']
 * @returns
 */
export const timeFilter = timeFormat;

/**
 * 章节看点位置
 *
 * @param {Number} value
 * @returns
 */
export const chapterIntroPosition = ['靠左', '居中', '靠右'];
export const chapterIntroPositionFilter = value => {
    return chapterIntroPosition[value - 1];
};

/**
 * 日志操作行为
 *
 * @param {Number} value
 * @returns String
 */
export const logActionList = ['新增', '编辑', '删除'];
export const logActionFilter = value => {
    return logActionList[value - 1];
};

/**
 * 日志操作类型
 *
 * @param {Number} value
 * @returns String
 */
export const logTypeList = ['普通操作', '漫画操作'];
export const logTypeFilter = value => {
    return logActionList[value - 1];
};

/**
 * 用户账号启用状态
 *
 * @param {Number} value
 * @returns String
 */
export const userAccountStatusList = ['所有', '禁用', '启用'];
export const userAccountStatusFilter = value => {
    return logActionList[value - 1];
};

/**
 * 用户权限过滤器
 *
 * @param {Array}} list
 * @param {String}} format  分隔符
 * @returns {Array} [name]
 */
export const userPermissionFilter = (list = [], format) => {
    let nameList = list.map(permission => permission.name);
    if (!format) return nameList;
    else return nameList.join(format);
};

/**
 * 鉴黄状态格式化
 *
 * @param {*} value
 * @returns {string}
 */
export const greenDescFilter = value => {
    if (!value) return '';
    const tmp = {
        'pass': '无黄图',
        'ing': '鉴黄中',
        'refuse': '有黄图'
    };
    return tmp[value];
};

export const videoGreenDescFilter = value => {
    if (!value) return '';
    const tmp = {
        'pass': '无黄视频',
        'ing': '鉴黄中',
        'refuse': '有黄视频'
    };
    return tmp[value];
};

export const reCovertTimeFilter = value => {
    return reCovertTime(value);
};

// 视频大小
export const convertFileSizeFilter = value => {
    if (!value) return '';
    return `${(parseInt(value) / 1024 / 1024).toFixed(2)}M`;
};

/**
 * 章节类型
 *
 * @param {*} value
 * @returns {string}
 */
export const chapterTypeFilter = (val) => {
    const mapData = {
        1: '连载',
        2: '番外'
    };
    return mapData[val] || '-';
};
