<template>
    <div class="edit-comic page-container flex-column">
        <div class="edit-comic-head">
            <h2 class="edit-comic-title">{{comicData.animation_name ||''}}</h2>
            <el-row class="edit-comic-info">
                <el-col :span="8"><span class="edit-comic-label">作品ID：</span>{{comicData.animation_id}}</el-col>
                <el-col :span="8"><span class="edit-comic-label">状态：</span>{{comicData.audit_status | auditAnStatusFilter}}</el-col>
                <el-col :span="8"><span class="edit-comic-label">最新章节：</span>{{comicData.last_chapter_title | chapterNameFilter}}</el-col>
            </el-row>
        </div>
        <el-tabs v-model="activeName" class="edit-comic-tab" @tab-click="tabClickHandle">
            <el-tab-pane class="data" label="漫画信息" name="1" v-scrollbar.y>
                <keep-alive>
                    <comicInfo v-if="comicData.animation_name" :publicClassList="publicClass" :comicInfo.sync="$utils.cloneDeep(comicData)"/>
                </keep-alive>
            </el-tab-pane>
            <el-tab-pane v-if="!$route.query.single" label="章节设置" name="2">
                <keep-alive>
                    <chapterList />
                </keep-alive>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-23 09:51:05
 * @Description: 漫画管理-我的漫画-编辑漫画
 * @Company: 成都二次元动漫
 */
import { anComicMixin } from '@/mixins';
import { getRouteByName } from '@/router/helper';
import comicInfo from '@/views/main/anComicManager/comicInfo';
import chapterList from '@/views/main/anComicManager/comicChapter/chapterList';

export default {
    mixins: [anComicMixin],
    data() {
        return {
            activeName: '2',
            comicData: {}
        };
    },
    beforeCreate() {
        const { commit, state } = this.$store;
        const { tabtype } = this.$route.query;
        const currentBreadcrumb = { title: tabtype === 1 ? '漫画信息' : '章节列表' };
        // 根据权限控制面包屑
        if ([2, 3].includes(this.$store.state.app.userInfo.level)) state.app.breadcrumb.splice(1, 2, currentBreadcrumb);
        else {
            const myComicListRoute = getRouteByName('myAnComic')[0];
            state.app.breadcrumb.splice(-1, 1, myComicListRoute, currentBreadcrumb);
        }
        commit('app/setBreadcrumb', [...state.app.breadcrumb]);
    },
    created() {
        if(this.$route.query.animation_id) {
            this.activeName = this.$route.query.tabtype + '';
            this.getAllData([this.getPublicClass(), this.getAnComicData(this.$route.query.animation_id)]);
        }
    },
    methods: {
        tabClickHandle({ name }) {
            const { commit, state } = this.$store;
            state.app.breadcrumb.splice(-1, 1, { title: name === '1' ? '漫画信息' : '章节列表' });
            commit('app/setBreadcrumb', [...state.app.breadcrumb]);
        }
    },
    components: {
        comicInfo,
        chapterList
    }
};
</script>

<style lang="scss">
    .edit-comic{
        &-head{
            height: 80px;
        }
        &-title{
            font-size: 20px;
            font-weight: normal;
        }
        &-info{
            padding: 20px 0;
        }
        &-label{
            margin-right: 10px;
            color: #909399;
        }
        &-tab{
            flex-grow: 1;
            overflow: hidden;
            height: 100%;
            .el-tabs__content{
                height: calc(100% - 55px);
                .el-tab-pane{
                    height: 100%;
                    position: relative;
                    overflow-y: auto;
                }
            }
        }
    }
</style>
