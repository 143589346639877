<!--
 * @Author: daipeng
 * @Date: 2019-09-06 11:50:03
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-18 12:10:18
 * @Description: 审核漫画卡片
 -->
<template>
    <div class="audit-comic-card flex-row" :class="{'has-deffer' : comicData.deferStatus, 'unshelve': comicData.onlineShowStatus === 0}">
        <div class="audit-comic-card-img" @click="$emit('preview', comicData)">
            <img :key="$config.cdn + comicData.comicCover34" v-lazy="$config.cdn + $utils.filterImagePath(comicData.comicCover34)" alt="">
        </div>
        <dl class="audit-comic-card-info" style="overflow: hidden;">
            <dt class="flex-row-between">
                <div class="audit-comic-card-title flex-row">
                    <span class="comic-name">{{comicData.comicName}}</span>
                    <!-- <span class="comic-btn-edit comic-dispatch-primary cursor-pointer" @click="editComicHandle(comicData)"><i class="el-icon-edit"></i>编辑漫画</span> -->
                </div>
                <div v-if="comicData.onlineShowStatus === 0">已下架</div>
                <div v-else :class="comicData.auditStatus | auditStatusStyleFilter">
                    {{comicData.auditStatus | auditStatusFilter(level)}}
                    <el-tooltip placement="top" v-if="[3, 4, -1].includes(comicData.auditStatus)">
                        <div slot="content">{{comicData.auditRemark || ([3, -1].includes(comicData.auditStatus) ?'审核未通过': '审核通过')}}</div>
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </div>
            </dt>
            <dd class="comic-dispatch-time">
                <div>
                    <span class="comic-dispatch-label">最新发布时间：</span>
                    <span>{{comicData.lastPublishTime | timeFilter('yyyy年MM月dd日 hh:mm')}}</span>
                </div>
                <!-- <el-button size="mini" plain @click="setLabelHandler">标签设置</el-button> -->
            </dd>
            <dd>
                <span class="comic-dispatch-label">最新章节：</span>
                <span v-if="comicData.lastChapter" class="comic-dispatch-content">
                    {{comicData.lastChapter | chapterNameFilter}}
                </span>
                <span v-else class="comic-dispatch-content">
                    还没更新~
                </span>
                <!-- <el-button type="text" size="mini" plains @click="addChapter(comicData)">新增章节</el-button> -->
            </dd>
            <dd v-if="comicData.deferDesc">
                <div class="comic-dispatch-deffer">
                    <span class="comic-dispatch-label">延迟备注：</span>
                    <el-tooltip placement="top">
                        <div slot="content">{{comicData.deferDesc}}</div>
                        <span>{{comicData.deferDesc}}</span>
                    </el-tooltip>
                </div>
                <!-- <el-button size="mini" plain @click="setLabelHandler">标签设置</el-button> -->
            </dd>
            <dd class="audit-comic-card-btns">
                <template v-if="couldOperate(comicData, level)">
                    <el-button size="mini" @click="$emit('preview', comicData)">预览</el-button>
                    <el-button size="mini" :disabled="comicData.onlineShowStatus === 0" v-if="checkAuth('AdminAuditComic.AuthComicPlatform')" plain @click="$emit('publish', comicData)">发布管理</el-button>
                    <el-button size="mini" :disabled="couldAudit(comicData, level)" v-if="hasAuditBtn(comicData, level)" type="primary" @click="gotoAuditHandle(comicData)">审核</el-button>
                    <template v-if="[2].includes(level)">
                        <el-button :disabled="comicData.onlineShowStatus === 0" size="mini" type="primary" @click="jumpPublishBox(comicData)">编辑</el-button>
                        <el-dropdown split-button size="small" trigger="click" type="primary" @command="handleCommand">
                            <span class="el-dropdown-link">
                            {{ getGradeName(comicData.comicGrade) }}
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    :disabled="level !== 2 || comicData.onlineShowStatus === 0"
                                    :command="item.value"
                                    v-for="item in grades"
                                    :key="item.value">{{ item.label }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </template>
                <template v-if="[2].includes(level) && checkAuth('AdminAuditComic.displayComic')">
                    <el-button v-if="comicData.onlineShowStatus === 0" type="primary" @click="handleDisplayComic(1)" size="mini">上架</el-button>
                    <el-button type="primary" v-else @click="handleDisplayComic(0)" size="mini">下架</el-button>
                </template>
            </dd>
        </dl>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-14 09:55:44
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-14 09:56:05
 * @Description: 漫画审核卡片
 * @Company: 成都二次元动漫
 */

import { Grades } from '@/constants';
import { mapState } from 'vuex';
import { dialogPlus } from '@/components';
import roleMixin from '@/mixins/role';

export default {
    mixins: [roleMixin],
    components: {
        dialogPlus
    },
    props: {
        comicData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            grades: Grades
        };
    },
    methods: {
        // 二审上下架漫画
        handleDisplayComic(showStatus) {
            const msg = showStatus === 0 ? '下架' : '上架';
            this.$utils.confirm('', `确定${msg}该漫画吗？`, `${msg}中...`, (action, instance, done) => {
                return this.$api('displayComicForAuditor', { showStatus, comicId: this.comicData.comicId }).then(res => {
                    this.comicData.onlineShowStatus = showStatus;
                });
            });
        },
        couldAudit(comicData, level) {
            // 审核中/拒绝就放开点击
            const maps = {
                // 二审人员
                '2': [2, 3],
                // 一审人员
                '4': [0, -1]
            };
            if (maps[level].includes(comicData.auditStatus)) {
                return false;
            }
            return comicData.onlineShowStatus === 0;
        },
        couldOperate(comicData, level) {
            // case 0: return level === 4 ? '待审核' : '一审审核中';
            // case 1: return '待提交';
            // case -1: return '一审未通过';
            // case 2: return '二审审核中';
            // case 3: return '二审未通过';
            // case 4: return '二审已通过';
            const maps = {
                // 二审人员
                '2': [2, 3, 4],
                // 一审人员
                '4': [0, -1]
            };
            return maps[level].includes(comicData.auditStatus);
        },
        // 去审核
        gotoAuditHandle({ comicId, comicName, auditStatus }) {
            this.$router.push({ name: 'auditComicInfo', query: { comicId, comicName, auditStatus } });
        },
        // 重新编辑审核
        jumpPublishBox({comicId, comicName, authorName, uploaderUid, comicType}) {
            this.$router.push({ name: 'editComic', query: { comicId, comicName, authorName, uploaderUid, editType: 'edit', tabtype: 1, comicType } });
        },
        getGradeName(comicGrade) {
            const grade = this.grades.find(item => item.value === comicGrade);
            return grade === undefined ? '未配置' : grade.short;
        },
        handleCommand(command) {
            if (this.comicData.comicGrade === command) {
                return;
            }
            const name = this.getGradeName(command);
            this.$utils.confirm('', `确定修改等级为 ${name}？`, `修改中...`, (action, instance, done) => {
                return this.$api('updateComicGrade', { comicId: this.comicData.comicId, comicGrade: command }).then(res => {
                    this.$message.success('修改成功！');
                    this.$emit('search');
                });
            });
        },
        // 标签设置
        setLabelHandler() {
            const { comicId, comicName } = this.comicData;
            this.$router.push({ name: 'labelSetting', query: { comicId, comicName } });
        }
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        })
    }
};
</script>
<style lang="scss">
    .audit-comic-card {
        float: left;
        position: relative;
        width: calc(50% - 10px);
        min-width: 560px;;
        height: 190px;
        background-color: #fff;
        border: 1px solid #e4e7ea;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 2px;
        margin-bottom: 20px;
        &.unshelve {
           filter: grayscale(1);
        }

         &.has-deffer{
            background: #f5ecec;
        }

        .el-dropdown .el-dropdown__caret-button .el-dropdown__icon{
            vertical-align: top;
        }
        &.isMouseOver{
            box-shadow: 0px 0px 20px rgba(102, 102, 102, 0.35);
        }
        &:nth-child(odd){
            margin-right: 20px;
        }
        &-img{
            position: relative;
            width: 140px;
            height: 100%;
            background-color: #e4e7ea;
            border-right: 1px solid #e4e7ea;
            cursor: pointer;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
        &-info{
            padding: 10px;
            flex-grow: 1;
            font-size: 14px;
            dt, dd {
                height: 30px;
                line-height: 30px;
                color: #606266;
            }
            .comic-name{
                max-width: 140px;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &-title{
            align-items: center;
        }
        &-btns{
            font-size: 0;
            margin: 10px 0 0 0;
            .el-button{
                padding: 7px 10px;
            }
            .el-dropdown{
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .comic-btn-edit{
            font-size: 12px;
            margin-left: 10px;
        }
        .comic-dispatch-time{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
         .comic-dispatch-deffer{
            display: inline-block;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            width: 90%;
        }
    }
</style>
