<template>
    <div class="audit-record-col">
        <!-- 图片展示 -->
        <div v-if="imgData.length" class="audit-record-colimg" v-scrollbar>
            <template v-for="(img, index) in imgData">
                <el-popover
                    :key="img + index"
                    ref="popover"
                    placement="top"
                    title=""
                    trigger="hover">
                    <div class="audit-enlarged" v-lazy:background-image="$config.cdn + $utils.filterImagePath(img)" :key="$config.cdn + $utils.filterImagePath(img)"></div>
                    <div slot="reference" class="thumbnail">
                        <img :key="$utils.filterImagePath(img)" v-lazy="$config.cdn + $utils.filterImagePath(img)" alt="">
                    </div>
                </el-popover>
            </template>
        </div>
        <!-- 发布平台展示 -->
        <div v-else-if="publishData.length" v-for="platform in publishData" :key="platform.platformId" class="platform-col flex-row-between">
            <div class="platform-col-name">{{formatPlatform(platform.platformId)}}</div>
            <div v-if="platform.publishTime" class="platform-col-time">{{platform.publishTime | timeFilter('yyyy-MM-dd hh:mm:ss')}}</div>
            <div v-else class="platform-col-time">立即发布</div>
        </div>
        <!-- 漫画类型 -->
        <template v-else-if="types">{{types}}</template>
        <!-- 视频 -->
        <template v-else-if="editKey === 'videoID'">
            <el-button :disabled="this.colData === ''" size="mini" type="primary" @click="previewVideoHandle()">预览V漫</el-button>
        </template>
        <!-- 空 -->
        <template v-else-if="!colData">--</template>
        <!-- 特殊处理 -->
        <template v-else>{{colData == '[]' ? '--' : colData}}</template>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-29 10:46:49
 * @LastEditTime: 2018-09-29 10:46:49
 * @Description: 审核记录-修改前后-分类展示组件
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        colData: {
            type: String,
            default: ''
        },
        publicClass: { type: Array, default: () => [] },
        editKey: String,
        platformList: Array
    },
    data() {
        return {};
    },
    computed: {
        // 图片
        imgData() {
            if (/\.(png|jpe?g|gif|svg)+/ig.test(this.colData)) {
                return /\[/g.test(this.colData) ? JSON.parse(this.colData) : [this.colData];
            } else return [];
        },
        // 发布平台
        publishData() {
            if (/(platformId)+/g.test(this.colData)) return JSON.parse(this.colData);
            else return [];
        },
        // 连载类型、漫画类型、受众类型、题材类型
        types() {
            if (['serial', 'comicType', 'audience'].includes(this.editKey) && this.publicClass.length) {
                return this.$utils.formatPublicClass(this.publicClass, [this.colData])[`${this.editKey}Names`].join('、');
            }
            if (['theme'].includes(this.editKey) && this.publicClass.length) {
                return this.$utils.formatPublicClass(this.publicClass, JSON.parse(this.colData))[`${this.editKey}Names`].join('、');
            }
            return '';
        }
    },
    methods: {
        // 格式化平台名称
        formatPlatform(platformId) {
            const platform = this.platformList.find(platform => (platform.id === platformId));
            if (platform) {
                return platform.name;
            }
            return '';
        },
        previewVideoHandle() {
            console.log(this.editKey,this.colData)
            this.$api('getComicVideoPlayAuth', { video_id: this.colData }).then(res => {
                if(res.data.PlayInfoList.PlayInfo) {
                    window.open(res.data.PlayInfoList.PlayInfo[0].PlayURL, '_blank')
                }
            })
        }
    }
};
</script>

<style lang="scss">
    .audit-record-col{
        width: 100%;
        &Slide{
            width: 100%;
            overflow: hidden;
            position: relative;
        }
        &img{
            height: 40px;
            position: relative;
            overflow: hidden;
            text-align: left;
            .thumbnail{
                padding-right: 10px;
                box-sizing: border-box;
                display: inline-block;
                height: 20px;
                cursor: pointer;
                img{
                    height: 100%;
                }
            }
        }
    }
    .audit-enlarged{
        width: 200px;
        height: 200px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>
