<template>
  <div class="batch-upload mb10">
    <div
      v-if="type != 'view'"
      class="batch-upload-header flex-row-between"
    >
      <template v-if="comicType === 11">
        <div class="flex-row">
            <div class="mr30">
                <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="checkAllHandle"
                >全选</el-checkbox>&nbsp;
                <el-button
                type="text"
                @click="removeHandle"
                >删除</el-button>
                <el-button
                v-if="imageAddr.length"
                type="text"
                size="mini"
                @click="previewHandle"
                >预览</el-button>
            </div>
            <div class="mr30">
            黄色图片 <strong class="text-danger">{{greenStatData.yellow}}</strong> 张，鉴黄中 <strong class="text-warning">{{greenStatData.pending}}</strong> 张，通过鉴黄 <strong class="text-success">{{greenStatData.green}}</strong> 张
            </div>
            <div class="mr30" v-if="uploadData.uploadTotal !== 0 && uploadStatus === 1">
            本次上传：{{uploadData.uploadTotal}},
            <span class="color-green" v-if="showSuccCount">成功{{uploadData.uploadSuccessNum}}</span>
            <span class="color-red">失败{{uploadData.uploadErrorNum}}</span>
            </div>
            <div class="mr10">共切图 {{imageAddr.length}}张 </div>
        </div>
      </template>
      <el-upload
        class="upload-btn"
        ref="batchUpload1"
        :multiple="true"
        action=""
        drag
        :accept="$config.acceptImg"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="(file, fileList) => onChange(file, fileList, 'batchUpload1')"
      >
        <el-button
          type="primary"
          size="mini"
        >上传图片</el-button>
      </el-upload>
      <template v-if="comicType===13">
          <div class="flex-row">
                <div class="mr30">
                    黄色图片 <strong class="text-danger">{{greenStatData.yellow}}</strong> 张，鉴黄中 <strong class="text-warning">{{greenStatData.pending}}</strong> 张，通过鉴黄 <strong class="text-success">{{greenStatData.green}}</strong> 张
                </div>
                <div class="mr30" v-if="uploadData.uploadTotal !== 0">
                    本次上传：{{uploadData.uploadTotal}},
                    <span class="color-green" v-if="showSuccCount">成功{{uploadData.uploadSuccessNum}}</span>
                    <span class="color-red">失败{{uploadData.uploadErrorNum}}</span>
                </div>
                <div class="mr10">已上传 {{imageAddr.length}} </div>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="checkAllHandle">全选</el-checkbox>
                <el-button type="text" @click="removeHandle">删除</el-button>
                <el-button v-if="imageAddr.length" type="text" size="mini" @click="previewHandle">预览</el-button>
            </div>
      </template>
    </div>
    <el-checkbox-group
      class="batch-upload-imgbox flex-row"
      v-model="checkList"
      @change="checkSingleHandle"
    >
      <div
        class="batch-upload-list flex-row"
        ref="imageList"
      >
        <div
          class="batch-upload-item"
          v-for="(img, index) in imageAddr"
          :key="img.imgUrl + index"
        >
          <div class="batch-upload-img upload-bg">
            <customerImage
              :greenprocess="img.greenStatus"
              :key="img.imgUrl"
              :data-src="$config.cdn + $utils.filterImagePath(img.imgUrl)"
              :src="$config.cdn + $utils.filterImagePath(img.imgUrl)"
            />
          </div>
          <el-tooltip
            effect="dark"
            :content="img.imgUrl | imgNameFilter"
            placement="top-start"
          >
            <el-checkbox
              class="single-ellipsis"
              v-if="type != 'view'"
              :label="img"
            >{{img.imgUrl | imgNameFilter}}</el-checkbox>
          </el-tooltip>
        </div>
        <el-upload
          v-if="type != 'view'"
          ref="batchUpload2"
          drag
          action=""
          :multiple="true"
          :accept="$config.acceptImg"
          :class="uploadBtnStyle"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="(file, fileList) => onChange(file, fileList, 'batchUpload2')"
        >
        <template v-if="comicType===11">
          <p class="batch-upload-text" v-if="imageAddr.length > 0">
            条漫自动切图
            <i class="el-icon-plus"></i>
          </p>
          <div class="batch-upload-bigtext" v-else>
            <h3 class="h3">条漫自动切图</h3>
            <i class="el-icon-plus"></i>
            <p>系统将自动对上传的图片进行切图处理</p>
            <p>点击或拖动文件至此区域进行上传</p>
          </div>
        </template>
        <template v-else>
            <p class="batch-upload-text">
            页漫上传
            <i class="el-icon-plus"></i>
          </p>
        </template>
        </el-upload>
      </div>
    </el-checkbox-group>
    <el-dialog
      width="400px"
      :show-close="false"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="uploadShow"
    >
      <h3
        slot="title"
        style="font-size:16px"
      >图片处理中，请稍后…</h3>
      <p style="text-align:center;color:#999;margin-bottom:10px;margin-top:-20px">请勿关闭本页面或浏览器</p>
      <el-progress :percentage="uploadStatus.percentage"></el-progress>
      <p>{{uploadStatus.text}}</p>
    </el-dialog>
  </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-18 09:13:33
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-22 10:02:59
 * @Description: 批量上传章节图片
 * @Company: 成都二次元动漫
 */
import { uploadMixin } from "@/mixins";
import customerImage from "@/components/customerImage";
import Sortable from "sortablejs";
import { mapState } from "vuex";
export default {
  mixins: [uploadMixin],
  props: {
    type: String, // 编辑类型: 0 view, 1 edit, 2 add
    imageAddr: {
      type: Array,
      default: () => [],
    },
    showSuccCount: {
      type: Boolean,
      default: true, // 是否显示上传成功条数显示
    },
    comicType: {
      type: Number,
      default: 11, // 11代表页面，13代表条漫
    }
  },
  components: {
    customerImage,
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      checkList: [],
      uploadData: {
        uploadSuccessNum: 0,
        uploadErrorNum: 0,
        uploadTotal: 0,
        errorMessage: [],
        isEnd: true,
        isDrop: false,
        firstImgRaw: null,
        filePaths: [],
      },
      uploadShow: false,
      uploading: 0,
      cutProgress: 0,
      batchUploadFiles: []
    };
  },
  computed: {
    uploadStatus() {
      switch (this.uploading) {
        case 1:
          return { percentage: 10, text: "图片上传完成" };
        case 2:
          return { percentage: 15, text: "发起图片裁剪" };
        case 3:
          return { percentage: 20, text: "发起裁剪完成" };
        case 4: // 对应采集图片stauts 1
          switch (this.cutProgress) {
            case 1:
              return { percentage: 40, text: "开始处理裁剪图片" };
            case 2:
              return { percentage: 50, text: "下载裁剪图片完成" };
            case 3:
              return { percentage: 60, text: "切图完成" };
            default:
              return { percentage: 40, text: "裁剪图片还未开始" };
          }
        case 5: // 对应采集图片stauts 2
          return { percentage: 100, text: "裁剪处理失败" };
        case 6: // 对应采集图片stauts 3
          return { percentage: 100, text: "切图上传oss完成" };
        case 7:
          return { percentage: 100, text: "图片鉴黄中…" };
        default:
          return { percentage: 0, text: "开始上传图片" };
      }
    },
    greenStatData() {
      let tmp = {};
      tmp.yellow = this.imageAddr.filter((item) => {
        return item.greenStatus === 3;
      }).length;
      tmp.green = this.imageAddr.filter((item) => {
        return item.greenStatus === 4;
      }).length;
      tmp.pending = this.imageAddr.filter((item) => {
        return item.greenStatus === 1;
      }).length;
      return tmp;
    },
    uploadBtnStyle() {
      if (this.imageAddr.length == 0 && this.comicType === 11) {
        return "batch-upload-big";
      }
      return "batch-upload-add";
    },
    ...mapState("app", {
      userId: (state) => state.userInfo.id,
    }),
  },
  mounted() {
    this.dragTable = Sortable.create(this.$refs.imageList, {
      onUpdate: this.dragUpdateHandle,
    });

    // element-ui bug，重写内部拖拽组件拖拽方法
    this.dropInstance = this.$refs.batchUpload2.$children[0].$children[0];
    this.dropInstance.onDrop = this.onDrop.bind(this.dropInstance);
  },
  watch: {
    imageAddr: {
      deep: true,
      handler() {
        // 获取第一张图片的高宽数据，用于上传其他图片时验证
        if (
          this.imageAddr &&
          this.imageAddr.length > 0 &&
          !this.uploadData.firstImgRaw
        ) {
          this.getFirstImgRaw(this.imageAddr[0]);
        }
      },
    },
  },
  methods: {
    getFirstImgRaw(image) {
      let img = new Image();
      img.onload = () => {
        this.setFirstImgRaw(img);
        img = null;
      };
      img.src = this.$config.cdn + this.$utils.filterImagePath(image.imgUrl);
    },
    // 设置第一张图片尺寸信息
    setFirstImgRaw(image) {
      if (!this.uploadData.firstImgRaw) {
        this.uploadData.firstImgRaw = {
          uid: image.uid,
          w: image.width,
          h: image.height,
        };
      }
    },
    clearFirstImgRaw(file) {
      if (!file) {
        this.uploadData.firstImgRaw = null;
        return;
      }
      // file存在的情况->清除同一批中第一张错误时，情况记录数据
      if (file && file.uid === this.uploadData.firstImgRaw.uid) {
        this.uploadData.firstImgRaw = null;
      }
    },
    onDrop(e) {
      const _this = this.dropInstance;
      if (_this.disabled || !_this.uploader) return;
      const accept = _this.uploader.accept;
      _this.dragover = false;
      if (!accept) {
        files = e.dataTransfer.files;
        _this.$emit("file", files);
        this.batchUploadFiles = [...files]
        return;
      }
      const files = [].slice.call(e.dataTransfer.files).filter((file) => {
        const { type, name } = file;
        const extension =
          name.indexOf(".") > -1 ? `.${name.split(".").pop()}` : "";
        const baseType = type.replace(/\/.*$/, "");
        return accept
          .split(",")
          .map((type) => type.trim())
          .filter((type) => type)
          .some((acceptedType) => {
            if (/\..+$/.test(acceptedType)) {
              return extension === acceptedType;
            }
            if (/\/\*$/.test(acceptedType)) {
              return baseType === acceptedType.replace(/\/\*$/, "");
            }
            /* eslint-disable */
            if (/^[^\/]+\/[^\/]+$/.test(acceptedType)) {
              return type === acceptedType;
            }
            /* eslint-enable */
            return false;
          });
      });

      this.uploadData = {
        ...this.uploadData,
        uploadTotal: files.length,
        isDrop: true,
      };
      this.batchUploadFiles = [...files]
      _this.$emit("file", files);
    },
    // 预览
    previewHandle() {
      this.$root.$emit("previewChapterContent", this.imageAddr);
    },
    // 拖拽排序更新
    dragUpdateHandle(dragData) {
      const { newIndex, oldIndex } = dragData;
      const sortImageAddr = this.$utils.moveArrayItems(
          this.$utils.cloneDeep(this.imageAddr),
          newIndex,
          oldIndex
        )
      this.$emit(
        "update:imageAddr",
        sortImageAddr
      );
    },
    // 选择所有
    checkAllHandle(val) {
      this.checkList = val ? this.imageAddr : [];
      this.checkAll = val;
      this.isIndeterminate = false;
    },
    // 选择单个
    checkSingleHandle(list) {
      let checkedCount = list.length;
      this.checkAll = checkedCount === this.imageAddr.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.imageAddr.length;
    },
    // 删除
    removeHandle() {
      const _imageAddr = this.$utils.cloneDeep(this.imageAddr);
      this.checkList.forEach((item) => {
        let index = _imageAddr.findIndex((subItem) => {
          return subItem.imgUrl === item.imgUrl;
        });
        _imageAddr.splice(index, 1);
      });
      this.checkList = [];
      this.checkAll = false;
      this.isIndeterminate = false;
      if (_imageAddr.length === 0) {
        this.clearFirstImgRaw();
      }
      this.$emit("update:imageAddr", _imageAddr);
    },
    // 上传成功后的图片排序方法
    sortUploadedImage(list) {
      return list.sort((prev, next) => {
        const { getFileName, isNumber, getUnicode } = this.$utils;
        const prevName = getFileName(
          typeof prev === "string" ? prev : prev.imgUrl
        );
        const nextName = getFileName(
          typeof next === "string" ? next : next.imgUrl
        );
        let prevNameNum = parseInt(prevName);
        let nextNameNum = parseInt(nextName);
        if (!isNumber(prevNameNum)) prevNameNum = getUnicode(prevName);
        if (!isNumber(nextNameNum)) nextNameNum = getUnicode(nextName);
        return prevNameNum - nextNameNum;
      });
    },
    uploadImages(files) {
      const uploadFiles = [];
      const { comicId, chapterId, uploaderUid } = this.$route.query;
      files.forEach((file) => {
        const fileName = file.name;
        const uploadRequest = this.getChapterContentToken({
          comicId,
          chapterId,
          fileName,
          uploaderUid,
        })
          .then((res) => {
            return this.uploadHandle({ ...res, file }).then((res) => {
              const imgUrl = "/" + res.key;
              return imgUrl;
            });
          })
          .catch(() => {
            // 如果上传失败，找到对应图片删除
            this.clearFirstImgRaw(file);
            this.uploading = 0;
            this.uploadShow = false;
          });
        uploadFiles.push(uploadRequest);
      });
      return Promise.all(uploadFiles);
    },
    // 自定义上传方法
    uploadFunction(validFiles) {
      if(this.comicType === 11) {
        if (this.uploadShow) return;
        this.uploadShow = true;
      }
      this.uploadImages(validFiles).then((filePaths) => {
        this.uploading = 1; // 上传完成
        this.uploadSuccess(filePaths);
      });
    },
    // 进行图片鉴黄
    insertgreenImage(images) {
      this.uploading = 7; // 鉴黄
      const { comicId, chapterId } = this.$route.query;
      return this.$api("insertgreenImage", {
        comicId,
        chapterId: chapterId || 0,
        images,
        imgType: "chapter_content",
      }).then((res) => {
        let list = Array.isArray(res.data) ? res.data : [];
        list.map((item) => {
          this.imageAddr.map((subItem) => {
            if (subItem.imgUrl === item.imgUrl) {
              Object.assign(subItem, item);
            }
          });
        });
        this.$emit("update:imageAddr", this.imageAddr);
      });
    },
    // 图自动裁切
    autoCutSubmitjob(filePaths) {
      const img_source = this.sortUploadedImage(filePaths);
      const img_index = this.imageAddr.length || 1; // 添加图片序列
      this.uploading = 2; // 发起裁剪
      return this.$api("submitjob", {
        user_id: this.userId,
        img_source,
        img_index
      }).then((res) => {
        this.uploading = 3; // 发起裁剪完成
        return this.getCutProgress(res.data);
      })
    },
    getCutProgress(job_id) {
      return new Promise((resolve, reject) => {
        let timer;
        const loopFn = (oldstatus) => {
          timer = setInterval(() => {
            this.$api("getjobprogress", {
              job_id,
            }).then((res) => {
              const data = res.data;
              const { status, progress } = data;
              this.cutProgress = progress;
              if (data.status === 1) {
                this.uploading = 4; // 裁剪进度
                loopFn(status);
                return;
              }
              if (status === 3 && progress === 4) {
                this.uploading = 5;
                data.img_target = JSON.parse(data.img_target);
                resolve(data);
              } else {
                this.uploading = 6;
                reject(data);
              }
            });
            clearInterval(timer);
          }, 1000);
        };
        loopFn();
      });
    },
    // 上传完成
    async uploadEnd() {
      this.uploadData.isEnd = true;
      let { errorMessage } = this.uploadData;
      if (errorMessage.length) {
        this.$notify.error({
          title: "图片错误",
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: errorMessage
            .map(
              (group) =>
                `<strong>名称：</strong>${
                  group.name
                }<br /><strong>错误信息：</strong>${group.message
                  .map((err) => err)
                  .join("、")}`
            )
            .join("<br />"),
        });
        return;
      }
      this.$nextTick(async () => {
        if(this.comicType === 11) {
            // 全部上传完成后并裁剪后进行鉴黄
            await this.autoCutSubmitjob(this.uploadData.filePaths)
              .then((images) => {
                if (images.img_target && images.img_target.length) {
                  const imageList = this.sortUploadedImage(images.img_target);
                  const imageObjList = imageList.map((path) => ({
                    imgUrl: path,
                  }));
                  // 更新imageAddr，这里不能再排序了原因是：
                  // 1、在上传裁剪完成后已经排序操作了
                  // 2、第二次上传的序列也是1，2，3…，否则排序会混乱
                  this.$emit("update:imageAddr", [
                    ...this.imageAddr,
                    ...imageObjList,
                  ]);
                  this.$emit("uploaded");
                  return this.insertgreenImage(imageList);
                }
              })
              .then((res) => {
                this.uploadShow = false;
                setTimeout(() => {
                  this.uploading = 0;
                }, 500);
                return res;
              })
              .catch((err) => {
                console.error(err);
                this.uploading = 0;
                this.uploadShow = false;
                const data = err.data;
                this.$message.error(data.message || data.status);
                return err;
              });
        } else {
            const filePaths = this.uploadData.filePaths;
            const filePathsMap = filePaths.map(imgUrl => ({imgUrl}));
            this.$emit('update:imageAddr', this.sortUploadedImage([...this.imageAddr, ...filePathsMap]));
            await this.insertgreenImage(filePaths);
            this.$emit("uploaded");
        }
      });
    },
    // 上传错误
    uploadError(errorFiles) {
      this.uploadData.errorMessage = errorFiles;
      this.uploadData.uploadErrorNum = errorFiles.length;
      this.clearFirstImgRaw();
      this.uploadEnd();
    },
    // 上传成功
    uploadSuccess(filePaths) {
      this.uploadData.uploadSuccessNum = filePaths.length;
      this.uploadData.filePaths = filePaths;
      this.uploadEnd();
    },
    // 图片选择，由于多图片选择会执行多次这里不能重复做操作，
    // 需要进行图片收集后一次性上传并一次性获得所有上传结果，故只能通过获取原生files进行数据处理，
    // 并情况上传列表，当第二次进入时，files将为空数组
    onChange(file, fileList, refName) {
      let files = this.$refs[refName].$el.querySelector(".el-upload__input").files;
      files = this.batchUploadFiles.length ? this.batchUploadFiles: [...files]; // 这里解构赋值到files
      // 如果files数组为空不执行，或者file没有uid也不执行，原因后面代码在使用uid
      if (files.length === 0 || files.filter(item => !item.uid).length) {
          return
      };
      // element-ui的upload组件需要清空上传列表，否则多图上传的时候会重复
      this.$refs[refName].clearFiles();
      this.batchUploadFiles.length = 0; // 清楚自管理的filesList

      if (this.uploadData.isEnd) {
        // 新的一次批量上传需要重置数据
        const { isDrop, uploadTotal, firstImgRaw } = this.uploadData;
        const _data = this.$options.data();
        this.uploadData = {
          ..._data.uploadData,
          uploadTotal: isDrop ? uploadTotal : files.length,
          isDrop,
          isEnd: false,
          firstImgRaw,
          filePaths: [],
        };
      }
      this.validateImages(files).then((validFiles) => {
        let flag = true;
        let errorFiles = [];
        validFiles.forEach((file, index) => {
          const { message } = file;
          if (message.length) {
            errorFiles.push(file);
            flag = false;
          } else if (index === 0) {
            this.setFirstImgRaw(file);
          }
        });

        // 全部验证通过才上传图片
        if (flag) {
          this.uploadFunction(validFiles);
        } else {
          this.uploadError(errorFiles);
        }
      });
    },
    // 图片校验，进行批量图片校验
    validateImages(files) {
      const validateFiles = [];
      files.forEach((file) => {
        validateFiles.push(
          this.validateImageName(file).then((validateFile) => {
            if (validateFile.message.length) return validateFile;
            else return this.validateImageFormat(validateFile);
          })
        );
      });
      return Promise.all(validateFiles);
    },
    // 新增图片名称校验，交由后端接口统一校验便于维护，2019-3-20
    validateImageName(file) {
      const { comicId } = this.$route.query;
      return this.$api("checkimagesformat", {
        comicId,
        imageAddr: [file.name],
      }).then((res) => {
        file.message = [];
        if (this.$utils.isArray(res.data)) {
          res.data.forEach((ele) => {
            if (!ele.islegal) {
              file.message.push("图片名称格式错误！");
            }
          });
        }
        return file;
      });
    },
    // 验证图片格式、大小
    validateImageFormat(file) {
      const isRt2M = file.size / 1024 / 1024 > 20;

      return this.$utils.getImagePromise(file).then((image) => {
        const { width, height } = image;
        file.width = width;
        file.height = height;
        if (isRt2M) file.message.push("请上传小于20MB的图片");
        if (!/(.jpg|.png|jpeg|gif)$/i.test(file.name)) {
          file.message.push("上传图片格式错误");
        }
        if (width > 2000 || width < 800) {
          file.message.push("图片宽度不正确");
        }
        if (height > 20000) file.message.push("图片高度不正确");
        const checkRes = this.checkRawInfo(image);
        if (checkRes) {
          file.message.push(checkRes);
        }
        return file;
      });
    },
    checkRawInfo(image) {
      const { width } = image;
      const firstImgRaw = this.uploadData.firstImgRaw;
      if (firstImgRaw) {
        // 第一条上传成功的图片信息数据
        const { w } = firstImgRaw;
        if (width !== w) {
          return "图片宽度与第一张图片不一致";
        }
      }
      return "";
    },
    // 图片错误提示
    imageErrorMessage(errorList) {
      if (!errorList.length) return;
      this.$msgbox({
        title: "消息",
        message: errorList
          .map((item) => `${item.name}: ${item.message} \n`)
          .join(""),
        showCancelButton: false,
        showConfirmButton: false,
      });
    },
  },
};
</script>

<style lang="scss">
.batch-upload {
  border: 1px solid #dcdfe6;
  &-header {
    padding: 8px 10px;
    background-color: #ebeef5;
    border-bottom: 1px solid #dcdfe6;
  }
  &-imgbox {
    padding: 20px;
    flex-flow: row wrap;
  }
  &-item {
    width: 140px;
    box-sizing: border-box;
    margin: 0 1% 20px;
    background-color: #fff;
  }
  div.batch-upload-list {
    width: 100%;
    flex-wrap: wrap;
  }
  &-img {
    position: relative;
    width: 100%;
    height: 140px;
    // background-color: #e4e7ea;
    border: 1px solid #e4e7ea;
    cursor: move;
    // overflow: hidden;
    margin-bottom: 10px;
    img {
      width: 100%;
    }
  }
  &-add {
    width: 140px;
    cursor: pointer;
    color: #999;
    font-size: 14px;
    margin: 0 1% 20px;
    .el-upload {
      width: 100%;
      height: 190px;
      border: 1px solid #e4e7ea;
      .el-upload-dragger {
        width: 100%;
        height: 190px;
        border: none;
        background: #e4e7ea;
        border-radius: 0;
      }
    }
  }
  // &-btn {
  //     color: #ffffff;
  //     :focus {
  //         border-color: #409EFF;
  //         color: #ffffff;
  //     }
  // }
  &-text {
    position: absolute;
    width: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    i {
      display: block;
      color: #aaa;
      font-size: 32px;
    }
  }
  &-bigtext {
    padding: 20px;
    .h3 {
      font-size: 24px;
      color: #333;
      margin-bottom: 10px;
    }
    p {
      line-height: 1.5;
      color: #999;
    }
    i {
      font-size: 24px;
      color: #666;
    }
  }
  &-big {
    width: 100%;
    text-align: center;
  }
  .upload-btn {
    line-height: 1;
    .el-upload-dragger {
      background: none;
      border: none;
      width: auto;
      height: auto;
      text-align: center;
      position: relative;
      overflow: hidden;
    }
  }
}
</style>
