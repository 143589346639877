<template>
  <div class="audit-main flex-column">
    <el-row :gutter="20" class="audit-main-option flex-row-between">
      <el-col :span="10">
        <span v-if="comicData.operate_time" class="audit-submit-time"
          >提交时间：
          {{
            comicData.operate_time | timeFilter('yyyy年MM月dd日 hh:mm:ss')
          }}</span
        >
        <el-button
          :disabled="tableList.length !== 0"
          type="primary"
          size="mini"
          @click="forceAuditPass(comicData)"
          >强制通过</el-button
        >
      </el-col>
      <el-col :span="14" align="right">
        <span style="color: red; margin-right: 10px"
          >已选择：{{ selectedRows.length }}</span
        >
        <el-button type="primary" size="mini" @click="viewComicInfo"
          >动态漫主体预览</el-button
        >
        <el-button
          type="primary"
          size="mini"
          :disabled="checkDisablePassBtn"
          @click="promptAudit('pass')"
          >通过</el-button
        >
        <el-button
          type="danger"
          size="mini"
          :disabled="checkDisableNoPassBtn"
          @click="promptAudit('reject')"
          >拒绝</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableList"
      ref="table"
      v-loading="isloading"
      :element-loading-text="loadingText"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        label="选择"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column prop="team_name" label="动态漫团队" width="90">
      </el-table-column>
      <el-table-column prop="edit_field" label="编辑字段"></el-table-column>
      <el-table-column prop="edit_action" label="编辑行为"></el-table-column>
      <el-table-column width="120" prop="edit_details" label="编辑细节"
        ><template slot-scope="scope">{{
          showDetails(scope.row.edit_details)
        }}</template></el-table-column
      >
      <el-table-column prop="mainPrice" label="主体价格" width="80">
        <!-- <template slot-scope="scope">{{scope.row.charge_type === 0 ? '免费' : 'VIP'}}</template> -->
      </el-table-column>
      <el-table-column prop="chapter_price" label="最新章节价格" width="100">
      </el-table-column>
      <el-table-column
        label="章节封面图"
        prop="chapter_cover_16_8"
        align="center"
        width="90"
      >
        <template slot-scope="scope">
          <img-preview
            :src="
              cover_https_domain +
              $utils.filterImagePath(scope.row.chapter_cover_16_8)
            "
          >
            <img
              width="76px"
              height="38px"
              :key="
                scope.row.chapter_cover_domain +
                $utils.filterImagePath(scope.row.chapter_cover_16_8)
              "
              v-lazy="
                cover_https_domain +
                $utils.filterImagePath(scope.row.chapter_cover_16_8)
              "
            />
          </img-preview>
        </template>
      </el-table-column>
      <el-table-column prop="duration" label="时长" width="80">
        <template slot-scope="scope">{{
          scope.row.duration | reCovertTimeFilter
        }}</template>
      </el-table-column>
      <el-table-column label="审核状态">
        <template slot-scope="scope">
          <span :class="{ 'color-red': scope.row.item_audit_status === 5 }">{{
            scope.row.item_audit_status | auditAnStatusFilter(level)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="audit_remark" label="最新审核理由" width="150">
        <template slot-scope="scope">
          <span v-if="level === 4">
            <span v-if="scope.row.item_audit_status === 3">
              <auditComicRemarkEdit
                :scope="scope"
                property="audit1st_refuse_remark"
                @getTableList="getTableList"
              />
            </span>
            <span v-else>
              <el-tooltip
                class="item"
                effect="light"
                :content="scope.row.audit1st_refuse_remark"
                placement="top-start"
              >
                <div class="single-ellipsis">
                  {{ scope.row.audit1st_refuse_remark || '-' }}
                </div>
              </el-tooltip>
            </span>
            <el-tooltip
              class="item"
              effect="light"
              :content="scope.row.audit2nd_refuse_remark"
              placement="top-start"
            >
              <div class="single-ellipsis">
                {{ scope.row.audit2nd_refuse_remark || '-' }}
              </div>
            </el-tooltip>
          </span>
          <span v-if="level === 2">
            <el-tooltip
              class="item"
              effect="light"
              :content="scope.row.audit1st_pass_remark"
              placement="top-start"
            >
              <div class="single-ellipsis">
                {{ scope.row.audit1st_pass_remark || '-' }}
              </div>
            </el-tooltip>
            <span v-if="scope.row.item_audit_status === 5">
              <auditComicRemarkEdit
                :scope="scope"
                property="audit2nd_refuse_remark"
                @getTableList="getTableList"
              />
            </span>
            <span v-else>
              <el-tooltip
                class="item"
                effect="light"
                :content="scope.row.audit2nd_refuse_remark"
                placement="top-start"
              >
                <div class="single-ellipsis">
                  {{ scope.row.audit2nd_refuse_remark || '-' }}
                </div>
              </el-tooltip>
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="是否定时发布" width="100">
        <template slot-scope="scope">
          {{ scope.row.is_timing_publish ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column label="发布时间" width="300">
        <template slot-scope="scope">
          <div
            v-for="platform in scope.row.auth_platforms"
            :key="platform.platform_id"
            class="platform-col flex-row-between"
          >
            <div class="platform-col-name">
              {{ formatPlatform(platform.platform_id) }}
            </div>
            <div>
              <span class="platform-col-time">
                {{ platform.is_timing ? '定时发布' : '立即发布' }}</span
              >
              <span class="platform-col-time"
                >({{
                  platform.publish_time | timeFilter('yyyy-MM-dd hh:mm:ss')
                }})</span
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.row_type !== 'chapter'"
            type="text"
            @click="previewHandle(scope.row)"
            >预览</el-button
          >
          <el-button
            :disabled="scope.row.row_type !== 'chapter'"
            type="text"
            @click="download(scope.row)"
            >下载</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <dialogPlus
      :visible.sync="dialogData.visible"
      :title.sync="dialogData.title"
      width="700px"
      :model="dialogData.data"
      :heightFull="true"
    >
      <auditComicDialog :comicData="dialogData.data" />
    </dialogPlus>
  </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-08-31 11:10:58
 * @LastEditTime: 2018-08-31 19:01:18
 * @Description: 漫画审核-审核主体
 * @Company: 成都二次元动漫
 */

import { dialogMixin, anComicMixin } from '@/mixins'
import auditComicDialog from './auditComicDialog'
import auditComicRemarkEdit from './auditComicRemarkEdit'
import ImgPreview from '@/components/img-preview'
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  mixins: [dialogMixin, anComicMixin],
  props: {
    search: Function,
  },
  data() {
    return {
      link: null,
      chapter_id: null,
      isloading: false,
      comicData: {},
      tableList: [],
      searchData: {},
      pubClass: [],
      audit_status: null, // 待提交：1 、待审核：2 、审核拒绝：3 、审核通过:4
      selectedRows: [], // 选择的行
      loadingText: '',
    }
  },
  created() {
    this.getPublicClass().then((res) => (this.pubClass = res))
  },
  computed: {
    ...mapState('app', {
      level: (state) => state.userInfo.level, // 1：用户 2：管理 3：超管, 4:cp方审核人员
      platform_list: (state) => state.publicConfig.platform_list,
      cover_https_domain: (state) =>
        state.publicConfig.animation.cover_https_domain,
    }),
    checkDisablePassBtn() {
      // 通过按钮
      if (this.selectedRows.length === 0) {
        return true
      }
      // 如果是一审人员则不做置灰
      if (this.level === 4) {
        return false
      }
      let flag = this.selectedRows.filter((item) => {
        return ['ing'].includes(item.greenDesc)
      }).length

      if (flag) {
        return true
      }
    },
    checkDisableNoPassBtn() {
      // 拒绝按钮
      // 主体审核状态是否有未通过的
      const noSuccMainRow = this.selectedRows.filter(
        (item) => this.isComic(item) && item.compareStatus === 5
      )

      // 章节审核状态是否有未通过的
      const noSuccCapRows = this.selectedRows.filter(
        (item) => !this.isComic(item) && item.audit_status === 5
      )
      if (noSuccMainRow.length > 0 || noSuccCapRows.length > 0) {
        // 如果选中有拒绝的，则灰掉按钮
        return true
      }
      return this.selectedRows.length === 0
    },
  },
  methods: {
    showDetails(details) {
      return details
        .map((item) => {
          return item.edit_detail
        })
        .join(',')
    },
    // 是否是漫画主体
    isComic(row) {
      return row.type === 'comic'
    },
    // 格式化平台名称
    formatPlatform(platform_id) {
      const platform = this.platform_list.find(
        (platform) => platform.id === platform_id
      )
      if (platform) {
        return platform.name
      }
      return ''
    },
    handleSelectionChange(val) {
      this.selectedRows = val
    },
    searchEvent(searchData) {
      this.searchData = searchData
      this.getTableList()
    },
    // 审核操作
    promptAudit(type) {
      let url, message, title, inputValue
      const defaultInput = {
        2: '二审同意',
        4: '一审同意',
      }

      if (type === 'pass') {
        url = 'passAnAudit_Admin'
        message = '确定通过审核吗？'
        title = '审核通过'
        inputValue = defaultInput[this.level]
      } else if (type === 'reject') {
        url = 'refuseAnAudit_Admin'
        message = '确定拒绝通过审核吗？'
        title = '拒绝通过'
      }

      const { animation_id } = this.comicData

      this.$prompt(message, title, {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入理由',
        showInput: true,
        inputValue,
        inputType: 'textarea',
        customClass: 'audit-main-msgbox',
        center: true,
        inputValidator(value) {
          if (!value) return '请输入理由'
          else if (value.length > 100) return '输入字符数量1-100'
          return true
        },
        beforeClose: (action, instance, done) => {
          const closeHandle = () => {
            instance.confirmButtonLoading = false
            done()
          }
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '提交中...'
            // 如果有主体审核，单独用hasMain标识
            const include_main = this.selectedRows.some(
              (item) => item.chapter_id === 0
            )
              ? 1
              : 0
            const chapter_ids = this.selectedRows
              .filter((item) => item.chapter_id !== 0)
              .map((item) => item.chapter_id)
            this.$api(url, {
              chapter_ids,
              animation_id,
              audit_remark: instance.inputValue,
              include_main,
            })
              .then((res) => {
                this.$message.success('提交成功！')
                this.$emit('search')
                closeHandle()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
                instance.confirmButtonText = '提交'
              })
          } else {
            closeHandle()
          }
        },
      })
    },
    forceAuditPass({ animation_id }) {
      this.$api('forceAnAuditPass', { animation_id }).then(({ data }) => {
        this.$message.success('操作成功')
      })
    },
    // 查看漫画详情
    viewComicInfo() {
      let animation_id = this.$route.query.animation_id
      this.$api('getAnComicInfo', { animation_id }).then(({ data }) => {
        this.changeDialog(true, '', 0, data)
      })
    },
    //下载视频
    async download({ animation_id, chapter_id }) {
      let chapter_video_id

      let chapterData = await this.$api('getAnComicChapterInfo', {
        animation_id,
        chapter_id,
      })
      chapter_video_id = chapterData.data.info.chapter_video_id
      this.$api('getAnChapterVideoPlayAuth', {
        animation_id,
        chapter_video_id: chapter_video_id,
      }).then((res) => {
        this.$api('getAddr', {
          video_id: res.data.VideoMeta.VideoId,
          chapter_id,
        }).then((Request) => {
          let PlayInfo = Request.data.PlayInfoList.PlayInfo,
            Width = 0,
            playindex = 0
          PlayInfo.forEach((item, index) => {
            // console.log(index)
            if (item.Format == 'mp4') {
              if (item.Width > Width) {
                Width = item.Width
                playindex = index
              }
            }
          })
          playindex == 0 && playindex == PlayInfo.length - 1
          console.log(playindex)
          let PlayURL = PlayInfo[playindex].PlayURL
          this.isloading = true
          this.downloadvideo(PlayURL, 'video' + Date.now()).then(
            () => {
              // 下载完成todo
            },
            (progress) => {
              // 下载进度
              console.log(progress)
            }
          )
          // console.log(Request.PlayInfoList.PlayInfo)
        })
      })
    },
    downloadvideo(url, name, callback) {
      return new Promise((resolve, reject) => {
        axios(url, {
          method: 'get',
          responseType: 'blob',
          onDownloadProgress: (evt) => {
            const progress = parseInt((evt.loaded / evt.total) * 100)
            // console.log('progress: ' + progress + '%')
            this.loadingText = '下载中: ' + progress + '%'
            if (typeof callback === 'function') {
              callback(progress)
            }
          },
        })
          .then((res) => {
            const type = res.headers['content-type'] //请求头中文件类型
            const blob = new Blob([res.data])
            const a = document.createElement('a')
            a.download = name + '.' + type?.replace('video/', '')
            a.href = URL.createObjectURL(blob)
            a.click()
            URL.revokeObjectURL(a.href)
            a.remove()
            this.isloading = false
            this.loadingText = ''
            resolve()
          })
          .catch((err) => {
            reject(err)
            this.isloading = false
            this.loadingText = ''
          })
      })
    },

    // 预览章节
    async previewHandle({ animation_id, chapter_id }) {
      let chapter_video_id
      if (!this.link) {
        this.link = document.createElement('a')
      }
      let chapterData = await this.$api('getAnComicChapterInfo', {
        animation_id,
        chapter_id,
      })
      chapter_video_id = chapterData.data.info.chapter_video_id
      await this.$api('getAnChapterVideoPlayAuth', {
        animation_id,
        chapter_video_id: chapter_video_id,
      }).then((res) => {
        this.link.setAttribute(
          'href',
          `/static/video_preview.html?playauth=${res.data.PlayAuth}&vid=${chapter_video_id}`
        )
        this.link.setAttribute('target', '_blank')
        this.link.click()
      })
    },
    // 查询列表
    getTableList() {
      this.isloading = true
      this.$api('getAnComicDiff_Admin', {
        animation_id: this.searchData.animation_id,
      }).then(({ data }) => {
        this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0)
        this.comicData = data.user_animation
        this.tableList = this.selectHandle(data.diff_rows, this.searchData)
        this.tableList.forEach((item) => {
          item.mainPrice = data.user_animation.chapter_price
        })
        this.isloading = false
      })
    },
    // 前端查询
    selectHandle(list, searchData) {
      const { type, chapterName } = searchData
      if (type !== '全部') {
        list = list.filter((item) => item.edit_action === type)
      } else if (chapterName) {
        list = list.filter((item) => {
          const chapterNameArray = item.chapterName.split('')
          return chapterName.split('').some((b) => chapterNameArray.includes(b))
        })
      }
      return list
    },
  },
  components: {
    auditComicDialog,
    ImgPreview,
    auditComicRemarkEdit,
  },
}
</script>
<style lang="scss">
.audit-main {
  height: 100%;
  &-option {
    margin-bottom: 10px;
  }
  .el-table {
    flex-grow: 1;
    .el-table__body-wrapper {
      height: calc(100% - 40px);
      position: relative;
      overflow-y: auto;
    }
    .has-deffer {
      td {
        background: #f5ecec;
      }
    }
  }
  .audit-submit-time {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
}
.audit-main-msgbox {
  .el-message-box__title span {
    color: red;
    font-size: 20px;
  }
}
</style>
