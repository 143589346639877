/*
 * @Author: daipeng
 * @Date: 2018-09-19 20:32:47
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-23 16:02:01
 * @Description: 漫画信息mixin
 * @Company: 成都二次元动漫
 */
import { auditRelatedVideoCard } from '@/components/comic';

export default {
    data() {
        return { };
    },
    methods: { },
    components: {
        auditRelatedVideoCard
    }
};
