<template>
    <div class="audit-comic page-container flex-column">
        <!-- search-box -->
        <el-form class="search-box" ref="searchForm" align="center" :model="searchData" inline @submit.native.prevent>
            <el-form-item prop="correlation_name" label="视频名称：">
                <el-input style="width: 260px;"  v-model="searchData.correlation_name" placeholder="输入视频名称" clearable class="search-input" @keyup.enter.native.prevent="searchHandle">
                </el-input>
            </el-form-item>
            <el-form-item prop="show_status" label="显示状态:">
                <el-select v-model="searchData.show_status" style="width:100px" placeholder="选择状态">
                    <el-option v-for="(item,index) in showTypes" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="searchHandle">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="clearSearchHandle">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="comic-dispatch-line"></div>
        <!-- search-box-end -->
        <div class="audit-comic-tab">
            <el-tabs v-model="activeName">
                <template v-for="(tab, index) in auditType[level]">
                    <el-tab-pane :key="tab.id" :name="tab.id + ''">
                        <div slot="label">{{tab.name}} <span class="tab-icon">{{tab.total}}</span></div>
                        <keep-alive>
                            <auditComicTable :ref="'audit' + (index + 1)" :index="tab.id" @changeTypeNumber="changeTypeNumber" :isTable="isTable" />
                        </keep-alive>
                    </el-tab-pane>
                </template>
            </el-tabs>
        </div>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-23 10:22:07
 * @Description: 漫画管理-我的漫画
 * @Company: 成都二次元动漫
 */
import { searchMixin } from '@/mixins';
import auditComicTable from './auditComicTable';
import { mapState } from 'vuex';
export default {
    mixins: [searchMixin],
    components: { auditComicTable },
    data() {
        return {
            activeName: 'all',
            searchDateRange: null,
            searchData: {
                show_status: -2,
                query_type: 'all',
                correlation_name: null
            },
            showTypes: [
                { id: -2, name: '全部' },
                { id: -1, name: '未上线' },
                { id: 0, name: '已下架' },
                { id: 1, name: '已上架' }
            ],
            isTable: false,
            auditType: {
                // 二审人员
                '2': [
                    { id: 'all', name: '全部', total: null },
                    { id: 'auditor1st_auditing', name: '一审审核中', total: null },
                    { id: 'auditor2nd_auditing', name: '二审审核中', total: null },
                    { id: 'auditor1st_refuse', name: '一审未通过', total: null },
                    { id: 'auditor2nd_refuse', name: '二审未通过', total: null },
                    { id: 'auditor2nd_pass', name: '二审通过', total: null }
                ],
                // 一审人员
                '4': [
                    { id: 'all', name: '全部', total: null },
                    { id: 'auditor1st_auditing', name: '待审核', total: null },
                    { id: 'auditor1st_refuse', name: '未通过', total: null },
                    { id: 'auditor2nd_auditing', name: '审核通过', total: null }
                ]
            }
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        })
    },
    watch: {
        level(newValue, oldValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.searchHandle();
                });
            }
        },
        'searchDateRange'(dateRange = []) {
            const [startTime = null, endTime = null] = dateRange | [];
            this.searchData.startTime = startTime;
            this.searchData.endTime = endTime;
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if ([2, 4].includes(vm.level)) {
                vm.searchHandle();
            }
        });
    },
    methods: {
        // 搜索
        searchHandle() {
            this.broadCastSearchEvent();
        },
        // 清除搜索
        clearSearchHandle() {
            this.resetForm('searchForm');
            this.searchData.auditStatus = null;
            this.searchHandle();
        },
        // 更新数量
        changeTypeNumber(id, status) {
            this.auditType[this.level].map(item => {
                if (item.id === id) {
                    item.total = status;
                }
            });
        },
        // 列表模式切换
        tableModeChange({ isTable }) {
            this.isTable = isTable;
        },
        // 广播查询到子组件
        broadCastSearchEvent() {
            this.auditType[this.level].map((item, index) => {
                const _data = this.$utils.cloneDeep(this.searchData);
                _data.query_type = item.id;
                this.$refs[`audit${index + 1}`][0].searchEvent(_data);
            });
        }
    }
};
</script>

<style lang="scss">
    .audit-comic{
        .search-input{
            width: 524px;
        }
        &-tab{
            height: calc(100% - 93px);
            position: relative;
            .el-tabs{
                height: 100%;
                overflow: hidden;
                .el-tabs__content{
                    height: calc(100% - 55px);
                    .el-tab-pane{
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
            .table-change-button{
                position: absolute;
                right: 0;
                top: 5px;
            }
        }
    }
</style>
