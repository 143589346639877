<template>
    <div class="admin-user page-container flex-column">
        <!-- search-box -->
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @keyup.enter.native.prevent="searchHandle">
            <el-form-item label="账号:" prop="id">
                <el-input v-model="searchData.id" clearable placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="用户名称:" prop="name">
                <el-input v-model="searchData.name" clearable placeholder="请输入"></el-input>
            </el-form-item>
             <el-form-item label="状态:" prop="enable">
                <el-select v-model="searchData.enable" clearable placeholder="请选择" style="width: 110px;">
                    <el-option v-for="(item, index) in userAccountStatusList" :key="item" :label="item" :value="index - 1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchHandle">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button @click="clearSearchHandle">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- search-box-end -->
        <div class="admin-user-option flex-row mb10">
            <div class="mr10">已经择<span class="color-green"> {{allSelect.length}} </span>项</div>
            <el-button size="mini" type="primary" @click="dialogHandle(1)">新增用户</el-button>
            <el-button size="mini" type="primary" @click="disablePermissionHandle(1)">启用所选用户权限</el-button>
            <el-button size="mini" type="warning" @click="disablePermissionHandle(0)">禁用所选用户权限</el-button>
            <el-button size="mini" type="danger" @click="clearPermissionHandle">清空所选用户权限</el-button>
        </div>
        <el-table class="admin-user-table" ref="table" :data="tableList" border style="width: 100%" v-loading="isloading" @selection-change="selectionChangeHandle">
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="账号" prop="userId" width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="dialogHandle(0, scope.$index,scope.row)">{{scope.row.userId}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="账号类型" prop="userTypeName" width="150" align="center"></el-table-column>
            <el-table-column label="用户名称" prop="userName" width="150" align="center"></el-table-column>
            <el-table-column label="用户平台" prop="userPlatform" width="150" align="center"></el-table-column>
            <el-table-column label="可用权限" prop="permissions" align="center">
                <template slot-scope="scope">
                    <el-tooltip :disabled="!scope.row.permissions.length" class="item" effect="light" :content="scope.row.permissions | userPermissionFilter('、')" placement="top-start">
                        <div class="width100 single-ellipsis">{{scope.row.permissions | userPermissionFilter('、') || '无'}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="注册时间" prop="createTime" width="150" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.createTime | timeFilter('yyyy-MM-dd hh:mm:ss')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="enable" width="80" align="center">
                <template slot-scope="scope">
                    <div class="admin-user-status enabled" v-if="scope.row.enable">已启用</div>
                    <div class="admin-user-status disable" v-else>已禁用</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.enable" type="text" @click="disablePermissionHandle(0, scope.$index, scope.row)">禁用</el-button>
                    <el-button v-else type="text" @click="disablePermissionHandle(1, scope.$index, scope.row)">启用</el-button>
                    <el-button type="text" @click="dialogHandle(2, scope.$index, scope.row)">编辑</el-button>
                    <el-button v-if="scope.row.userLevel === 4" type="text" @click="relevanceHandle(scope.row)">关联</el-button>
                    <el-button v-else type="text" disabled>关联</el-button>
                </template>
            </el-table-column>
        </el-table>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
        <dialogPlus :visible.sync="dialogData.visible" :title.sync="dialogData.title" width="700px" :heightFull="heightFull" @close="dialogCloseHandle">
            <userDialogContent :user="dialogData.data" :type="dialogData.type" ref="userDialogContent"/>
            <div slot="footer" align="center">
                <template v-if="dialogData.type === 0">
                    <el-button @click="dialogHandle(2, dialogData.data.$index, dialogData.data)">编 辑</el-button>
                    <el-button type="primary" @click="dialogCloseHandle">确 定</el-button>
                </template>
                <template v-else-if="dialogData.type === 1">
                    <el-button @click="dialogCloseHandle">取消</el-button>
                    <el-button type="primary" @click="addUser()">确 定</el-button>
                </template>
                <template v-else>
                    <el-button @click="dialogCloseHandle">取 消</el-button>
                    <el-button type="primary" @click="addAndEditSubmitHandle(dialogData.data)">确 定</el-button>
                </template>
            </div>
        </dialogPlus>
        <el-dialog title="一审账号关联CP账号" center :visible.sync="dialogVisible" width="50%">
            <relevanceContent :user="relevanceData" @close="relevanceClose"></relevanceContent>
        </el-dialog>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-12 20:17:44
 * @Description: 管理中心-用户列表
 * @Company: 成都二次元动漫
 */
import { searchMixin, paginationMixin, dialogMixin, formMixin } from '@/mixins';
import { userAccountStatusList } from '@/libs/filters';
import userDialogContent from './userDialogContent';
import relevanceContent from './relevanceContent';

export default {
    mixins: [searchMixin, paginationMixin, dialogMixin, formMixin],
    data() {
        return {
            isloading: false,
            userAccountStatusList,
            heightFull: false,
            searchData: {
                id: null,
                name: null,
                enable: -1,
                order: null
            },
            tableList: [],
            allSelect: [],
            dialogVisible: false,
            relevanceData: {},
            userLevel: ['', '普通cp', '二审', '', '一审'],
            teamType: ['', '-独家', '-非独家']
        };
    },
    computed: {
        rowList() {
            return this.allSelect.map(power => power);
        }
    },
    created() {
        this.getTableList();
    },
    mounted() {
    },
    methods: {
        // 搜索
        searchHandle() {
            this.pagination.pageIndex = 1;
            this.getTableList();
        },
        // 清除搜索
        clearSearchHandle() {
            this.resetForm('searchForm');
            this.searchData.auditStatus = null;
            this.searchHandle();
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            this.$api('getUserList', { ...this.searchData, ...this.pagination }).then(res => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                const { rows, pageIndex, pageSize, total } = res.data;
                rows.map(item => {
                    item.userTypeName = this.userLevel[item.userLevel] + this.teamType[item.teamType];
                });
                this.tableList = rows;
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
                this.isloading = false;
            });
        },
        // 行选择
        selectionChangeHandle(list) {
            this.allSelect = list;
        },
        // 启用、禁用用户权限操作
        disablePermissionHandle(status, index, row) {
            if (!this.rowList.length && !row) return;
            let rowList = row ? [row] : this.rowList;

            this.$utils.confirm('', '确定修改选中状态吗？', '修改中...', (action, instance, done) => {
                return this.$api('disableUserPermission', { status, list: rowList.map(item => ({ userId: item.userId, userName: item.userName })) }).then(res => {
                    // 批量操作，需要刷新整个列表
                    if (this.$utils.isUndefined(index)) this.getTableList();
                    // 单个操作不需要刷新列表
                    else {
                        const currentTabel = this.$utils.cloneDeep(this.tableList);
                        currentTabel[index].enable = currentTabel[index].enable ? 0 : 1;
                        this.dialogData.data = { ...this.dialogData.data, ...currentTabel[index] };
                        this.tableList = currentTabel;
                    }
                });
            });
        },
        // 清空用户所有权限
        clearPermissionHandle() {
            if (!this.rowList.length) return;

            this.$utils.confirm('', '确定修改选中状态吗？', '修改中...', (action, instance, done) => {
                return this.$api('clearUserPermission', { list: this.rowList.map(item => ({ userId: item.userId, userName: item.userName })) }).then(res => {
                    this.getTableList();
                });
            });
        },
        // 编辑用户权限
        addAndEditSubmitHandle(data) {
            const {userId, userName, userLevel, teamType} = data;
            const list = this.$refs.userDialogContent.list.map(item => item.code);
            if (!this.checkUser(userName, list)) return false;
            this.$utils.confirm('', '确定提交吗？', '提交中...', (action, instance, done) => {
                return this.$api('editUserPermission', { id: userId, name: userName, userLevel, teamType, permissions: list }).then(res => {
                    this.getTableList();
                    this.dialogCloseHandle();
                });
            });
        },
        // 打开dialog
        dialogHandle(type, $index, data) {
            if (type === 0) {
                this.heightFull = false;
                this.changeDialog(true, '用户详情', 0, { ...data, $index });
            } else if (type === 2) {
                this.heightFull = true;
                this.changeDialog(true, '编辑用户', 2, { ...data, $index });
            } else {
                this.heightFull = true;
                this.changeDialog(true, '新建用户', 1);
            }
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        },

        // 添加用户
        addUser() {
            const { userName, userType, teamType } = this.$refs.userDialogContent;
            const list = this.$refs.userDialogContent.list.map(item => item.code);
            if (!this.checkUser(userName, list)) return false;
            return this.$api('createUserPermission', { name: userName, userLevel: userType, teamType, permissions: list }).then(res => {
                this.getTableList();
                this.dialogCloseHandle();
            });
        },

        // 关联账号
        relevanceHandle(data) {
            this.relevanceData = data;
            this.dialogVisible = true;
        },

        // 关联账号关闭弹框
        relevanceClose() {
            this.dialogVisible = false;
        },

        // 验证账号
        checkUser(userName, list) {
            if (userName === '') {
                this.$message({
                    type: 'error',
                    message: '请输入用户名称'
                });
                return false;
            }
            if (userName.length < 1 || userName > 20) {
                this.$message({
                    type: 'error',
                    message: '用户名称应为1-20位！'
                });
                return false;
            }
            if (list.length <= 0) {
                this.$message({
                    type: 'error',
                    message: '请选择用户权限'
                });
                return false;
            }
            return true;
        }
    },
    components: {
        userDialogContent,
        relevanceContent
    }
};
</script>

<style lang="scss">
    @import '../../../../styles/var';
    .admin-user{
        &-status{
            &::before{
                display: inline-block;
                content: " ";
                width: 3px;
                height: 6px;
                border-radius: 3px;
                margin-right: 10px;
                vertical-align: middle;
            }
            &.enabled::before{
                background: $color-success;
            }
            &.disable:before{
                background: $color-danger;
            }
        }
        &-option{
            height: 28px;
            align-items: center;
        }
        &-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
        }
        .search-input {
            width: 524px;
        }
    }
</style>
