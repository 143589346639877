<template>
    <div class="chapter-field">
        <div v-if="!editActive" class="flex-row-between">
            <div>{{scope.row[property]}}</div>
            <div @click="editHandle"><i class="el-icon-edit color-green"></i></div>
        </div>
        <div v-else class="flex-row-between">
            <el-input
                class="mr10"
                size="mini"
                v-model="scope.row[property]"
            ></el-input>
            <el-button type="text" icon="el-icon-check" @click.stop="submitHandle"></el-button>
            <el-button type="text" icon="el-icon-close" @click.stop="cancleHandle"></el-button>
        </div>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-21 13:33:56
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-18 09:13:59
 * @Description: 章节列表-行内编辑
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        scope: Object,
        property: String
    },
    data() {
        return {
            editActive: false,
            cloneRowData: null
        };
    },
    methods: {
        // 开始编辑
        editHandle() {
            this.editActive = true;
            this.cloneRowData = this.$utils.cloneDeep(this.scope.row);
        },
        // 取消编辑
        cancleHandle() {
            this.editActive = false;
            this.scope.row[this.property] = this.cloneRowData[this.property];
        },
        // 确认修改
        submitHandle() {
            const { type, comicId, chapterId } = this.scope.row;
            const content = this.scope.row[this.property];
            if (type === 'comic') {
                this.$api('auditRemarkComic', { comicId, content }).then(res => {
                    this.$message.success(`修改成功！`);
                    this.editActive = false;
                });
                return false;
            }
            this.$api('auditRemarkChapter', { comicId, chapterId, content }).then(res => {
                this.$message.success(`修改成功！`);
                this.editActive = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .chapter-field{

    }
    .el-icon-edit{
        cursor: pointer;
    }
</style>
