/*
 * @Author: daipeng
 * @Date: 2018-09-03 18:57:19
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-12 18:49:05
 * @Description: 封装request方法
 * @Company: 成都二次元动漫
 */
import axios from 'axios';
import router from '@/router';
import { Message } from 'element-ui/lib';
import { replaceUrl, isUndefined } from '@/libs/utils';

const instance = axios.create();
const AJAX_DEFAULT_CONFIG = {
    headers: {
        'Content-Type': 'application/json'
    }
};

let message = null;
/**
 * 未登录时跳转到登录页面
 */
const gotoLogin = () => {
    const { pathname } = window.location;
    if (!['/login', '/'].includes(pathname)) {
        Message({
            type: 'warning',
            message: '未登录',
            duration: '1000',
            onClose: () => router.replace({ path: '/login' })
        });
    }
};
/**
 * 统一弹出错误消息
 *
 * @param {*} message
 */
const errorMessage = msg => {
    message && message.close();
    message = Message.error({
        type: 'error',
        message: msg,
        duration: '1000'
    });
};

// 请求拦截器
instance.interceptors.request.use(config => {
    return config;
});

// 响应拦截器
instance.interceptors.response.use(res => {
    const { status, data } = res;
    // webserver状态
    if (status === 200) {
        // 只有weserver成功并且接口响应成功，才resolve，其余的都是reject
        if (data.status === 0) {
            return Promise.resolve(data);
        } else if (data.status === 10004) {
            gotoLogin();
        } else if (isUndefined(data.status)) {
            return Promise.resolve(data);
        } else {
            errorMessage(data.message);
            return Promise.reject(res);
        }
    } else {
        errorMessage(data.message);
        return Promise.reject(res);
    }
}, error => {
    const { response } = error;
    if (response.status === 401) {
        gotoLogin();
    } else errorMessage(response.data.message);
    return Promise.reject(response);
});

Object.assign(instance.defaults, AJAX_DEFAULT_CONFIG);

const request = method => {
    return url => {
        return data => {
            return options => {
                return config => {
                    const _data = ['get'].includes(method) ? { params: data } : { data };
                    url = replaceUrl(url, options);
                    return instance({
                        ...config,
                        url,
                        method,
                        ..._data
                    });
                };
            };
        };
    };
};

const [get, post, put, remove] = ['get', 'post', 'put', 'delete'].map(method => {
    return request(method);
});

export { get, post, put, remove, instance };
