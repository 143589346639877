import http from '@/api';
import { isArray, isObject, cloneDeep } from '@/libs/utils';
import { mainMenuRoutes } from '@/router/routes';

const defaultState = {
    breadcrumb: [],
    menuList: [],
    publicConfig: {},
    userInfo: {
        name: '',
        id: 0,
        platform: '',
        level: 1,
        enable: 1,
        desc: '',
        sign: '',
        permissions: [],
        auditComic: 0,
        auditAnComic: 0,
        auditRelative: 0,
        level_extra: [] // [1 创作价格 2 工作效率 3 批量修改创作价格]
    }
};

function checkPowerKeys(keys = [], powerKey = '') {
    let powerKeys;
    powerKeys = powerKey.split(',');
    return powerKeys.some(function(item) {
        return keys.includes(item);
    });
};

export default {
    namespaced: true,
    state: cloneDeep(defaultState),
    mutations: {
        setPublicConfig(state, payload) {
            state.publicConfig = { ...state.publicConfig, ...payload };
        },
        setUserInfo(state, payload) {
            state.userInfo = { ...state.userInfo, ...payload };
            state.userInfo.level_extra = payload.level_extra.split(' ');
            const { level } = payload;
            // 1：用户 2：管理|二审 3：超管 4:一审人员
            state.menuList = cloneDeep(mainMenuRoutes).map(subMenu => {
                switch (level) {
                case 1: {
                    if (!checkPowerKeys(['comicManagerMenu', 'personalCenterMenu'], subMenu.powerKey)) subMenu.isMenu = false;
                    break;
                }
                case 2: {
                    if (!checkPowerKeys(['comicManagerMenu', 'anComicManagerMenu', 'personalCenterMenu', 'adminCenterMenu'], subMenu.powerKey)) subMenu.isMenu = false;
                    else if (subMenu.name === 'adminCenter') {
                        subMenu.children.forEach(menu => {
                            if (checkPowerKeys(['powerSettingMenu'], menu.powerKey)) menu.isMenu = false;
                        });
                    }
                    break;
                }
                case 3: {
                    if (!checkPowerKeys(['personalCenterMenu', 'adminCenterMenu'], subMenu.powerKey)) subMenu.isMenu = false;
                    else if (subMenu.powerKey === 'adminCenterMenu') {
                        subMenu.children.forEach(menu => {
                            if (checkPowerKeys(['auditComicMenu', 'auditRecordList', 'auditAnComicMenu', 'auditRelatedVideoMenu'], menu.powerKey)) menu.isMenu = false;
                        });
                    }
                    break;
                }
                case 4: {
                    if (!checkPowerKeys(['personalCenterMenu', 'adminCenterMenu'], subMenu.powerKey)) subMenu.isMenu = false;
                    else if (subMenu.name === 'adminCenter') {
                        subMenu.children.forEach(menu => {
                            if (!checkPowerKeys(['auditComicMenu', 'auditRecordList', 'auditAnComicMenu', 'auditRelatedVideoMenu'], menu.powerKey)) menu.isMenu = false;
                        });
                    }
                    break;
                }
                };
                // 判断有没有工时效率菜单权限[1 创作价格 2 工作效率 3 批量修改创作价格]
                if (subMenu.name === 'personalCenter' && !state.userInfo.level_extra.includes('2')) {
                    subMenu.children.forEach(menu => {
                        if (checkPowerKeys(['personalEffectMenu'], menu.powerKey)) menu.isMenu = false;
                    });
                }
                console.log(subMenu)
                return subMenu;
            });
        },
        setBreadcrumb(state, payload) {
            state.breadcrumb = [...payload];
        },
        pushBreadcrumb(state, payload) {
            if (isArray(payload)) state.breadcrumb = [...state.breadcrumb, ...payload];
            else if (isObject(payload)) state.breadcrumb = [...state.breadcrumb, payload];
        },
        // 重置state
        resetState(state) {
            const def = cloneDeep(defaultState);
            state.breadcrumb = def.breadcrumb;
            state.menuList = def.menuList;
            state.userInfo = def.userInfo;
        },
        // 设置用户信息（外部整体替换data数据）
        setUserInfoCustom(state, payload) {
            state.userInfo = payload;
        }
    },
    actions: {
        getUerInfo({ commit }, payload) {
            const { user_id } = payload;
            http('userInfo', {}, { userId: user_id });
            commit('setUserInfo', payload);
        },
        async getPublicConfig({ commit }) {
            let data = await http('getPublicConfig').then(({data}) => data);
            commit('setPublicConfig', data);
        }
    }
};
