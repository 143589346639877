<template>
    <div class="edit-chapter flex-column" v-loading="isLoading" element-loading-text="保存中" element-loading-spinner="el-icon-loading">
        <el-form ref="chapterForm" :model="chapterData" :rules="type?chapterDataRules:{}" label-width="200px">
            <el-form-item label="章节编号：" prop="chapterSN">
                <el-col :span="12">
                    <span v-if="type === 'view'" class="edit-chapter-name">{{chapterData.chapterSN}}</span>
                    <el-input v-else :style="type===2?'display:none':''" v-model="chapterData.chapterSN" placeholder='最多1 - 30个数字、字母、汉字或符号，例: "第1话"'/>
                </el-col>
                <el-col :span="12">
                    <div v-if="lastChaterName" style="margin-left: 20px;color: #666">上一话为：{{ lastChaterName }}</div>
                </el-col>
            </el-form-item>
            <el-form-item label="最终话：">
                <el-radio-group v-model="chapterData.is_over">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="章节名：" prop="chapterName">
                <el-col :span="12">
                    <span v-if="type === 'view'" class="edit-chapter-name">{{chapterData.chapterName}}</span>
                    <el-input v-else :style="type===2?'display:none':''" v-model="chapterData.chapterName" placeholder='最多1-40个字符，和本章内容相关，例"萧家秘事"'/>
                </el-col>
            </el-form-item>
            <el-form-item label="章节格数：" prop="gridNum">
                <el-col :span="12">
                    <el-input maxlength="11" v-acgn-number="2" v-model="chapterData.gridNum"/>
                </el-col>
            </el-form-item>
            <el-form-item label="章节价格：" prop="chapterPrice">
                <template v-if="type === 'view'">{{chapterData.chapterPrice || 0}}</template>
                <template v-else>
                    <el-radio-group v-model="chapterData.chapterPriceStatus" @change="status => (status === 0 ? chapterData.chapterPrice = null : status)">
                        <el-radio :label="0">免费</el-radio>
                        <el-radio :label="1">收费</el-radio>
                    </el-radio-group>
                    <template v-if="chapterData.chapterPriceStatus">
                        <el-input-number v-model="chapterData.chapterPrice" :min="0" :max="500"></el-input-number> 钻石
                        <div class="search-box-message"><i class="el-icon-info"></i>选择收费填写数字价格，收费价格必须大于0，最大限制500钻石，100钻石=1元</div>
                    </template>
                </template>
            </el-form-item>
            <el-form-item label="章节类型：" prop="chapterPrice">
                <template v-if="type === 'view'">{{chapterData.chapterPrice || 0}}</template>
                <template v-else>
                    <el-select v-model="chapterData.chapterType" @change="setVip">
                      <el-option
                        v-for="item in chapterTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                </template>
            </el-form-item>
            <el-form-item label="会员专属：" prop="isVip" v-if="chapterData.chapterType === 2">
                <el-checkbox v-model="chapterData.isVip" :true-label="1" :false-label="0"></el-checkbox>
                <el-tooltip effect="light" placement="top">
                <div slot="content">勾选后，该章节仅限会员用户阅读</div>
                    <i class="el-icon-question" style="margin-left:10px;"/>
                </el-tooltip>
            </el-form-item>
            <el-form-item v-if="hasCreatePricePermission" label="创作价格" prop="createPrice">
                <el-row>
                    <el-col :span="6">
                        <el-input v-model="chapterData.createPrice" v-acgn-number="2" maxlength="12" placeholder="请输入创作价格，单位（每格/元）"/>
                    </el-col>
                    <el-col :span="12" style="color:gray;">
                       &nbsp;<i class="el-icon-info"></i>创作价格包含作品的稿酬和人力成本
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="上传图片：" prop="imageArray">
                <batchUpload @uploaded="handleUploaded" ref="batchUpload" :type="type" :show-succ-count="false" :imageAddr.sync="chapterData.imageArray" :comicType="comicType" v-if="!isAutoCut"/>
                <batchUploadAutoCut @uploaded="handleUploaded" ref="batchUpload" :type="type" :show-succ-count="false" :imageAddr.sync="chapterData.imageArray" :comicType="comicType" v-else/>
                <div class="edit-chapter-tips flex-row-between" v-if="comicType == 11">
                    <div style="line-height:1.25">图片宽度尺寸800-2000，高度≤20000，<span class="color-red font-bold">(所有图片宽度需一致)</span>，大小20M内，支持jpg、JPG、png，<br/>若是上传多张图，则每张图需按顺序命名为自然序号（例：上传序列为1，2，3，4，以此类推）</div>
                </div>
                <div class="edit-chapter-tips flex-row-between" v-else>
                    <div style="line-height:1.25">图片宽度尺寸800-2000，<span class="color-red font-bold">(所有图片宽度需一致)</span>，大小2M内，支持jpg、JPG、png，（例：上传序列为1，2，3，4，以此类推）</div>
                </div>
            </el-form-item>
            <el-form-item label="章节看点：" prop="intro">
                <chapterFocus :type="type" :intro.sync="chapterData.intro"/>
            </el-form-item>
            <el-form-item label="章节图：" prop="coverObj">
                <chapterCover @uploaded="handleUploaded" :type="type" :chapterCover168.sync="chapterData.coverObj"/>
            </el-form-item>
            <el-form-item label="发布平台：" prop="publishTask" class="edit-chapter-platform">
                <div class="edit-chapter-tips flex-row-between">
                    <div><span class="color-red font-bold">重要提示：</span>选择【全部】后，设置好的状态或时间选项会生效于所有发布平台</div>
                </div>
                <div class="chapter-cover">
                    <div class="el-checkbox-group flex-row">
                        <el-checkbox v-model="allPlat.checked" label="全部" @change="handleSelectAll"></el-checkbox>
                        <el-radio-group
                            class="flex-row autoResize"
                            v-model="allPlat.isTiming"
                            @change="syncSelectAll"
                            :disabled="!allPlat.checked">
                            <el-radio :label="0" >立即发布</el-radio>
                            <el-radio :label="1">定时发布</el-radio>
                            <el-date-picker type="datetime" :editable="false" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerDisabled" v-model="allPlat.publishTime" placeholder="选择日期时间"
                            :disabled="!allPlat.isTiming || !allPlat.checked"
                            :default-time="defaultTime()"
                            @change="handleDatePickAllChange(allPlat)"></el-date-picker>
                        </el-radio-group>
                    </div>
                    <template v-for="platform in platformList">
                        <el-checkbox-group class="flex-row"
                            @change="handleChangeSelect"
                            v-model="chapterData.publishTask" :disabled="type === 'view'" :key="platform.platformId">
                            <el-checkbox :label="platform">{{platform.name}}</el-checkbox>
                            <el-radio-group class="flex-row autoResize" v-model="platform.isTiming" :disabled="!chapterData.publishTask.includes(platform)" @change="syncSelectRadio">
                                <el-radio :label="0" :disabled="type === 'view'">立即发布</el-radio>
                                <el-radio :label="1" :disabled="type === 'view'">定时发布</el-radio>
                                <el-date-picker type="datetime" :default-time="defaultTime(platform)" :editable="false" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerDisabled" :disabled="!platform.isTiming ||  type === 'view'" v-model="platform.publishTime" placeholder="选择日期时间" @change="datePickerChange(platform)"></el-date-picker>
                            </el-radio-group>
                        </el-checkbox-group >
                    </template>
                </div>
            </el-form-item>
            <dialogPlus :visible.sync="dialogData.visible" :title.sync="dialogData.title" width="530px" :model="dialogData.data" :heightFull="true">
                <template v-if="dialogData.data.dialogName === 'agreement'">
                    <agreement />
                    <div slot="footer">
                        <el-button @click="dialogCloseHandle">取消</el-button>
                        <el-button type="primary" @click="agreementHandle">同意</el-button>
                    </div>
                </template>
                <previewChapterContent v-if="dialogData.data.dialogName === 'previewChapterContent'" :imageList="chapterData.imageArray"/>
            </dialogPlus>
        </el-form>
        <div v-if="type !== 'view'" class="mmiddle">
            <el-checkbox v-model="agreement">我同意</el-checkbox>
            <el-button type="text" @click.stop="changeDialog(true, '用户协议', 1, { dialogName: 'agreement' })" size="medium">中国漫画分发中心服务协议</el-button>
        </div>
        <!-- buttons -->
        <div v-if="type !== 'view'" class="mmiddle btns">
            <el-button size="mini" type="primary" @click="submitFormHandle('next')">保存并进入下一章</el-button>
            <el-button type="primary" size="mini" @click="submitFormHandle">保存</el-button>
            <el-button size="mini" @click="cancelHandle('chapterForm')">取消</el-button>
        </div>
        <el-dialog class="fulldialog" title="预览" width="800px" :append-to-body="true" :visible.sync="showDialog">
            <div class="fulldialog-content" :key="fullDialogImage" v-lazy:background-image="fullDialogImage">
                <div class="fulldialog-focus" :style="{
                    color: chapterData.intro.fontColor,
                    textStroke: `2px ${chapterData.intro.borderColor}`,
                    textShadow: `0 0 8px ${chapterData.intro.borderColor}`,
                    textAlign: ['', 'left', 'center', 'right'][chapterData.intro.position]
                }">{{chapterData.intro.content}}</div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-17 14:58:30
 * @LastEditTime: 2018-09-17 14:58:30
 * @Description: 编辑章节-具体实现
 * @Company: 成都二次元动漫
 */
import { formMixin, dialogMixin, dataMixin, createPriceStaffMixin } from '@/mixins';
import batchUpload from './batchUpload';
import batchUploadAutoCut from './batchUploadAutoCut';
import chapterFocus from './chapterFocus';
import chapterCover from './chapterCover';
import previewChapterContent from './previewChapterContent';
import agreement from '@/views/main/other/agreement';
import { timeFormat } from '@/libs/utils';
const TIMING_PUBLISH = 8.64e7; // 定时发布默认为24小时
import { mapState } from 'vuex';
export default {
    mixins: [formMixin, dialogMixin, dataMixin, createPriceStaffMixin],
    props: {
        currentChapterData: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            clonePubTask: null, // 编辑时，保存一份发布平台数据，定时发送时做逻辑处理
            timer: [],
            lastChaterName: '',
            showDialog: false,
            fullDialogImage: '',
            isLoading: false,
            maxlength: 200,
            publicClass: [],
            type: 'add', // 编辑类型: 0 view, 1 edit, 2 add
            platformList: [], // { platformId: null, publishTime: null, name: '' }
            agreement: true, // 同意协议
            isChange: false, // 是否编辑
            isSubmit: false, // 是否提交
            prevChapterId: null, // 多章节保存中的id暂存
            submitIds: [], // 多章节编辑模式中，已保存的章节ids
            comicInfo: {},
            originPlatformList: [], // 主体发布平台列表
            allPlat: {
                checked: false,
                isTiming: null,
                publishTime: null
            },
            chapterData: {
                chapterId: null,
                isBatch: false,
                comicId: null,
                comicName: null,
                authorName: null,
                chapterName: null,
                chapterSN: null,
                chapterPriceStatus: 0,
                chapterPrice: null,
                imageAddr: [],
                diamondNum: 0,
                imageArray: [],
                gridNum: 0,
                coverObj: null,
                createPrice: 0,
                chapterType: 1,
                is_over: 0,
                isVip: 0, // 会员专属
                // 章节看点
                intro: {
                    content: null,
                    fontColor: null,
                    fontSize: 36,
                    borderSize: 6,
                    borderColor: null,
                    position: 1
                },
                // 发布平台
                publishTask: []
            },
            chapterDataRules: {
                chapterSN: [
                    { required: true, message: '请输入章节编号', trigger: 'blur' },
                    { min: 0, max: 30, message: '最多30个字符', trigger: 'blur' }
                ],
                chapterName: [
                    { max: 40, message: '最多40个字符', trigger: 'blur' }
                ],
                chapterPrice: [
                    { required: true, validator: this.validChapterPrice, trigger: 'blur' }
                ],
                createPrice: [
                    { required: true, message: '创作价格不能为空', trigger: 'blur' }
                ],
                imageArray: [
                    { required: true, message: '请上传章节图片', trigger: 'change' }
                ],
                // chapterCover168: [
                //     { required: true, message: '请上传章节封面', trigger: 'blur' }
                // ],
                publishTask: [
                    { required: true, validator: this.validPublishTask, trigger: 'blur' }
                ]
            },
            pickerDisabled: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - TIMING_PUBLISH;
                }
            },
            chapterTypeOptions: [
                {
                    value: 1,
                    label: '连载'
                },
                {
                    value: 2,
                    label: '番外'
                }
            ]
        };
    },
    computed: {
        ...mapState('app', ['userInfo']),
        isAutoCut() {
            const flag = this.userInfo.level_extra.indexOf('4') > -1;
            return flag;
        }
    },
    watch: {
        currentChapterData() {
            this.initQuery();
        },
        chapterData: {
            deep: true,
            handler() {
                if (this.type === 'edit') this.isChange = this.checkEditChange();
                else if (this.type === 'add') this.isChange = this.checkAddChange();
            }
        }
    },
    beforeCreate() {
        this.$emit('update:active', 2);
    },
    destroyed() {
        this.$root.$off('fullscreen');
        this.stopRefreshGreenImgProcessData();
    },
    async created() {
        this.initQuery();
        await this.initData();
        this.autoRefreshGreenImgProcessData();
        this.$root.$on('fullscreen', this.fullscreenHandle);
        this.$root.$on('previewChapterContent', this.previewChapterContent);
    },
    methods: {
        setVip() {
            this.chapterData.isVip = this.chapterData.chapterType === 2 ? this.chapterData.isVip : 0;
        },
        handleUploaded() {
            setTimeout(() => {
                this.autoRefreshGreenImgProcessData();
            }, 2000);
        },
        stopRefreshGreenImgProcessData() {
            for (let item of this.timer) {
                clearTimeout(item);
            }
        },
        // 判断是否需要开启自动刷新 [排除greenId为零的数据]
        checkAutoRefresh() {
            if (this.chapterData && this.chapterData.imageArray.length) {
                let list = this.chapterData.imageArray.filter(item => {
                    return item.greenId !== 0;
                });
                for (let item of list) {
                    if (![3, 4].includes(item.greenStatus)) {
                        return true;
                    }
                }
            }
            let coverObj = this.chapterData.coverObj;
            if (coverObj && coverObj.greenId === 0) return;
            if (coverObj && ![3, 4].includes(coverObj.greenStatus)) {
                return true;
            }
        },
        // 开启自动刷新鉴黄进度
        autoRefreshGreenImgProcessData() {
            if (!this.checkAutoRefresh()) {
                return;
            }
            this.stopRefreshGreenImgProcessData();
            this.pullGreenImgProcessData().finally(() => {
                this.timer.push(setTimeout(() => {
                    this.autoRefreshGreenImgProcessData();
                }, 3000));
            });
        },
        // 获取最新鉴黄进度
        async pullGreenImgProcessData() {
            const { comicId } = this.$route.query;
            let ids = this.chapterData.imageArray.filter(item => {
                // 过滤id为0的数据
                return item.greenId !== 0 && ![3, 4].includes(item.greenStatus);
            });
            ids = ids.map(item => {
                return item.greenId;
            });
            let coverObj = this.chapterData.coverObj;
            if (coverObj && coverObj.greenId !== 0) {
                ids.push(coverObj.greenId);
            }
            if (!ids.length) return;
            return this.$api('getGreenImageProcess', { comicId, greenIds: ids.join(',') }).then(res => {
                let list = res && res.data;
                if (Array.isArray(list)) {
                    list.map(item => {
                        this.chapterData.imageArray.map((subItem, index) => {
                            if (item.greenId === subItem.greenId) {
                                this.chapterData.imageArray.splice(index, 1, item);
                            }
                        });
                        if (this.chapterData.coverObj && item.greenId === this.chapterData.coverObj.greenId) {
                            this.chapterData.coverObj.greenStatus = item.greenStatus;
                        }
                    });
                }
            });
        },
        // 统计黄图
        getYellowImgCount() {
            let count = 0;
            count = this.chapterData.imageArray.filter(item => {
                return item.greenStatus === 3;
            }).length;
            if (this.chapterData.coverObj && this.chapterData.coverObj.greenStatus === 3) {
                count++;
            }
            return count;
        },
        handleDatePickAllChange() {
            // “全部选项”功能只针对新增章节
            if (new Date(this.allPlat.publishTime).getTime() < Date.now()) {
                this.$message.error('只能选择当前时间以后的时间');
                this.allPlat.publishTime = null
            }
            this.syncSelectAll();
        },
        syncSelectAll() {
            if (this.allPlat.checked) {
                const isTiming = this.allPlat.isTiming;
                let publishTime = this.allPlat.publishTime;
                if(!publishTime) {
                    publishTime = this.allPlat.publishTime = timeFormat(Date.now() + TIMING_PUBLISH, 'yyyy-MM-dd hh:mm:ss');

                }
                this.chapterData.publishTask = this.platformList;
                this.chapterData.publishTask.map((item) => {
                    item.isTiming = isTiming;
                    item.publishTime = publishTime;
                });
            } else {
                this.chapterData.publishTask = [];
            }
        },
        syncSelectRadio() {
            const publishTask = this.chapterData.publishTask;
            this.defaultSelect(publishTask);
        },
        handleSelectAll() {
            if (!this.allPlat.isTiming) {
                this.allPlat.isTiming = 0;
            }
            this.syncSelectAll();
        },
        handleChangeSelect(value) {
            this.allPlat.checked = value.length === this.platformList.length;
        },
        // 初始化传递参数
        async initQuery() {
            this.resetDataField('chapterData');
            const { type = 'add', comicId, comicName, authorName, chapterId = null, uploaderUid, comicType } = this.$route.query;
            this.type = type;
            this.comicType = parseInt(comicType, 10);
            if (!this.$route.query.single) {
                this.chapterData = {
                    ...this.chapterData,
                    comicId,
                    comicName,
                    authorName,
                    chapterId,
                    uploaderUid,
                    ...this.currentChapterData
                };
            } else {
                this.chapterData = {
                    ...this.chapterData,
                    ...this.currentChapterData,
                    comicId,
                    comicName,
                    authorName,
                    chapterId,
                    uploaderUid
                };
            }
            // 获取章节数
            this.$api('getLastChaptersn', { comicId }).then(res => {
                this.lastChaterName = res.data;
            });

            this.chapterData.intro.fontColor = this.formatColor(this.chapterData.intro.fontColor) || '#ffffff';
            this.chapterData.intro.borderColor = this.formatColor(this.chapterData.intro.borderColor) || '#342065';
            if (this.type === 'add') {
                this.isSubmit = false;
                this.chapterData.sourceObj = this.$utils.cloneDeep(this.chapterData);
                this.originData = this.$utils.cloneDeep(this.chapterData);
                if (this.chapterData.type) this.type = this.chapterData.type;
                if (!this.$route.query.single && this.currentChapterData.chapterId) {
                    this.initData();
                    this.isSubmit = true;
                }
                await this.getComicInfo();
            }
        },
        // 初始化数据
        async initData() {
            await this.getComicPlatformList();
            await this.getComicChapterData();
        },
        // 获取漫画详情
        getComicInfo() {
            return this.$api('getComicInfo', { comicId: this.$route.query.comicId }).then(res => {
                this.comicInfo = res.data || {};
                this.chapterData.createPrice = this.comicInfo.createPrice;
                // if (res.data.lastChapterPrice) {
                //     this.chapterData.chapterPriceStatus = 1;
                //     this.chapterData.chapterPrice = res.data.lastChapterPrice;
                // }
                if (res.data.chapterPrice !== 0) {
                    this.chapterData.chapterPriceStatus = 1;
                    this.chapterData.chapterPrice = res.data.chapterPrice;
                }
            });
        },
        // 查看放大图
        fullscreenHandle(src) {
            if (!src) return;
            this.showDialog = true;
            this.fullDialogImage = src;
        },
        // 预览所有上传的章节内容图片
        previewChapterContent(list) {
            this.changeDialog(true, '预览已上传章节内容', 0, { dialogName: 'previewChapterContent' });
        },
        // date-picker选择
        datePickerChange(platform) {
            if (new Date(platform.publishTime).getTime() < Date.now()) {
                this.$message.error('只能选择当前时间以后的时间');
                platform.publishTime = null;
                return;
            }

            const origin = this.getOriginPlatform(platform);
            if (origin.publishTime !== null &&
                new Date(platform.publishTime).getTime() < new Date(origin.publishTime).getTime()) {
                const time = timeFormat(origin.publishTime, 'yyyy-MM-dd hh:mm:ss');
                this.$message.error({
                    message: `时间必须大于等于渠道发布时间：${time}`,
                    duration: 5000
                });
                platform.publishTime = null;
            }
        },
        // 获取漫画平台列表
        getComicPlatformList() {
            return this.$api('getComicCheckedPlatformList', { comicId: this.$route.query.comicId }).then(({ data }) => {
                this.originPlatformList = data;
                this.platformList = data.map(platform => ({platformId: platform.platformId, publishTime: null, isTiming: null, name: platform.platformName}));
            });
        },
        // 获取漫画章节数据
        getComicChapterData() {
            if (this.type === 'add') return;
            const { comicId, chapterId } = this.chapterData;
            return this.$api('getComicChapterInfo', { comicId, chapterId }).then(({ data }) => {
                if (this.$utils.isEmptyObject(data)) {
                    this.$message.error('当前章节不存在！');
                    return this.$router.back();
                }
                const __publishTask = data.publishTask.map(task => {
                    return this.platformList.filter(platform => {
                        if (platform.platformId === task.platformId) {
                            platform.publishTime = task.publishTime;
                            platform.isTiming = task.isTiming;
                            return true;
                        }
                    })[0];
                });
                const _publishTask = __publishTask.filter((task)=>{
                    return !!task
                });
                data.publishTask = _publishTask;

                this.defaultSelect(_publishTask);
                this.clonePubTask = JSON.parse(JSON.stringify(_publishTask));
                data.chapterPriceStatus = data.chapterPrice !==0 ? 1 : 0;
                data.intro.fontColor = this.formatColor(data.intro.fontColor);
                data.intro.borderColor = this.formatColor(data.intro.borderColor);
                this.chapterData = { ...this.chapterData, ...data, coverObj: { greenId: data.coverGreenId, imgUrl: data.chapterCover168, greenStatus: data.coverGreenStatus }, sourceObj: { ...this.$utils.cloneDeep({ ...this.chapterData, ...data, coverObj: { greenId: data.coverGreenId, imgUrl: data.chapterCover168, greenStatus: data.coverGreenStatus } }) } };
            });
        },
        // 格式化字体、描边颜色
        formatColor(value) {
            if (!value) return value;
            if (/^#/.test(value)) return value;
            else return `#${value}`;
        },
        // 验证发布平台
        validPublishTask(rule, value, callback) {
            if (!value.length) return callback(new Error('请选择发布平台'));

            if(this.type === 'add') {
                value.forEach(item => {
                    if(item.isTiming === 1) {
                        if(new Date(item.publishTime).getTime() < Date.now()) {
                            return callback(new Error('只能选择当前时间以后的时间'));
                        }
                    }
                })
            }
            callback();
        },
        // 验证章节价格
        validChapterPrice(rule, value, callback) {
            if (this.chapterData.chapterPriceStatus === 1 && !this.chapterData.chapterPrice) callback(new Error('请输入章节价格'));
            else if (this.chapterData.chapterPriceStatus === 0 && this.chapterData.chapterPrice) this.chapterData.chapterPrice = null;
            else callback();
        },
        // 同意协议
        agreementHandle() {
            this.agreement = true;
            this.dialogCloseHandle();
        },
        // 检查是否做了修改
        checkEditChange() {
            const chapterDataClone = this.$utils.cloneDeep(this.chapterData);
            const sourceObjClone = this.$utils.cloneDeep(chapterDataClone.sourceObj);
            Reflect.deleteProperty(chapterDataClone, 'sourceObj');
            if (JSON.stringify(chapterDataClone) === JSON.stringify(sourceObjClone)) return false;
            else return true;
        },
        // 检查新增数据是否修改
        checkAddChange() {
            if (JSON.stringify(this.chapterData) === JSON.stringify(this.originData)) return false;
            else return true;
        },
        // 取消, 1、取消表单验证    2、如果是add清空数据，如果是view/edit还原数据
        cancelHandle(formName) {
            this.clearFormValidate(formName);
            this.$refs.batchUpload.clearFirstImgRaw()
            if (this.type === 'add') {
                this.resetDataField('chapterData');
                // 清除全选
                this.allPlat.checked = false;
            } else {
                this.initData();
            }
        },
        // 提交审核
        async submitFormHandle(actionType) {
            // 如果漫画主体的平台被修改了，章节的平台需要清空
            this.chapterData.publishTask = this.chapterData.publishTask.reduce((arr, value) => {
                const timeStr = timeFormat(new Date(), 'yyyy-MM-dd hh:mm:ss');
                if (!value.isTiming && !this.clonePubTask) {
                    value.publishTime = timeStr;
                } else if (!value.isTiming && this.clonePubTask) {
                    const re = this.clonePubTask.find(item => item.platformId === value.platformId);
                    if (re && re.isTiming) {
                        value.publishTime = timeStr;
                    } else {
                        value.publishTime = !re ? timeStr : re.publishTime;
                    }
                }
                if (value) arr.push(value);
                return arr;
            }, []);
            const content = this.chapterData.intro.content;
            if ( content && content.length < 10 ) {
                this.message = this.$message.error('看点内容字数过少或者超出!');
                return;
            }
            if (this.chapterData.publishTask.length === 0) {
                this.message = this.$message.error('请选择发布平台!');
                return;
            }
            const valid = await this.validateForm('chapterForm');
            if (!valid) {
                this.message && this.message.close();
                this.message = this.$message.error('章节信息填写有误!');
                return false;
            } else if (!this.agreement) {
                this.$message.error('请阅读并同意用户协议');
                return false;
            } else if (!this.isChange) {
                this.$message.error('章节未做修改');
                return false;
            }

            let url;
            if (this.type === 'add') url = 'addComicChapter';
            else if (this.type === 'edit') url = 'editComicChapter';

            // 章节内容图在上传过程中不能提交
            if (!this.$refs.batchUpload.uploadData.isEnd) return this.$message.error('请等待章节图片上传完成后提交!');
            // 保存前获取最新鉴黄进度
            await this.pullGreenImgProcessData().finally((res) => {
                let count = this.chapterData.imageArray.length;
                this.$utils.confirm('', `请确认保存页面中的所有内容，图片总数(不含章节图)：${count}张, 黄色图片: ${this.getYellowImgCount()}张`, '保存中...', (action, instance, done) => {
                    this.isLoading = true;
                    done();
                    const postData = this.$utils.cloneDeep(this.chapterData);

                    postData.imageArray = postData.imageArray.map(item => {
                        return {
                            greenId: item.greenId,
                            imgUrl: item.imgUrl
                        };
                    });
                    if (postData.coverObj) {
                        if (postData.coverObj.imgUrl) {
                            delete postData.coverObj.greenStatus;
                        } else {
                            delete postData.coverObj;
                        }
                    }

                    postData.gridNum = postData.gridNum || 0;
                    return this.$api(url, postData).then(res => {
                        this.isLoading = false;
                        this.isSubmit = true;
                        this.prevChapterId = res.data.chapterId;
                        this.$message.success({
                            message: '提交成功！',
                            duration: '1000',
                            onClose: async () => {
                                const { type, comicName, comicId, uploaderUid, authorName, single, tabType } = this.$route.query;
                                // 如果为已有的漫画章节编辑页面，就不用跳转，否则刷新数据
                                if (this.$route.query.tabType) {
                                    if (actionType === 'next' && res.data === 0) {
                                        return this.$message.warning({
                                            message: '已经是最后一个章节',
                                            duration: 1000,
                                            onClose: () => {
                                                this.$router.back();
                                            }
                                        });
                                    }
                                    if (actionType === 'next') {
                                        const {href} = this.$router.resolve({ name: 'editComicChapter', query: { type, comicName, comicId, uploaderUid, chapterId: res.data, authorName, tabType, single } });
                                        window.location.replace(href);
                                        return;
                                    }
                                    this.$router.back();
                                    // 多章节编辑
                                } else if (!this.$route.query.single && this.type === 'add') { // 只有单章节新增、编辑才能进行跳转操作，多章节新增不操作
                                    // this.type = 'edit';
                                    this.chapterData.chapterId = res.data.chapterId;
                                    // 存储已经提交的章节的chapterId
                                    if (!this.submitIds.includes(res.data.chapterId)) this.submitIds.push(res.data.chapterId);
                                    // 检查是否全部多章节都提交完了
                                    if (this.$parent.pagination.total === this.submitIds.length) {
                                        this.$emit('update:active', 3);
                                        this.$router.replace({ name: 'addPublish', query: { type, comicName, comicId, uploaderUid, authorName } });
                                    } else {
                                        // 未提交完也不是最后一页
                                        if (this.$parent.$refs.pagination.currentPage !== this.$parent.$refs.pagination.total) {
                                            this.$parent.$refs.pagination.next();
                                            document.querySelector('.edit-chapter form').scrollTop = 0;
                                        }
                                    }
                                } else {
                                    if (actionType === 'next') {
                                        const {href} = this.$router.resolve({ name: 'editChapter', query: { comicId, comicName, uploaderUid, authorName, type: 'add', single } });
                                        window.location.replace(href);
                                        return;
                                    }
                                    this.$emit('update:active', 3);
                                    this.$router.replace({ name: 'addPublish', query: { type, comicName, comicId, uploaderUid, authorName } });
                                }
                            }
                        });
                    }).catch(er => { this.isLoading = false; });
                });
            });
        },
        // 获取发布平台主体信息
        getOriginPlatform(platform) {
            return this.originPlatformList.find(item => item.platformId === platform.platformId);
        },
        defaultTime(platform) { // 设置平台发布默认时间(小时)
            let defaultVal = '08:00:00';
            let time;
            if(platform) {
                const origin = this.getOriginPlatform(platform);
                time = origin.publishTime
            }
            if (time) {
                const dateTime = new Date(time);
                defaultVal = dateTime.getHours() + ':' + dateTime.getMinutes() + ':' + dateTime.getSeconds();
            }
            return defaultVal;
        },
        defaultSelect(publishTask) {
            // 指定默认全选发布状态
            const publishTaskLen = publishTask.length;
            if(publishTaskLen === this.platformList.length){
                this.allPlat.checked = true;
                let isTiming = 0
                let len = 0 // 选择为0的长度
                publishTask.forEach(item => {
                    if(item && item.isTiming) {
                        len += 1
                    }
                })
                if(len < publishTaskLen && len > 0) {
                    isTiming = -1 // 代表不选择
                } else if(len === publishTaskLen) {
                    isTiming = 1
                }
                this.allPlat.isTiming = isTiming
            }
        }
    },
    components: {
        agreement,
        batchUpload,
        batchUploadAutoCut,
        chapterFocus,
        chapterCover,
        previewChapterContent
    }
};
</script>
<style lang="scss">
    @import "../../../../styles/var";
    .edit-chapter{
        font-size: 12px;
        height: 100%;
        .el-form{
            flex-grow: 1;
            padding: 0 10px 0 0;
            overflow-x: hidden;
            overflow-y: auto;

            .autoResize{
                overflow: hidden;
                align-items: center;
            }
        }
        .search-box-message{
            font-size: 12px;
            color: $color-info;
            i{
                margin-right: 5px;
            }
        }
        &-textarea {
            position: relative;
            textarea{
                padding-bottom: 20px;
            }
            &-length {
                position: absolute;
                bottom: 5px;
                left: calc(100% + 10px);
                line-height: 1;
                color: #999;
            }
        }
        &-tips{
            font-size: 12px;
            color: #909399;
        }
        &-theme.el-checkbox{
            margin-left: 0;
            margin-right: 30px;
        }
        &-tips{
            >div{
                font-size: 12px;
                color: #999;
            }
        }
        &-platform{
            .el-checkbox-group{
                padding: 10px 0;
                .el-checkbox{
                    width: 130px;
                    margin-right: 10px;
                }
                .el-date-editor{
                    margin-left: 10px;
                }
            }
        }
        .btns{
            margin-bottom: 10px;
        }
    }
    .fulldialog{
        .is-fullscreen{
            background: rgba(255, 255,255,0);
        }
        .el-dialog__header{
            border: none;
        }
        .el-dialog__body{
            height: calc(100% - 30px);
        }
        i.el-dialog__close{
            color: #ffff;
        }
        &-content{
            width: 700px;
            height: 375px;
            margin: 0 auto;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: relative;
        }
        &-focus{
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            font-size: 36px;
            border-width: 6px;
            padding: 0 15px;
            z-index: 999999;
            height: 42px;
            font-weight: bold;
        }
    }
</style>
