<template>
  <div class="comic-info">
    <el-form
      ref="comicForm"
      :model="comicData"
      :rules="editType === 'view' ? {} : comicDataRules"
      label-width="200px"
      element-loading-background="rgba(0, 0, 0, 0.75)"
    >
      <el-form-item label="漫画名称" prop="comicName">
        <el-col :span="12">
          <span v-if="editType === 'view'" class="comic-info-name">{{
            comicData.comicName
          }}</span>
          <template v-else>
            <el-input
              v-model="comicData.comicName"
              placeholder="请输入漫画名称，无需填写《》或其他符号"
            />
            <div class="search-box-message">
              <i class="el-icon-info"></i>请认真填写漫画名称，一经保存将不可修改
            </div>
          </template>
        </el-col>
      </el-form-item>
      <el-form-item label="漫画封面" prop="comicCover34">
        <div
          v-if="editType === 'view'"
          class="comic-info-cover-box"
          :style="{ width: '120px', height: '150px' }"
        >
          <img
            :key="
              tokenData.cdn + $utils.filterImagePath(comicData.comicCover34)
            "
            v-lazy="
              tokenData.cdn + $utils.filterImagePath(comicData.comicCover34)
            "
            class="comic-info-cover"
          />
        </div>
        <template v-else>
          <!-- <el-input v-model="comicData.comicCover34" :value="comicData.comicCover34" v-show="false"></el-input> -->
          <comicCoverUpload
            id="cover34"
            :drag="true"
            width="120px"
            height="160px"
            :accept="$config.acceptImg"
            :onChange="uploadChangeHandle"
          >
            <img
              v-if="comicData.comicCover34"
              :src="
                tokenData.cdn + $utils.filterImagePath(comicData.comicCover34)
              "
              class="comic-info-cover"
            />
            <!-- <img v-if="comicData.comicCover34" :key="tokenData.cdn + $utils.filterImagePath(comicData.comicCover34)" v-lazy="tokenData.cdn + $utils.filterImagePath(comicData.comicCover34)" class="comic-info-cover" /> -->
          </comicCoverUpload>
          <p class="comic-info-tips comic-info-cover-tips">
            建议封面尺寸为750*1000px,72dpi; 支持jpg，png; 大小2M以内
          </p>
        </template>
      </el-form-item>
      <el-form-item label="漫画封面" prop="comicCover169">
        <div
          v-if="editType === 'view'"
          class="comic-info-cover-box"
          :style="{ width: '160px', height: '90px' }"
        >
          <img
            :key="
              tokenData.cdn + $utils.filterImagePath(comicData.comicCover169)
            "
            v-lazy="
              tokenData.cdn + $utils.filterImagePath(comicData.comicCover169)
            "
            class="comic-info-cover"
          />
        </div>
        <template v-else>
          <!-- <el-input v-model="comicData.comicCover169" :value="comicData.comicCover169" v-show="false"></el-input> -->
          <!-- {{$config.cdn + comicData.comicCover34}} -->
          <comicCoverUpload
            id="cover169"
            :drag="true"
            width="200px"
            height="100px"
            :accept="$config.acceptImg"
            :onChange="uploadChangeHandle"
          >
            <img
              v-if="comicData.comicCover169"
              :src="
                tokenData.cdn + $utils.filterImagePath(comicData.comicCover169)
              "
              class="comic-info-cover"
            />
            <!-- <img v-if="comicData.comicCover169" :key="tokenData.cdn + $utils.filterImagePath(comicData.comicCover169)" v-lazy="tokenData.cdn + $utils.filterImagePath(comicData.comicCover169)" class="comic-info-cover" /> -->
          </comicCoverUpload>
          <p class="comic-info-tips comic-info-cover-tips">
            建议封面尺寸为800*400px,72dpi; 支持jpg，png; 大小2M以内
          </p>
        </template>
      </el-form-item>
      <el-form-item label="漫画封面" prop="comicCover43">
        <div
          v-if="editType === 'view'"
          class="comic-info-cover-box"
          :style="{ width: '160px', height: '120px' }"
        >
          <img
            :key="
              tokenData.cdn + $utils.filterImagePath(comicData.comicCover43)
            "
            v-lazy="
              tokenData.cdn + $utils.filterImagePath(comicData.comicCover43)
            "
            class="comic-info-cover"
          />
        </div>
        <template v-else>
          <comicCoverUpload
            id="cover43"
            :drag="true"
            width="160px"
            height="120px"
            :accept="$config.acceptImg"
            :onChange="uploadChangeHandle"
          >
            <img
              v-if="comicData.comicCover43"
              :src="
                tokenData.cdn + $utils.filterImagePath(comicData.comicCover43)
              "
              class="comic-info-cover"
            />
          </comicCoverUpload>
          <p class="comic-info-tips comic-info-cover-tips">
            建议封面尺寸为1000*750,比例为4：3; 支持jpg，png; 大小2M以内
          </p>
        </template>
      </el-form-item>
      <el-form-item label="漫画作者" prop="authorName">
        <el-col :span="12">
          <template v-if="editType === 'view'">{{
            comicData.authorName
          }}</template>
          <el-input
            v-else
            v-model="comicData.authorName"
            placeholder="请输入作者笔名，多个作者请用空格间隔"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="作品介绍" prop="comicDesc">
        <el-col :span="12" class="comic-info-textarea">
          <template v-if="editType === 'view'">
            <el-tooltip
              class="item"
              effect="light"
              :content="comicData.comicDesc"
              placement="top-start"
            >
              <div class="width100 single-ellipsis">
                {{ comicData.comicDesc }}
              </div>
            </el-tooltip>
          </template>
          <template v-else>
            <el-input
              ref="textarea"
              type="textarea"
              v-model="comicData.comicDesc"
              :autosize="{ minRows: 4, maxRows: 6 }"
              placeholder="请输入关于作品的描述"
              :maxlength="maxlength"
            ></el-input>
            <div class="comic-info-textarea-length">
              {{ inputTxtLength }}/{{ maxlength }}
            </div>
          </template>
        </el-col>
      </el-form-item>
      <el-form-item label="价格" prop="chapterPrice">
        <el-col :span="12">
          <span v-if="editType === 'view'" class="comic-info-name">{{
            comicData.chapterPrice
          }}</span>
          <template v-else>
            <el-input
              v-model="comicData.chapterPrice"
              placeholder="请输入漫画价格"
            />
            <div class="search-box-message">
              <i class="el-icon-info"></i>请填写漫画价格，若免费填0
            </div>
          </template>
        </el-col>
      </el-form-item>
      <el-form-item label="漫画等级" prop="comicGrade">
        <el-select
          v-model="comicData.comicGrade"
          :disabled="gradeDisable"
          placeholder="请选择漫画等级"
        >
          <el-option
            v-for="item in grades"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <template v-if="publicClass.length">
        <el-form-item label="连载状态" prop="serial">
          <template v-if="editType === 'view'">
            <template
              v-for="item in publicClass[0].child"
              v-if="item.id === comicData.serial"
              >{{ item.name }}</template
            >
          </template>
          <el-radio-group v-else v-model="comicData.serial">
            <el-radio
              v-for="item in publicClass[0].child"
              :label="item.id"
              :key="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="漫画类型" prop="comicType">
          <template v-if="editType === 'view'">
            <template
              v-for="item in publicClass[1].child"
              v-if="item.id === comicData.comicType"
              >{{ item.name }}</template
            >
          </template>
          <el-radio-group v-else v-model="comicData.comicType">
            <el-radio
              :label="item.id"
              :key="item.id"
              v-for="item in publicClass[1].child"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="受众" prop="audience">
          <template v-if="editType === 'view'">
            <template
              v-for="item in publicClass[2].child"
              v-if="item.id === comicData.audience"
              >{{ item.name }}</template
            >
          </template>
          <el-radio-group v-else v-model="comicData.audience">
            <el-radio
              :label="item.id"
              :key="item.id"
              v-for="item in publicClass[2].child"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="题材" prop="theme">
          <el-col :span="16">
            <template v-if="editType === 'view'">
              <template
                v-for="item in publicClass[3].child"
                v-if="comicData.theme.includes(item.id)"
                >{{ item.name }}
              </template>
            </template>
            <template v-else>
              <el-checkbox-group v-model="comicData.theme">
                <el-checkbox
                  class="comic-info-theme"
                  :key="item.id"
                  v-for="item in publicClass[3].child"
                  :label="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
              <p class="comic-info-tips">必选，限选1--3项</p>
            </template>
          </el-col>
        </el-form-item>
      </template>
      <el-form-item label="创作价格" prop="createPrice">
        <el-row>
          <el-col :span="6">
            <el-input
              v-model="comicData.createPrice"
              v-acgn-number="2"
              placeholder="请输入创作价格，单位（每格/元）"
            />
          </el-col>
          <el-col :span="12" style="color: gray">
            &nbsp;<i class="el-icon-info"></i>创作价格包含作品的稿酬和人力成本
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="漫画看点" prop="comicFeature">
        <el-input
          v-model="comicData.comicFeature"
          placeholder="请输入漫画看点"
        />
      </el-form-item>
      <el-form-item label="更新规律" prop="updateCycle">
        <el-input
          v-model="comicData.updateCycle"
          placeholder="请输入更新规律"
        />
      </el-form-item>
      <el-form-item
        label="漫画标签"
        class="comic-info-platform"
        v-if="editType !== 'view'"
      >
        <el-button type="primary" size="mini" @click="editLabelHandler"
          >编辑标签</el-button
        >
      </el-form-item>
      <ul class="comic-info-wrap">
        <li
          class="comic-info-item"
          v-for="category in comicData.tag_list"
          :key="category.id"
        >
          <template v-if="category.tags && category.tags.length">
            <div class="comic-info-categroy" :title="category.category_name">
              {{ category.category_name }}
            </div>
            <div class="comic-info-tags">
              <el-tag
                :class="{
                  'comic-info-tags-item': true,
                  disabled: !tag.show_status,
                }"
                v-for="tag in category.tags"
                :key="tag.tag_id"
                :closable="editType !== 'view'"
                size="medium"
                :disable-transitions="false"
                @close="removeHandler(category, tag)"
              >
                <span
                  class="comic-info-tags-item-inner"
                  :title="tag.tag_name"
                  >{{ tag.tag_name }}</span
                >
              </el-tag>
            </div>
          </template>
        </li>
      </ul>
      <el-form-item label="全文使用字体" prop="fontsName">
        <el-input
          style="width: 300px"
          maxlength="50"
          v-model="comicData.fontsName"
          placeholder="请输入全文使用字体(1-50字)"
        />
      </el-form-item>
      <el-form-item label="字体是否有版权" prop="fontsCopyright">
        <el-radio v-model="comicData.fontsCopyright" :label="1"
          >有版权</el-radio
        >
        <el-radio v-model="comicData.fontsCopyright" :label="0"
          >无版权</el-radio
        >
      </el-form-item>
      <el-form-item
        label="发布平台："
        prop="platformList"
        class="comic-info-platform"
        v-if="editType !== 'edit'"
      >
        <template v-if="editType === 'add'">
          <el-checkbox-group class="flex-row" v-model="comicData.platformList">
            <el-checkbox
              v-for="platform in platformList"
              :key="platform.platformId"
              :label="platform"
              >{{ platform.name }}</el-checkbox
            >
          </el-checkbox-group>
        </template>
        <template v-if="editType === 'view'">
          <span
            v-for="platform in comicData.platforms"
            :key="platform.platformId"
            class="mr10"
            >{{ platform.platformName }}</span
          >
        </template>
      </el-form-item>
      <el-form-item v-if="!(editType === 'view')">
        <el-checkbox v-model="agreement">我同意</el-checkbox>
        <el-button
          type="text"
          @click.stop="changeDialog(true, '用户协议', 1, { name: 'agreement' })"
          size="medium"
          >中国漫画分发中心服务协议</el-button
        >
      </el-form-item>
      <!-- buttons -->
      <el-form-item v-if="!(editType === 'view')">
        <el-button type="primary" @click="submitFormHandle('comicForm')"
          >保存待审</el-button
        >
        <el-button @click="cancelHandle('comicForm')">取消</el-button>
      </el-form-item>
      <el-form-item v-else>
        <el-button type="primary" @click="editFormHandle">编辑信息</el-button>
      </el-form-item>
      <!-- buttons-end -->
    </el-form>
    <cropper
      title="封面图剪切"
      :visible.sync="cropperData.visible"
      :image="cropperData.image"
      :options="cropperData.options"
      @close="closeCropper"
      @getCropperImage="getCropperImage"
    ></cropper>
    <dialogPlus
      :visible.sync="dialogData.visible"
      :title.sync="dialogData.title"
      :width="dialogData.data.name === 'editTag' ? '90%' : '530px'"
      :model="dialogData.data"
      :heightFull="true"
    >
      <template v-if="dialogData.data.name === 'editTag'">
        <labelAE @cancelDialog="cancelDialog" @saved="savedHandler" />
      </template>
      <template v-else>
        <agreement />
        <div slot="footer">
          <el-button @click="dialogCloseHandle">取消</el-button>
          <el-button type="primary" @click="agreementHandle">同意</el-button>
        </div>
      </template>
    </dialogPlus>
  </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-17 14:58:30
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-17 14:58:30
 * @Description: 增加、修改漫画
 * @Company: 成都二次元动漫
 */
import { Grades } from '@/constants'
import agreement from '@/views/main/other/agreement'
import labelAE from '@/views/main/labelManager/components/labelAE'
import {
  formMixin,
  cropperMixin,
  uploadMixin,
  comicMixin,
  dialogMixin,
  dataMixin,
} from '@/mixins'
import { mapMutations } from 'vuex'

export default {
  mixins: [
    formMixin,
    cropperMixin,
    uploadMixin,
    dialogMixin,
    comicMixin,
    dataMixin,
  ],
  props: {
    comicInfo: Object,
    publicClassList: Array,
  },
  data() {
    return {
      grades: Grades,
      editType: 'add',
      maxlength: 200,
      currentUploadType: '',
      platformList: [],
      agreement: true, // 同意协议
      gradeDisable: false,
    }
  },
  computed: {
    inputTxtLength() {
      if (this.comicData.comicDesc) {
        return this.comicData.comicDesc.length
      } else {
        return 0
      }
    },
  },
  beforeCreate() {
    if (!this.$route.tabtype) this.$emit('update:active', 0)
  },
  created() {
    this.setBreadcrumb()
    this.getComicPlatformList()
    this.initData()
  },
  watch: {
    'comicData.comicGrade': function (val, oVal) {
      if (this.editType === 'edit' && val === 0) {
        // 编辑状态下，如果原漫画没有等级，则开放编辑
        this.comicData.comicGrade = null
        this.gradeDisable = false
      }
    },
  },
  methods: {
    ...mapMutations('comic', ['setTagList']),
    // 设置面包屑
    setBreadcrumb() {
      if (!this.comicInfo) {
        const { commit, state } = this.$store
        if (state.app.breadcrumb[2].name === 'addComic')
          state.app.breadcrumb.splice(2, 1)
        commit('app/setBreadcrumb', [...state.app.breadcrumb])
      }
    },
    // 初始化
    initData() {
      const { comicId = null, editType = 'add' } = this.$route.query
      this.editType = editType
      this.comicData.comicId = comicId
      this.$set(this.comicData, 'fontsCopyright', 1)
      this.gradeDisable = editType !== 'add'
      // 如果当作组件使用，有数据跳过请求
      if (this.comicInfo) {
        this.publicClass = this.publicClassList
        this.comicData = this.comicInfo
        this.comicDataClone = this.$utils.cloneDeep(this.comicInfo)
      } else if (['edit', 'view'].includes(this.editType)) {
        this.getAllData([
          this.getPublicClass(),
          this.getComicData(this.comicData.comicId),
        ])
      } else this.getAllData([this.getPublicClass()])
    },
    // 获取漫画平台列表
    getComicPlatformList() {
      return this.$api('getComicPlatformList').then(({ data }) => {
        this.platformList = data.map((platform) => ({
          platformId: platform.id,
          name: platform.name,
          status: 1,
        }))
      })
    },
    // 图片选择
    uploadChangeHandle(file, fileList, id) {
      const { uploaderUid = this.$store.state.app.userInfo.id } =
        this.$route.query
      // 获取token

      // 将选择图片放入剪切组件
      let options = {
        aspectRatio: 3 / 4,
        aspectWidth: 750,
        aspectHeight: 1000,
        minCropBoxHeight: 320,
        minPreviewWidth: 180,
        minPreviewHeight: 240,
      }

      // 第二个裁切组件配置
      if (id === 'cover169') {
        options = {
          ...options,
          aspectWidth: 800,
          aspectHeight: 400,
          aspectRatio: 2 / 1,
          minCropBoxWidth: 320,
          minPreviewWidth: 180,
          minPreviewHeight: 102,
        }
      } else if (id === 'cover43') {
        options = {
          ...options,
          aspectWidth: 1000,
          aspectHeight: 750,
          aspectRatio: 4 / 3,
          minCropBoxWidth: 320,
          minPreviewWidth: 240,
          minPreviewHeight: 180,
        }
      }
      this.currentUploadType = id

      this.validateImage(file, options)
        .then(() => {
          this.getComicCoverToken(file.name, uploaderUid)

          // 获取本地图片地址
          const reader = new FileReader()
          reader.readAsDataURL(file.raw)

          reader.onloadend = (e) => {
            this.showCropper(true, e.target.result, options)
          }
        })
        .catch(() => {})
    },
    validateImage(file, options) {
      return this.$utils.getImagePromise(file.raw).then((image) => {
        const { width, height } = image
        const { aspectWidth, aspectHeight } = options
        const isRt2M = file.size > 2 * 1024 * 1024
        if (isRt2M) {
          this.$notify.error({
            title: '图片错误',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<strong>名称：</strong>${file.name}<br /><strong>错误信息：</strong>请上传小于2M的图片`,
          })
          throw new Error('验证图片错误')
        }

        if (width !== aspectWidth || height !== aspectHeight) {
          this.$notify.error({
            title: '图片错误',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<strong>名称：</strong>${file.name}<br /><strong>错误信息：</strong>请上传指定尺寸的图片`,
          })
          throw new Error('验证图片错误')
        }
      })
    },
    // 获取裁剪图片内容
    getCropperImage(blob) {
      const that = this
      this.uploadHandle({ file: blob }).then(({ key }) => {
        if (that.currentUploadType === 'cover34')
          that.comicData.comicCover34 = `/${key}`
        else if (that.currentUploadType === 'cover169')
          that.comicData.comicCover169 = `/${key}`
        else if (that.currentUploadType === 'cover43')
          that.comicData.comicCover43 = `/${key}`
        that.closeCropper()
      })
    },
    // 同意协议
    agreementHandle() {
      this.agreement = true
      this.dialogCloseHandle()
    },
    // 编辑漫画信息
    editFormHandle() {
      this.editType = 'edit'
      this.$nextTick(() => this.clearFormValidate('comicForm')) // 清空校验结果
    },
    // 取消编辑
    cancelHandle(formName) {
      this.clearFormValidate(formName)
      const { editType } = this.$route.query
      if (this.editType === 'add') this.resetDataField('comicData')
      else {
        this.comicData = this.$utils.cloneDeep(this.comicDataClone)
        if (editType === 'edit') this.$router.back()
        if (editType === 'view') this.editType = 'view'
      }
    },
    // 提交审核
    async submitFormHandle() {
      const valid = await this.validateForm('comicForm')
      if (!valid) {
        this.message && this.message.close()
        this.message = this.$message.error('漫画信息填写有误!')
        return false
      }
      const tagIds = (this.comicData.tag_list || []).reduce((list, item) => {
        list = list.concat(item.tags.map((tag) => tag.tag_id))
        return list
      }, [])
      if (!tagIds.length) return this.$message.error('漫画标签为必填!')
      const {
        comicId,
        comicName,
        authorName,
        comicDesc,
        comicCover34,
        comicCover43,
        comicCover169,
        serial,
        comicType,
        audience,
        theme,
        platformList,
        sourceObj,
        uploaderUid,
        comicGrade,
        comicFeature,
        updateCycle,
        createPrice,
        fontsName,
        fontsCopyright,
        chapterPrice,
      } = this.comicData
      const classIdList = [serial, comicType, audience, ...theme]
        .sort((a, b) => a > b)
        .join()

      let url
      if (this.editType === 'add') url = 'addAndUpdateComic'
      else if (this.editType === 'edit') url = 'editAndUpdateComic'
      this.$utils.confirm(
        '',
        '确认提交待审核吗？',
        '提交中...',
        (action, instance, done) => {
          return this.$api(url, {
            comicId,
            comicName,
            authorName,
            comicDesc,
            comicCover34,
            comicCover43,
            comicCover169,
            classIdList,
            tagIds,
            sourceObj,
            platformList,
            comicGrade,
            comicFeature,
            updateCycle,
            createPrice,
            fontsName,
            fontsCopyright,
            chapterPrice: chapterPrice,
          }).then((res) => {
            this.$message.success({
              message: '提交成功！',
              duration: '1000',
              onClose: () => {
                // 如果为已有的漫画编辑页面，就不用跳转，否则刷新数据
                if (!this.$route.query.tabtype) {
                  this.$emit('update:active', 1)
                  if (this.editType === 'add') {
                    return this.$router.replace({
                      name: 'addChapter',
                      query: {
                        type: 'add',
                        comicName: res.data.comicName,
                        comicId: res.data.comicId,
                        authorName: res.data.authorName,
                        uploaderUid: res.data.uploaderUid,
                        tabtype: 1,
                      },
                    })
                  }
                  this.$router.replace({
                    name: 'addChapter',
                    query: {
                      type: 'add',
                      comicName,
                      comicId,
                      authorName,
                      uploaderUid,
                      tabtype: 1,
                    },
                  })
                } else this.$router.back()
              },
            })
          })
        }
      )
    },
    // 编辑标签
    editLabelHandler() {
      this.setTagList(this.comicData.tag_list || [])
      this.changeDialog(true, '漫画标签', 2, { name: 'editTag' })
    },
    // 移除操作
    removeHandler(category, tag) {
      this.$utils.confirm(
        '',
        `确定移出“${tag.tag_name}”标签吗？`,
        `移出中...`,
        (action, instance, done) => {
          category.tags.splice(category.tags.indexOf(tag), 1)
          // const submit = { comic_id: this.$route.query.comicId, tag_ids: [] };
          // if (this.tagList.length) {
          //     this.tagList.forEach(category => {
          //         category.tags.forEach(tag => submit.tag_ids.push(tag.tag_id));
          //     });
          // }
          this.$message({ message: '删除成功', type: 'success' })
          // return this.$api('saveComictags', submit).then(res => {
          //     this.$message({ message: '删除成功', type: 'success' });
          //     this.updateTagList();
          // });
        }
      )
    },
    // 取消
    cancelDialog() {
      this.setTagList(this.comicData.tag_list)
      this.dialogCloseHandle()
    },
    // 保存成功
    savedHandler(tagList = []) {
      this.setTagList(tagList)
      this.comicData.tag_list = tagList
      this.dialogCloseHandle()
    },
  },
  components: {
    agreement,
    labelAE,
  },
}
</script>
<style lang="scss">
@import '../../../../styles/var';
.comic-info {
  &-textarea {
    position: relative;
    textarea {
      padding-bottom: 20px;
    }
    &-length {
      position: absolute;
      bottom: 5px;
      left: calc(100% + 10px);
      line-height: 1;
      color: #999;
    }
  }
  &-tips {
    font-size: 12px;
    color: #909399;
  }
  &-theme.el-checkbox {
    margin-left: 0;
    margin-right: 30px;
  }
  &-cover {
    width: 100%;
    height: 100%;
  }
  &-platform {
    .el-checkbox-group {
      flex-flow: row wrap;
      .el-checkbox {
        margin-left: 0;
        margin-right: 30px;
      }
    }
  }

  &-wrap {
    padding: 10px 0;
    .comic-info-item {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      .comic-info-categroy {
        box-sizing: border-box;
        width: 200px;
        font-size: 14px;
        flex-basis: 200px;
        padding: 5px 12px 0 0;
        line-height: 30px;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .comic-info-tags {
        flex: 1 0;
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        &-item {
          &-inner {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            vertical-align: middle;
          }
          margin: 5px;
          &.disabled {
            background: #ccc;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
