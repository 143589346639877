<!--
 * @Author: daipeng
 * @Date: 2019-09-06 11:50:03
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-30 17:55:01
 * @Description:
 -->
<template>
    <div :class="['ancomic-card', 'flex-row', { isMouseOver: isMouseOver, 'unshelve': comicData.online_show_status === 0}]" @mouseover="isMouseOver = true" @mouseout="isMouseOver = false" style="min-width: 400px">
        <template v-if="showSelect">
            <div v-show="isMouseOver && !isSelected" class="select-box" @click="selectComicHandle(true)"></div>
            <div v-show="isSelected" class="select-box check" @click="selectComicHandle(false)"><i class="el-icon-check"></i></div>
        </template>
        <div class="ancomic-card-img" @click="previewComicInfo(comicData)">
            <img src="/static/space.gif" :key="comicData.animation_cover_domain + $utils.filterImagePath(comicData.animation_cover_3_4)" v-lazy="comicData.animation_cover_domain + $utils.filterImagePath(comicData.animation_cover_3_4)" alt="">
            <div v-if="comicData.online_show_status === 0">已下架</div>
        </div>
        <dl class="ancomic-card-info">
            <dt class="flex-row-between">
                <div class="ancomic-card-title flex-row">
                    <el-tooltip class="item" effect="light" :content="comicData.animation_name" placement="top-start">
                        <span class="comic-name" :title="comicData.animation_name" style="max-width:90px;">{{comicData.animation_name}}</span>
                    </el-tooltip>
                    <el-button class="comic-btn-edit" type="text" size="mini" plains @click="editComicHandle(comicData)" :disabled="comicData.online_show_status === 0" icon="el-icon-edit">编辑动态漫</el-button>
                </div>
                <div :class="comicData.audit_status | auditAnStatusStyleFilter">
                    <!-- <span v-if="comicData.displayStatus != 2" class="mr10" :style="{color: comicData.displayStatus ? '#67c23a' : 'red'}">{{comicData.displayStatus === 0 ? '下架中' : '上架中'}}</span>
                    <span v-if="comicData.online_show_status === -1" class="mr10" style="color:#67c23a">未上架</span>
                    <span v-else class="mr10" :style="{color: comicData.online_show_status ? '#67c23a' : 'red'}">{{comicData.online_show_status === 0 ? '已下架' : '已上架'}}</span> -->
                    {{comicData.audit_status | auditAnStatusFilter}}
                    <el-tooltip effect="light" v-if="[-1, 3, 4].includes(comicData.audit_status)" placement="top">
                        <div slot="content">{{comicData.auditRemark || (comicData.audit_status === 5 ? '未通过' : '已通过')}}</div>
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </div>
            </dt>
            <dd>
                <span class="comic-dispatch-label">最新发布时间：</span>{{comicData.last_publish_time | timeFilter('yyyy年MM月dd日 hh:mm')}}
            </dd>
            <dd>
                <span class="comic-dispatch-label">最新章节：</span>
                <span v-if="comicData.last_chapter_title" class="comic-dispatch-content">
                    {{comicData.last_chapter_title | chapterNameFilter}}
                </span>
                <span v-else class="comic-dispatch-content">
                    还没更新~
                </span>
                <el-button type="text" size="mini" plains @click="addChapter(comicData)" :disabled="comicData.online_show_status === 0">新增章节</el-button>
            </dd>
            <dd class="ancomic-card-btns">
                <el-button size="mini" plains type="primary" :disabled="comicData.online_show_status === 0" @click="managerChapter(comicData)">管理章节</el-button>
                <!-- 状态1、3 -> 待提交、未通过 显示提交按钮审核 -->
                <el-button v-if="[1,3,5].includes(comicData.audit_status)" size="mini" plains type="primary" @click="submitAudit(comicData)">提交审核</el-button>
                <el-button v-if="comicData.correlation" :disabled="comicData.online_show_status === 0" plain size="mini" plains type="primary" @click="goToRelatedVideo(comicData)">相关视频</el-button>
                <el-button v-if="couldRemoveComic" :disabled="comicData.online_show_status === 0" size="mini" type="warning" @click="removeComicHandle">删除</el-button>
            </dd>
        </dl>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-14 09:55:44
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-14 09:56:05
 * @Description: 动态漫卡片
 * @Company: 成都二次元动漫
 */
import { mapState } from 'vuex';
export default {
    props: {
        comicData: {
            type: Object,
            default: () => {}
        },
        showSelect: { type: Boolean, default: false },
        // table list
        list: {
            type: Array,
            required: false,
            default: () => []
        },
        selectComicList: {
            type: Array,
            required: false,
            default: () => []
        },
        index: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            isMouseOver: false
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        }),
        isSelected() {
            return this.selectComicList.includes(this.comicData);
        },
        couldRemoveComic() {
            // 普通CP账号 审核中的动态漫隐藏“删除”按钮
            if (this.level === 1 && [4, 2].includes(this.comicData.audit_status)) {
                return false;
            }
            return this.comicData.allow_delete === 1;
        }
    },
    methods: {
        // 前往相关视频列表页
        goToRelatedVideo({ animation_id }) {
            this.$router.push({
                name: 'anComicRelatedVideoList',
                query: { animation_id }
            });
        },
        // 选择动态漫
        selectComicHandle(isSelected) {
            const that = this;
            if (isSelected) this.$emit('update:selectComicList', [...this.selectComicList, this.comicData]);
            else {
                const selectList = this.selectComicList.reduce((arr, obj) => {
                    if (obj !== that.comicData) arr.push(obj);
                    return arr;
                }, []);
                this.$emit('update:selectComicList', selectList);
            }
        },
        // 查看动态漫详情
        previewComicInfo({animation_id, animation_name, authorName, uploaderUid, online_show_status}) {
            // 如果下架中 则不能进入详情
            if (online_show_status === 0) {
                return;
            }
            this.$router.push({ name: 'editAnComic', query: { animation_id, animation_name, authorName, uploaderUid, editType: 'view', tabtype: 1, single: true } });
        },
        // 编辑动态漫
        editComicHandle({animation_id, animation_name, authorName, uploaderUid}) {
            this.$router.push({ name: 'editAnComic', query: { animation_id, animation_name, authorName, uploaderUid, editType: 'edit', tabtype: 1 } });
        },
        // 新增章节
        addChapter({animation_id, animation_name, authorName, uploaderUid, chapter_price}) {
            this.$router.push({ name: 'editAnChapter', query: { animation_id, animation_name, authorName, uploaderUid, editType: 'add', chapter_price } });
        },
        // 章节管理
        managerChapter({animation_id, animation_name, authorName, uploaderUid}) {
            this.$router.push({ name: 'editAnComic', query: { animation_id, animation_name, authorName, uploaderUid, editType: 'edit', tabtype: 2 } });
        },
        // 提交审核
        submitAudit({ animation_id }) {
            this.$utils.confirm('', '确认提交审核吗？', '提交中...', (action, instance, done) => {
                return this.$api('submitAnAudit', { animation_id }).then(res => {
                    // 更新当前动态漫卡片，因为卡片是数组项，所以需要更新数组
                    let _data = this.$utils.cloneDeep(this.list);
                    _data.splice(this.index, 1, { ...this.comicData, audit_status: 2 });
                    this.$emit('update:list', _data);
                });
            });
        },
        // 删除动态漫
        removeComicHandle() {
            const { animation_id } = this.comicData;
            this.$utils.confirm('', '确定删除该动态漫吗？', '删除中...', (action, instance, done) => {
                return this.$api('removeAnComic', { animation_id }).then(res => {
                    this.$parent.getTableList();
                });
            });
        }
    }
};
</script>
<style lang="scss">
    .ancomic-card{
        position: relative;
        width: calc(50% - 10px);
        margin-right: 20px;
        width: 560px;
        height: 160px;
        background-color: #fff;
        border: 1px solid #e4e7ea;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 2px;
        margin-bottom: 20px;
        &.unshelve {
           .ancomic-card-img {
             filter: grayscale(1);
             > div {
               position: absolute;
               bottom: 0;
               left: 0;
               right: 0;
               height: 20px;
               line-height: 20px;
               background-color: rgba(255,255,255, .7);
               text-align: center;
               color: #666;
             }
           }
        }
        &.isMouseOver{
            box-shadow: 0px 0px 20px rgba(102, 102, 102, 0.35);
        }
        &-img{
            position: relative;
            width: 140px;
            height: 100%;
            background-color: #e4e7ea;
            border-right: 1px solid #e4e7ea;
            cursor: pointer;
            img{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
        &-info{
            padding: 10px;
            flex-grow: 1;
            font-size: 14px;
            dt, dd {
                height: 30px;
                line-height: 30px;
                color: #606266;
            }
            .comic-name{
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &-title{
            align-items: center;
        }
        &-btns{
            font-size: 0;
            margin: 10px 0 0 0;
            .el-button{
                padding: 7px 10px;
            }
        }
        .comic-btn-edit{
            font-size: 12px;
            margin-left: 10px;
        }
        .select-box{
            position: absolute;
            top: -1px;
            left: -1px;
            width: 40px;
            height: 40px;
            z-index: 50;
            cursor: pointer;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid rgba(233, 233, 233, 1);
            &.check{
                background: rgba(24, 144, 255, 1);
                border: none;
                i{
                    color: #fff;
                    font-size: 24px;
                    margin: 8px;
                }
            }
        }
    }
</style>
