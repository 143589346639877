import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState('app', {
            userInfo: state => state.userInfo // 1：用户 2：管理 3：超管 4:一审人员
        })
    },
    methods: {
        // 控制审核相关操作显隐
        hasAuditBtn(comicData, level) {
            if (level === 4) {
                return comicData.audit_status < 4;
            } else if (level === 2) {
                return comicData.audit_status >= 4;
            }
        },
        // 检查权限
        checkAuth(api) {
            let authList = this.userInfo.routePermissions;
            let item = authList.find(function(item) {
                return item.code === api;
            });
            return !!item;
        }
    }
};
