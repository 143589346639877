<template>
    <div class="audit-main flex-column">
        <el-row :gutter="20" class="audit-main-option flex-row-between">
            <el-col :span="10">
                <span v-if="comicData.operate_time" class="audit-submit-time">提交时间： {{comicData.operate_time | timeFilter('yyyy年MM月dd日 hh:mm:ss')}}</span>
                <el-button :disabled="tableList.length !== 0" type="primary" size="mini" @click="forceAuditPass(comicData)">强制通过</el-button>
            </el-col>
            <el-col :span="14" align="right">
                <el-button
                    type="primary"
                    size="mini"
                    :disabled="checkDisablePassBtn"
                    @click="promptAudit('pass')"
                >通过</el-button>
                <el-button
                    type="danger"
                    size="mini"
                    :disabled="checkDisableNoPassBtn"
                    @click="promptAudit('reject')"
                >拒绝</el-button>
            </el-col>
        </el-row>
        <el-table
            :data="tableList"
            ref="table"
            v-loading="isloading"
            border
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" label="选择" align="center" width="50"></el-table-column>
            <el-table-column prop="team_name" label="动态漫团队"> </el-table-column>
            <el-table-column prop="edit_action" label="编辑行为"></el-table-column>
            <el-table-column width="160" prop="edit_details" label="编辑细节"><template slot-scope="scope">{{showDetails(scope.row.edit_details)}}</template></el-table-column>
             <el-table-column label="视频封面图" prop="correlation_cover_16_8" align="center" width="160">
                <template slot-scope="scope">
                    <img-preview :src="cover_https_domain + $utils.filterImagePath(scope.row.correlation_cover_16_8)">
                       <img
                            width="76px"
                            height="38px"
                            :key="scope.row.chapter_cover_domain + $utils.filterImagePath(scope.row.correlation_cover_16_8)"
                            v-lazy="cover_https_domain + $utils.filterImagePath(scope.row.correlation_cover_16_8)"
                        />
                    </img-preview>
                </template>
            </el-table-column>
            <el-table-column prop="duration" label="时长" width="80">
              <template slot-scope="scope">{{scope.row.duration | reCovertTimeFilter }}</template>
            </el-table-column>
            <el-table-column label="审核状态">
                <template slot-scope="scope">
                    <span :class="{'color-red': scope.row.item_audit_status === 5}">{{ scope.row.item_audit_status | auditAnStatusFilter(level) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="audit_remark" label="最新审核理由" width="150">
                <template slot-scope="scope">
                    <span v-if="level === 4">
                        <span v-if="scope.row.item_audit_status === 3">
                            <auditComicRemarkEdit :scope="scope" property="audit1st_refuse_remark" @getTableList = "getTableList" />
                        </span>
                        <span v-else>
                            <el-tooltip  class="item" effect="light" :content="scope.row.audit1st_refuse_remark" placement="top-start">
                                <div class="single-ellipsis">{{ scope.row.audit1st_refuse_remark || '-' }}</div>
                            </el-tooltip>
                        </span>
                        <el-tooltip  class="item" effect="light" :content="scope.row.audit2nd_refuse_remark" placement="top-start">
                            <div class="single-ellipsis">{{ scope.row.audit2nd_refuse_remark || '-' }}</div>
                        </el-tooltip>
                    </span>
                    <span v-if="level === 2">
                        <el-tooltip  class="item" effect="light" :content="scope.row.audit1st_pass_remark" placement="top-start">
                            <div class="single-ellipsis">{{ scope.row.audit1st_pass_remark || '-' }}</div>
                        </el-tooltip>
                        <span v-if="scope.row.item_audit_status === 5">
                            <auditComicRemarkEdit :scope="scope" property="audit2nd_refuse_remark" @getTableList = "getTableList" />
                        </span>
                        <span v-else>
                            <el-tooltip  class="item" effect="light" :content="scope.row.audit2nd_refuse_remark" placement="top-start">
                                <div class="single-ellipsis">{{ scope.row.audit2nd_refuse_remark || '-' }}</div>
                            </el-tooltip>
                        </span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="previewHandle(scope.row)">预览</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-08-31 11:10:58
 * @LastEditTime: 2018-08-31 19:01:18
 * @Description: 漫画审核-审核主体
 * @Company: 成都二次元动漫
 */

import auditComicRemarkEdit from './auditComicRemarkEdit';
import ImgPreview from '@/components/img-preview';
import { mapState } from 'vuex';

export default {
    props: {
        search: Function
    },
    data() {
        return {
            link: null,
            chapter_id: null,
            isloading: false,
            comicData: {},
            tableList: [],
            searchData: {},
            pubClass: [],
            audit_status: null, // 待提交：1 、待审核：2 、审核拒绝：3 、审核通过:4
            selectedRows: [] // 选择的行
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level, // 1：用户 2：管理 3：超管, 4:cp方审核人员
            platform_list: state => state.publicConfig.platform_list,
            cover_https_domain: state => state.publicConfig.animation.cover_https_domain
        }),
        checkDisablePassBtn() { // 通过按钮
            if (this.selectedRows.length === 0) {
                return true;
            }
            // 如果是一审人员则不做置灰
            if (this.level === 4) {
                return false;
            }
            let flag = this.selectedRows.filter(item => {
                return ['ing'].includes(item.greenDesc);
            }).length;

            if (flag) {
                return true;
            }
        },
        checkDisableNoPassBtn() { // 拒绝按钮
            // 章节审核状态是否有未通过的
            const noSuccCapRows = this.selectedRows.filter(item => item.audit_status === 5);
            if (noSuccCapRows.length > 0) { // 如果选中有拒绝的，则灰掉按钮
                return true;
            }
            return this.selectedRows.length === 0;
        }
    },
    methods: {
        showDetails(details) {
            return details.map(item => {
                return item.edit_detail;
            }).join(',');
        },
        handleSelectionChange(val) {
            this.selectedRows = val;
        },
        searchEvent(searchData) {
            this.searchData = searchData;
            this.getTableList();
        },
        // 审核操作
        promptAudit(type) {
            let url, message, title, inputValue;
            const defaultInput = {
                '2': '二审同意',
                '4': '一审同意'
            };

            if (type === 'pass') {
                url = 'passRelatedVideo_Admin';
                message = '确定通过审核吗？';
                title = '审核通过';
                inputValue = defaultInput[this.level];
            } else if (type === 'reject') {
                url = 'refuseRelatedVideo_Admin';
                message = '确定拒绝通过审核吗？';
                title = '拒绝通过';
            }

            const { correlation_id } = this.comicData;

            this.$prompt(message, title, {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                inputPlaceholder: '请输入理由',
                showInput: true,
                inputValue,
                inputType: 'textarea',
                customClass: 'audit-main-msgbox',
                center: true,
                inputValidator(value) {
                    if (!value) return '请输入理由';
                    else if (value.length > 100) return '输入字符数量1-100';
                    return true;
                },
                beforeClose: (action, instance, done) => {
                    const closeHandle = () => {
                        instance.confirmButtonLoading = false;
                        done();
                    };
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = '提交中...';
                        this.$api(url, { correlation_id, audit_remark: instance.inputValue }).then(res => {
                            this.$message.success('提交成功！');
                            this.$emit('search');
                            closeHandle();
                        }).finally(() => {
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = '提交';
                        });
                    } else {
                        closeHandle();
                    }
                }
            });
        },
        forceAuditPass({ correlation_id }) {
            this.$api('forceRelatedVideoAuditPass', { correlation_id }).then(({ data }) => {
                this.$message.success('操作成功');
            });
        },
        // 预览
        async previewHandle({correlation_video_id}) {
            if (!this.link) {
                this.link = document.createElement('a');
            }
            await this.$api('getRelatedVideoPlayAuth', {
                correlation_video_id
            }).then(res => {
                this.link.setAttribute('href', `/static/video_preview.html?playauth=${res.data.PlayAuth}&vid=${correlation_video_id}`);
                this.link.setAttribute('target', '_blank');
                this.link.click();
            });
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            this.$api('getRelatedVideoDiff_Admin', { correlation_id: this.searchData.correlation_id }).then(({data}) => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                this.comicData = data.user_correlation;
                this.tableList = this.selectHandle(data.diff_rows, this.searchData);
                this.isloading = false;
            });
        },
        // 前端查询
        selectHandle(list, searchData) {
            const { type, correlation_name } = searchData;
            if (type !== '全部') {
                list = list.filter(item => item.edit_action === type);
            } else if (correlation_name) {
                list = list.filter(item => {
                    const correlationNameArray = item.correlation_name.split('');
                    return correlation_name.split('').some(b => correlationNameArray.includes(b));
                });
            }
            return list;
        }
    },
    components: {
        ImgPreview,
        auditComicRemarkEdit
    }
};
</script>
<style lang="scss">
    .audit-main{
        height: 100%;
        &-option{
            margin-bottom: 10px;
        }
        .el-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
            .has-deffer{
                td {
                    background: #f5ecec;
                }
            }
        }
        .audit-submit-time{
            height: 32px;
            line-height: 32px;
            font-size: 14px;
        }
    }
    .audit-main-msgbox{
        .el-message-box__title span{
            color: red;
            font-size: 20px;
        }
    }
</style>
