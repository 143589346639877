<template>
    <el-container class="main">
        <SvgSprite/>
        <el-aside class="main-aside" :width="isCollapse ? '64px' : '200px'">
            <div class="main-aside-head">
                <router-link class="logo" :class="isCollapse?'logo-min':''" :to="{ name: ![3,4].includes(level) ? 'welcome' : 'personalCenter' }">
                    <el-collapse-transition>
                        <div class="logo-box" :style="{width:isCollapse?'64px':'200px'}">
                            <img :src="isCollapse?'/static/images/logo_white2.png':'/static/images/logo_white.png'">
                        </div>
                    </el-collapse-transition>
                </router-link>
            </div>
            <customMenu :isCollapse.sync="isCollapse"/>
        </el-aside>
        <el-container v-if="ready">
            <customHeader :isCollapse.sync="isCollapse"/>
            <el-main class="main-content">
                <router-view></router-view>
            </el-main>
            <el-footer class="main-footer">
                Copyright&copy;2017 中国漫画分发中心&nbsp;&nbsp;&nbsp;&nbsp; 蜀ICP备17035423号-2&nbsp;&nbsp;&nbsp;&nbsp;成都二次元动漫有限公司
            </el-footer>
        </el-container>
    </el-container>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-03 14:43:45
 * @LastEditTime: 2018-09-13 19:08:12
 * @Description: 内容页面
 * @Company: 成都二次元动漫
 */
import customMenu from '@/components/Menu';
import customHeader from '@/components/Header';
import { mapState, mapActions } from 'vuex';
import SvgSprite from '@/components/SvgSprite.vue';

export default {
    data() {
        return {
            isCollapse: false,
            ready: false
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level
        })
    },
    async mounted() {
        await this.getPublicConfig();
        this.ready = true;
    },
    methods: {
        ...mapActions('app', ['getPublicConfig'])
    },
    components: {
        customMenu,
        customHeader,
        SvgSprite
    }
};
</script>
<style lang="scss">
    .main{
        height: 100%;
        &-aside{
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-color: #0d4074;
            transition: width 0.5s ease;
            &-head{
                height: 64px;
                .logo-box {
                    height: 64px;
                    overflow: hidden;
                    img {
                        display: block;
                        height: 56px;
                        margin: 0 auto;
                    }
                }
            }
        }
        &-content{
            box-sizing: border-box;
            padding: 20px 20px 0;
            background: #eaedf1;
            height: 100%;
        }
        &-footer{
            text-align: center;
            line-height: 1.25;
            font-size: 12px;
            padding-top: 10px;
            height: 30px !important;
            color: #999;
            background-color: #eaedf1;
        }
    }
</style>
