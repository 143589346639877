/*
 * @Author: daipeng
 * @Date: 2018-09-19 14:05:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-04-24 17:09:14
 * @Description: 全局配置
 * @Company: 成都二次元动漫
 */
const VUE_APP_ENV = process.env.VUE_APP_ENV;
const isProduction = ['production'].includes(VUE_APP_ENV);
const isPre = ['pre'].includes(VUE_APP_ENV);
export default {
    acceptZip: 'zip,rar,z7',
    acceptImg: 'image/jpg,image/jpeg,image/png,image/gif',
    socketUrl: isProduction ? 'wss://manager.cnmanhua.com' : 'ws://10.0.10.11:8902',
    // cdn: 'https://cnmanhua.oss-cn-hangzhou.aliyuncs.com',
    // cdn: 'http://opensystem.321mh.com',
    cdn: isProduction ? 'http://image.cnmanhua.com' : 'http://cnmanhua.gougouwu.com',
    dingRedirect: isProduction ? 'http://manager.cnmanhua.com' : 'http://erciyuan.iok.la:8081',
    dingAppId: isPre ? 'dingoag6tampgkpk7eqbe9' : (isProduction ? 'dingoaaze9rbmfsfg13ham' : 'dingoavzhpxyxelhqks77i')
}
