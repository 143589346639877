<!--
 * @Author: your name
 * @Date: 2021-08-26 10:05:43
 * @LastEditTime: 2021-08-30 17:55:44
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /comic_dispatch_internal/src/views/main/anComicManager/addComic/addPublish.vue
-->
<template>
    <div class="add-publish flex-column">
        <div class="add-publish-icons"><i class="el-icon-circle-check-outline"></i></div>
        <h1>保存成功，请在我的动态漫中提交审核</h1>
        <el-button type="primary" size="large" @click="addChapterAgain">继续添加章节</el-button>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-10-16 17:38:39
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-16 18:40:56
 * @Description: 新增漫画发布审核
 * @Company: 成都二次元动漫
 */

export default {
    data() {
        return {};
    },
    methods: {
        addChapterAgain() {
            const { comicName, animation_id, uploaderUid, authorName, chapter_price } = this.$route.query;
            this.$router.replace({ name: 'editAnChapter', query: { editType: 'add', comicName, animation_id, uploaderUid, authorName, chapter_price } });
        }
    }
};
</script>

<style lang="scss">
    .add-publish{
        height: 100%;
        justify-content: center;
        align-items: center;
        &-icons{
            i{
               color: #67c23a;
               font-size: 60px;
            }
        }
    }
</style>
