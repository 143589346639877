<template>
    <div class="user-dialog">
        <el-form :model="user" label-width="80px" class="edit-form">
            <el-row>
                <el-col :span="8" :offset="8">
                        <el-input
                            placeholder="请输入查找内容"
                            v-model="searchCont"
                            @input="searchList">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                </el-col>
            </el-row>
            <div class="list_content" v-if="user.teamType === 1">
                <div class="sole_head">独家漫画团队CP</div>
                <el-radio-group v-model="soleCheck">
                    <el-radio v-for="(item,index) in soleList" :key="index" :label="item.uid">{{item.uname}}</el-radio>
                </el-radio-group>
            </div>
            <div class="list_content" v-else>
                <div class="sole_head">非独家漫画团队CP</div>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <el-checkbox-group v-model="unsoleCheckList" @change="handleCheckedUnsloleChange">
                    <el-checkbox v-for="(item,index) in unsoleList" :key="index" :label="item.uid">{{item.uname}}</el-checkbox>
                </el-checkbox-group>
            </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeModel">取 消</el-button>
            <el-button type="primary" @click="relevanceBtn">确 定</el-button>
        </div>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-27 09:37:23
 * @LastEditTime: 2018-09-27 09:37:23
 * @Description: 用户设置设置 view/edit/add 弹出框
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        user: Object
    },
    data() {
        return {
            soleList: [],
            soleCheck: -1,
            unsoleList: [],
            unsoleCheckList: [],
            isIndeterminate: true,
            checkAll: false,
            copySoleList: [],
            copyUnsoleList: [],
            searchCont: ''
        };
    },
    created() {
        this.init();
    },
    watch: {
        user() {
            this.init();
        }
    },
    methods: {
        // 初始化操作
        init () {
            this.indeterminate = true;
            this.checkAll = false;
            this.searchCont = '';
            if (this.user.teamType === 1) {
                this.getUnsoleList();
            } else {
                this.getAllUnsoleList();
            }
        },

        // 非独家列表
        getAllUnsoleList() {
            this.unsoleCheckList = [];
            return this.$api('getTeamcplist', { auditorUid: this.user.userId }).then(res => {
                this.unsoleList = res.data;
                this.unsoleList = this.unsoleList.sort(this.compare);
                this.copyUnsoleList = this.unsoleList;
                this.unsoleList.map(item => {
                    if (item.isBind) {
                        this.unsoleCheckList.push(item.uid);
                    }
                });
            });
        },

        // 全选
        handleCheckAllChange(val) {
            const unsoleL = [];
            this.unsoleList.map((item, index) => { unsoleL[index] = item.uid; });
            this.unsoleCheckList = val ? unsoleL : [];
            this.isIndeterminate = false;
        },

        // 列表改变
        handleCheckedUnsloleChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.unsoleList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.unsoleList.length;
        },

        // 独家列表
        getUnsoleList() {
            return this.$api('getTeamcplist', { auditorUid: this.user.userId }).then(res => {
                this.soleList = res.data;
                this.soleList = this.soleList.sort(this.compare);
                this.copySoleList = this.soleList;
                this.soleList.map(item => {
                    if (item.isBind) {
                        this.soleCheck = item.uid;
                    }
                });
            });
        },

        // 关联按钮
        relevanceBtn() {
            switch (this.user.teamType) {
            case 1:
                this.soleBind();
                break;
            case 2:
                this.unSoleBind();
                break;
            }
        },

        // 独家绑定
        soleBind() {
            if (this.soleCheck === -1) {
                this.$message({
                    type: 'error',
                    message: '请选择独家漫画团队CP'
                });
                return false;
            }
            return this.$api('bindExclusiveUser', { cpUid: this.soleCheck, cpAuditorUid: this.user.userId }).then(res => {
                this.$message({
                    type: 'success',
                    message: '关联成功'
                });
                this.closeModel();
            });
        },

        // 非独家绑定
        unSoleBind() {
            if (this.unsoleCheckList === 0) {
                this.$message({
                    type: 'error',
                    message: '请选择非独家漫画团队CP'
                });
                return false;
            }
            return this.$api('bindUnExclusiveUser', { cpUids: this.unsoleCheckList, cpAuditorUid: this.user.userId }).then(res => {
                this.$message({
                    type: 'success',
                    message: '关联成功'
                });
                this.closeModel();
            });
        },

        // 模糊搜索
        searchList(val) {
            this.soleList = this.copySoleList.filter(item => {
                return (item.uname).indexOf(val) > -1 || this.soleCheck === item.uid;
            });
            this.unsoleList = this.copyUnsoleList.filter(item => {
                return (item.uname).indexOf(val) > -1 || this.unsoleCheckList.indexOf(item.uid) > -1;
            });
        },

        // 关闭弹框
        closeModel() {
            this.$emit('close');
        },

        // 比较函数
        compare(obj1, obj2) {
            var font = obj1.uid;
            var home = obj2.uid;
            if (font > home) {
                return -1;
            }
            return 1;
        }
    }
};
</script>

<style lang="scss">
    .sole_head{
        font-size: 16px;
        margin-bottom: 15px;
    }
    .edit-form{
        .list_content{
            margin-top: 20px;
            max-height: 200px;
            overflow: auto;
        }
        .el-checkbox{
            line-height: 30px;
        }
        .el-radio{
            line-height: 30px;
        }
    }
    .dialog-footer{
        position: relative;
        margin-top: 20px;
        text-align: right;
        right: 40px;
    }
</style>
