/*
 * @Author: daipeng
 * @Date: 2018-09-08 10:19:18
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-22 13:40:44
 * @Description: router helper
 * @Company: 成都二次元动漫
 */
import { store } from '@/main';
import { mainMenuRoutes } from '@/router/routes';
import { isArray } from '@/libs/utils';

let constBreacrumb = [
    {
        name: 'welcome',
        path: '/main/welcome',
        title: '首页',
        icon: 'el-icon-home',
        meta: { title: '首页' }
    }
];
/**
 * 通过路由的matched组装面包屑数组
 *
 * @param {Array} routes
 * @param {Array} matched
 * @param {Array} [breadcrumb=[]]
 * @returns {Array} [breadcrumb]
 */
const getMathedRoute = (routes, matched, breadcrumb = []) => {
    routes.some(route => {
        const isBreadcrumb = matched.some(match => match.name === route.name);
        if (isBreadcrumb) {
            breadcrumb.push(route);
            if (isArray(route.children)) return getMathedRoute(route.children, matched, breadcrumb);
            return true;
        }
    });
    return breadcrumb;
};
/**
 * 获取面包屑数组
 *
 * @param {Route} to
 * @returns [breadcrumb]
 */
const getBreadcrumb = to => {
    if (to.name === 'welcome') {
        return [...constBreacrumb, {
            title: '欢迎来到中国漫画分发中心'
        }];
    } else {
        return [...constBreacrumb, ...getMathedRoute(mainMenuRoutes, to.matched)];
    }
};
/**
 * 通过路由名称获取路由对象
 *
 * @param {*} routeName
 */
export const getRouteByName = (routeName, routes = mainMenuRoutes, result = []) => {
    routes.some(route => {
        if (route.name === routeName) {
            result.push(route);
            return true;
        } else if (route.children && route.children.length) getRouteByName(routeName, route.children, result);
        else return false;
    });
    return result;
};

export const beforeEach = (to, from, next) => {
    document.title = `中国漫画分发中心-${to.meta.title}`;
    const breadcrumb = getBreadcrumb(to);
    // 路由权限下，面包屑特殊处理
    if (store.state.app.userInfo.level !== 1) {
        breadcrumb[0] = {
            name: 'personalCenter',
            path: '/main/personalCenter',
            title: '首页',
            icon: 'el-icon-home',
            meta: { title: '首页' }
        };
    }
    store.commit('app/setBreadcrumb', breadcrumb);
    // if (from.path === '/' && ['/', '/main'].includes(to.path)) return next({ path: '/main/order' });
    next();
};
