<template>
    <div class="an-audit-record-col">
        <!-- 图片展示 -->
        <div v-if="['correlation_cover_16_8'].includes(edit_column)" class="an-audit-record-colimg" v-scrollbar>
            <el-popover
              ref="popover"
              placement="top"
              title=""
              trigger="hover">
              <div class="audit-enlarged" v-lazy:background-image="coverDomain + $utils.filterImagePath(colDataJson)" :key="coverDomain + $utils.filterImagePath(colDataJson)"></div>
              <div slot="reference" class="thumbnail">
                  <img :key="$utils.filterImagePath(colDataJson)" v-lazy="coverDomain + $utils.filterImagePath(colDataJson)" alt="">
              </div>
          </el-popover>
        </div>
        <!-- 相关视频 -->
        <div class="chapter-video-preview" v-else-if="edit_column === 'correlation_video_id'">
          <span @click="previewHandle(colDataJson)">
              <SvgIcon icon="play"></SvgIcon>
          </span>
        </div>
        <!-- 关联视频 -->
         <div v-else-if="edit_column === 'animations'">
           <template v-for="(item, index) in colDataJson">
            <span :key="item.animation_id" :title="item.animation_name">
              {{item.animation_name}}
            </span>
            <template v-if="index + 1 !== colDataJson.length">、</template>
          </template>
         </div>
         <!-- 视频时长相关 -->
         <div v-else-if="['duration'].includes(edit_column)">
           {{colDataJson | reCovertTimeFilter }}
         </div>
         <!-- 上下架 -->
         <div v-else-if="edit_column === 'show_status'">
           {{colDataJson === 1 ? '上架' : '下架' }}
         </div>
         <!-- 视频文件大小 -->
         <div v-else-if="edit_column === 'size'">
           {{colDataJson | convertFileSizeFilter}}
         </div>
        <template v-else-if="edit_column.length">{{colDataJson}}</template>
        <!-- 新增 -->
        <template v-else-if="edit_column === ''">--</template>
        <!-- 空 -->
        <template v-else-if="!colData">--</template>
        <!-- 特殊处理 -->
        <template v-else>{{colData == '[]' ? '--' : colData}}</template>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-29 10:46:49
 * @LastEditTime: 2018-09-29 10:46:49
 * @Description: 审核记录-修改前后-分类展示组件
 * @Company: 成都二次元动漫
 */
import { mapState } from 'vuex';
export default {
    props: {
        colData: {
            type: String,
            default: ''
        },
        edit_column: String,
        platformList: Array
    },
    data() {
        return { };
    },
    computed: {
        ...mapState('app', {
            coverDomain: state => state.publicConfig.animation.cover_https_domain
        }),
        colDataJson() {
            let data = '--';
            try {
                data = JSON.parse(this.colData);
            } catch (error) {
                return this.colData;
            }
            return data;
        },
        // 图片
        imgData() {
            if (/\.(png|jpe?g|gif|svg)+/ig.test(this.colData)) {
                return /\[/g.test(this.colData) ? JSON.parse(this.colData) : [this.colData];
            } else return [];
        }
    },
    methods: {
        // 预览视频
        async previewHandle(correlation_video_id) {
            if (!this.link) {
                this.link = document.createElement('a');
            }
            await this.$api('getRelatedVideoPlayAuth', {
                correlation_video_id
            }).then(res => {
                this.link.setAttribute('href', `/static/video_preview.html?playauth=${res.data.PlayAuth}&vid=${correlation_video_id}`);
                this.link.setAttribute('target', '_blank');
                this.link.click();
            });
        }
    }
};
</script>

<style lang="scss">
    .an-audit-record-col{
        width: 100%;
        &Slide{
            width: 100%;
            overflow: hidden;
            position: relative;
        }
        &img{
            height: 40px;
            position: relative;
            overflow: hidden;
            text-align: center;
            .thumbnail{
                padding-right: 10px;
                box-sizing: border-box;
                display: inline-block;
                height: 20px;
                cursor: pointer;
                img{
                    height: 100%;
                }
            }
        }
        .chapter-video-preview .icon{
          font-size: 28px;
          color: #999;
          cursor: pointer;
        }
    }
    .audit-enlarged{
        width: 200px;
        height: 200px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>
