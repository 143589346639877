<template>
  <div class="chapter-cover flex-row">
    <el-upload
      :disabled="type === 'view'"
      ref="batchUpload"
      drag
      :multiple="false"
      action=""
      :accept="$config.acceptImg"
      class="chapter-cover-upload"
      :auto-upload="false"
      :show-file-list="false"
      :on-change="onChange"
      :limit="1"
    >
      <p class="chapter-cover-content">
        <!-- <img v-if="chapterCover168" :key="$config.cdn + chapterCover168" :src="$config.cdn + chapterCover168" alt=""> -->
        <customerImage
          :greenprocess="chapterCover168.greenStatus"
          v-if="chapterCover168"
          :key="$utils.filterImagePath(chapterCover168)"
          :src="
            (tokenData.domain || chapter_cover_domain) +
            $utils.filterImagePath(chapterCover168)
          "
        />
        <i v-else class="el-icon-plus"></i>
      </p>
      <div slot="tip" class="el-upload__tip">
        图片需小于500kb,比例为16:8 (800 x 400),仅支持jpg,jpeg,png,gif扩展名
      </div>
    </el-upload>
    <div v-if="chapterCover168" class="ml10">
      <el-button type="primary" size="mini" @click="previewChapterCover"
        >预览</el-button
      >
    </div>
  </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-18 15:23:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-19 14:11:46
 * @Description: 章节封面
 * @Company: 成都二次元动漫
 */
import { anUploadMixin } from '@/mixins'
import customerImage from '@/components/customerImage'
const COVER_IMAGE_SCALE = 16 / 8

export default {
  mixins: [anUploadMixin],
  props: {
    type: String, // 编辑类型: 0 view, 1 edit, 2 add
    chapterCover168: String,
    chapter_cover_domain: String,
  },
  data() {
    return {}
  },
  methods: {
    // 图片选择
    onChange(file, fileList) {
      // element-ui的upload组件需要清空上传列表，否则多选的时候会重复
      this.$refs.batchUpload.clearFiles()
      this.validateImage(file).then((errList) => {
        if (errList.length) {
          this.$notify.error({
            title: '图片错误',
            dangerouslyUseHTMLString: true,
            message: `<strong>名称：</strong>${
              file.name
            }<br /><strong>错误信息：</strong>${errList
              .map((err) => err.message)
              .join('、')}`,
          })
        } else this.uploadFunction(file.name, file.raw)
      })
    },
    // 图片校验
    validateImage(item) {
      const isRt500KB = item.size / 1024 > 500
      return this.$utils.getImagePromise(item.raw).then((image) => {
        const scale = image.width / image.height
        const errorList = []
        if (isRt500KB)
          errorList.push({ name: item.name, message: '请上传小于500KB的图片' })
        if (!/(.jpg|.png|jpeg|gif)$/.test(item.name))
          errorList.push({ name: item.name, message: '上传图片格式错误' })
        if (scale > COVER_IMAGE_SCALE * 1.1 || scale < COVER_IMAGE_SCALE * 0.9)
          errorList.push({ name: item.name, message: '图片长宽比例不正确' })
        return errorList
      })
    },
    // 自定义上传方法
    uploadFunction(filename, file) {
      const { animation_id, chapter_id } = this.$route.query
      this.getAnChapterCoverToken({
        animation_id,
        filename,
        type: 168,
        chapter_id,
      }).then((res) => {
        this.uploadHandle({ ...res, file }).then((res) => {
          this.$emit('update:chapterCover168', `/${res.name}`)
          this.$emit('update:chapter_cover_domain', this.tokenData.domain)
        })
      })
    },
    // 预览章节封面
    previewChapterCover() {
      console.log(
        (this.tokenData.domain || this.chapter_cover_domain) +
          this.$utils.filterImagePath(this.chapterCover168)
      )
      this.$root.$emit(
        'fullscreen',
        (this.tokenData.domain || this.chapter_cover_domain) +
          this.$utils.filterImagePath(this.chapterCover168)
      )
    },
  },
  components: {
    customerImage,
  },
}
</script>

<style lang="scss">
.chapter-cover {
  border: 1px dashed #dcdfe6;
  padding: 20px;
  &-upload {
    margin-left: 30px;
    position: relative;
    .el-upload-dragger {
      overflow: visible;
    }
  }
  &-content {
    width: 100%;
    height: 100%;
    position: relative;
    color: #e4e7ea;
    .el-icon-plus {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
