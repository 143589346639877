<template>
    <div class="personal-msg page-container flex-column">
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @submit.native.prevent>
            <el-form-item label="审核人员:" prop="logType">
                <el-select v-model="searchData.user_ids" multiple filterable reserve-keyword clearable placeholder="请选择人员" style="width: 300px;">
                    <el-option v-for="item in adminUserList" :key="item.userId" :label="item.userName" :value="item.userId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="CP提交时间:" prop="queryText">
                <el-date-picker format="yyyy-MM-dd HH:mm" v-model="searchData.dateRange" style="width: 400px;" clearable type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="doSearchData">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button @click="clearSearchHandle">重置</el-button>
            </el-form-item>
        </el-form>
        <div style="width:300px">
            <el-button type="primary" @click="doExportData">导出Excel</el-button>
        </div>
        <div class="comic-dispatch-line"></div>
        <el-table class="personal-msg-table" ref="table" :data="tableList" border style="width: 100%">
            <el-table-column type="index" label="#" align="center" width="50"></el-table-column>
            <el-table-column prop="comic_id" label="漫画ID" align="center" width="150"></el-table-column>
            <el-table-column prop="cp_submit_time" label="CP提交时间" align="center" width="150"></el-table-column>
            <el-table-column prop="first_name" label="一审审核人员" align="center" width="150"></el-table-column>
            <el-table-column prop="first_pass_time" label="一审通过时间" align="center" width="150"></el-table-column>
            <el-table-column prop="first_return_time" label="一审驳回时间" align="center" width="150"></el-table-column>
            <el-table-column prop="second_name" label="二审审核人员" align="center" width="150"></el-table-column>
            <el-table-column prop="second_pass_time" label="二审通过时间" align="center" width="150"></el-table-column>
            <el-table-column prop="second_return_time" label="二审驳回时间" align="center" width="150"></el-table-column>
        </el-table>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
    </div>
</template>
<script>

import { searchMixin, paginationMixin } from '@/mixins';

export default {
    mixins: [searchMixin, paginationMixin],
    data() {
        return {
            activeIndex: '1',
            adminUserList: [],
            searchData: {
                dateRange: null,
                user_ids: [],
                cp_submit_time_start: null,
                cp_submit_time_end: null
            },
            tableList: []
        };
    },
    watch: {
        'searchData.dateRange'(dateList = []) {
            if (dateList === null) {
                this.searchData.cp_submit_time_start = null;
                this.searchData.cp_submit_time_end = null;
                return;
            }
            this.searchData.cp_submit_time_start = this.$utils.timeFormat(dateList[0], 'yyyy-MM-dd hh:mm');
            this.searchData.cp_submit_time_end = this.$utils.timeFormat(dateList[1], 'yyyy-MM-dd hh:mm');
        }
    },
    mounted() {
        this.getAdminUserList();
    },
    created() {
        this.searchHandle();
    },
    methods: {
        getAdminUserList() {
            this.$api('getAdminUserList', { pageIndex: 1, pageSize: 99999, is_auditor: 1 }).then(res => {
                this.adminUserList = res.data.rows;
            });
        },
        doSearchData() {
            this.searchHandle();
        },
        // 搜索, 广播查询到子组件
        searchHandle(isExport) {
            this.pagination.pageIndex = 1;
            this.getTableList(isExport);
        },
        // 清除搜索
        clearSearchHandle() {
            this.searchData = this.$options.data().searchData;
            this.searchHandle();
        },
        // 查询列表
        getTableList(isExport) {
            const searchData = { ...this.searchData };
            searchData.user_ids = searchData.user_ids.join(',');
            delete searchData.dateRange;
            const params = { ...searchData, ...this.pagination };
            if (isExport) {
                this.downloadData(params);
                return;
            }

            return this.$api('getWorkEfficiency', params).then(res => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                const { list = [], pageIndex, pageSize, total } = res.data;
                this.tableList = list;
                this.tableList.forEach((item) => {
                    this.setTime(item, 'cp_submit_time');
                    this.setTime(item, 'first_pass_time');
                    this.setTime(item, 'first_return_time');
                    this.setTime(item, 'second_pass_time');
                    this.setTime(item, 'second_return_time');
                });
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
            });
        },
        setTime(row, key) {
            if (row[key]) {
                row[key] = this.$utils.timeFormat(row[key], 'yyyy-MM-dd hh:mm:ss');
            }
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        },
        downloadData(params) { // 导出数据
            const url = '/api/api/v2/admin/workefficiency/exportlist?';
            const nodes = [];
            Object.keys(params).forEach(key => {
                const content = params[key];
                if (!content) {
                    return;
                }
                nodes.push(`${key}=${params[key]}`);
            });
            window.open(url + nodes.join('&'));
        },
        doExportData() {
            this.searchHandle(true);
        }
    }
};
</script>
<style lang="scss">
    .personal-msg{
        &-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
        }
    }
</style>
